import { createContext } from 'react';
import WebCheckoutResponse from '../api/responses/WebCheckoutResponse';
import DeliveryMethod from '../types/DeliveryMethod';

export type OrderCheckoutResponseType = WebCheckoutResponse;
export type OrderCheckoutDeliveryResponseType = {
  deliveryMethod?: DeliveryMethod;
} & OrderCheckoutResponseType;

export type OrderInformationType = {
  checkoutInfo?: OrderCheckoutDeliveryResponseType;
  updateCheckoutInfo: (data: OrderCheckoutDeliveryResponseType) => void;
};

const OrderInformationContext = createContext<OrderInformationType | undefined>(
  undefined
);

export default OrderInformationContext;
