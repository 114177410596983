import React, { useState, useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet';
import { mutate } from 'swr';
import * as Sentry from '@sentry/react';
import QueryString from 'query-string';
import Cookies from 'universal-cookie';
import clsx from 'clsx';
import postStorePreview from './api/ShoppingApi/clients/StorePreviewClient';
import getStoreByAlias from './api/ShoppingApi/clients/StoresClient';
import { useGetCartValidate } from './api/ShoppingApi/clients/SWRCartClient';
import { baseUrl, apiVersion } from './api/ShoppingApi/apiConfig';
import CartContext, {
  updateCartItem,
  removeFromCart,
  claimCartItemsFromToken,
} from './context/CartContext';
import RetailerInformationContext from './context/RetailerInformationContext';
import CustomerInformationContext from './context/CustomerInformationContext';
import AppContext from './context/ApplicationContext';
import OrderInformationContext, {
  OrderCheckoutResponseType,
} from './context/OrderInformationContext';
import StorePreview from './context/StorePreviewContext';
import PaymentMethodProvider from './context/PaymentProviderContext';
import ScrollToTop from './utilities/ScrollToTop';
import Footer from './components/Footer';
import LoadingSpinner from './components/LoadingSpinner';
import Banner from './components/Banner';
import Navigation from './components/Navigation';
import NavigationCollections from './components/NavigationCollections';
import PrivacyPolicyNotification from './components/PrivacyPolicyNotification';
import ThemeWrapper from './components/ThemeWrapper';
import ProductInventory from './pages/ProductInventory';
import ProductInventoryCollections from './pages/ProductInventoryCollections';
import CustomerRegistration from './pages/CustomerRegistration';
import MyStory from './pages/MyStory';
import Contact from './pages/Contact';
import ShippingPolicy from './pages/ShippingPolicy';
import ReturnPolicy from './pages/ReturnPolicy';
import ProductDetail from './pages/ProductDetail';
import Rewards from './pages/Rewards';
import ReplyToBuy from './pages/ReplyToBuy';
import CartPage from './pages/Checkout';
import CheckoutInfo from './pages/CheckoutInfo';
import CheckoutConfirmation from './pages/CheckoutConfirmation';
import NotFound from './pages/NotFound';
import CartValidationType from './types/CartValidationType';
import InventoryResponse from './api/responses/InventoryResponse';
import CustomerFormInformation from './types/CustomerInformation';
import RetailerInformation from './types/RetailerInformation';
import Styles from './App.module.scss';
import AlertsProvider from './context/Alerts/AlertsProvider';
import { useSession } from './context/SessionProvider';
import SnackbarAlertsDisplay from './context/Alerts/SnackbarAlertsDisplay';
import useFeatureFlags from './api/ShoppingApi/clients/FeatureFlagClient';
import { useStoreCollections } from './api/ShoppingApi/clients/StoreCollectionsClient';
import DevelopmentInfo from './components/DevelopmentInfo';
import Breakpoints from './components/Breakpoints';
// Start Google Analytics conditionally.
if (process.env.NODE_ENV !== 'development') {
  ReactGA.initialize('G-QQ5JP1E8B6');
}

const parsedUrl = window.location.pathname.split('/');
const webAlias = parsedUrl[1];
const previewCode = QueryString.parse(window.location.search)
  .previewCode?.toString()
  .toLowerCase();

function App(): JSX.Element {
  const [loadingRetailerInformation, setLoadingRetailerInformation] =
    useState<boolean>(true);
  const [sitePreview, setSitePreview] = useState<boolean>(false);
  const [displayCart, setDisplayCart] = useState(false);
  const [pageError, setPageError] = useState<string>();
  const [retailerInformation, setRetailerInformation] =
    useState<RetailerInformation>();
  const [customerInfo, setCustomerInfo] = useState<
    CustomerFormInformation | undefined
  >();
  const [checkoutInfo, setCheckoutInfo] = useState<OrderCheckoutResponseType>();

  const { sessionId, specifySessionId, generateNewSessionId } = useSession();
  const { cart, isLoading } = useGetCartValidate(
    retailerInformation?.store.tenant.id,
    retailerInformation?.store.id,
    sessionId
  );

  useEffect(() => {
    if (!isLoading && cart.cart?.sessionId) {
      if (cart.cart.sessionId !== sessionId) {
        // If a cart comes back and it has a different session ID, our cart
        // must have gotten merged into another one (likely due to RTB), so we
        // should update our session ID accordingly!
        specifySessionId(cart.cart.sessionId);
      }
    }
  }, [cart.cart?.sessionId, isLoading, sessionId, specifySessionId]);

  useEffect(() => {
    if (loadingRetailerInformation) {
      getStoreByAlias(webAlias)
        .then((res) => {
          if (!res.shoppingPortalEnabled) {
            new Cookies().set('WayRoo-store-preview', true, {
              maxAge: 5184000,
            });
            setSitePreview(true);
          } else {
            new Cookies().remove('WayRoo-store-preview');
          }
          setRetailerInformation({ store: res });
        })
        .catch((error) => {
          Sentry.captureException(error);
          setPageError(error);
        })
        .finally(() => setLoadingRetailerInformation(false));
    }
  }, [loadingRetailerInformation]);

  useEffect(() => {
    if (
      navigator.userAgent.match(/FBAN|FBAV|FB_IAB/i) &&
      /iOS/.test(navigator.userAgent)
    ) {
      // eslint-disable-next-line no-restricted-globals
      history.pushState({}, '', window.location.pathname);
    }
  }, []);

  useEffect(() => {
    if (retailerInformation?.store.id) {
      if (previewCode) {
        postStorePreview(
          retailerInformation.store.tenant.id,
          retailerInformation.store.id,
          {
            previewCode,
          }
        )
          .then((res) => {
            if (new Cookies().get('WayRoo-store-preview') || res?.isValid) {
              setSitePreview(true);
            }
          })
          .catch((error) => {
            Sentry.captureException(error);
            setPageError(error);
          });
      }
    }
  }, [retailerInformation]);

  const storeId = retailerInformation?.store.id;
  const tenantId = retailerInformation?.store.tenant.id;
  const dsoLogoObject = retailerInformation?.store.tenant.brandingImages.find(
    (imageItem) => imageItem.brandingImageType === 'Logo'
  );
  // const dsoLoyaltyLogoObject =
  //   retailerInformation?.store.tenant.brandingImages.find(
  //     (imageItem) => imageItem.brandingImageType === 'LoyaltyCardLogo'
  //   );
  const dsoFaviconObject =
    retailerInformation?.store.tenant.brandingImages.find(
      (imageItem) => imageItem.brandingImageType === 'Favicon'
    );
  const dsoTheme = retailerInformation?.store.tenant.brandingColors;
  const tenantName = retailerInformation?.store.tenant.name || 'WayRoo';
  const storeName = retailerInformation?.store.storeName || '';
  const isSuspended = retailerInformation?.store.isSuspended;
  const hasFreeShippingRule = retailerInformation?.store.hasFreeShippingRule;
  const freeShippingMin =
    retailerInformation?.store.minimumOrderTotalToEarnFreeShipping;
  // awaiting backend to add the store information to the api call
  const { featureFlags, isFeatureFlagSet } = useFeatureFlags();
  const updateCustomerInfo = (
    data: CustomerFormInformation | undefined
  ): void => setCustomerInfo(data);
  const updateCheckoutInfo = (data: OrderCheckoutResponseType): void =>
    setCheckoutInfo(data);

  const mutateCart = (): Promise<CartValidationType> =>
    mutate(
      `${baseUrl}/${apiVersion}/tenants/${tenantId}/stores/${storeId}/cartItems/${sessionId}/validate`
    );
  const mutateInventory = (): Promise<InventoryResponse> =>
    mutate(
      `${baseUrl}/${apiVersion}/tenants/${tenantId}/stores/${storeId}/inventory/search?includeFilters=${featureFlags?.shoppingcollections}`
    );
  const toggleCartDisplay = (): void =>
    setDisplayCart((prevDisplayCart) => !prevDisplayCart);

  const { collections, tagCategories } = useStoreCollections(
    retailerInformation?.store?.tenant?.id,
    retailerInformation?.store?.id
  );

  return (
    <ThemeWrapper theme={dsoTheme}>
      <AlertsProvider>
        <GoogleReCaptchaProvider
          reCaptchaKey={
            process.env.REACT_APP_GOOGLE_RECAPTCHA_SITEKEY as string
          }
          scriptProps={{
            async: false, // optional, default to false,
            defer: false, // optional, default to false
            appendTo: 'body', // optional, default to "head", can be "head" or "body",
          }}
        >
          <AppContext.Provider
            value={{
              featureFlags,
              isFeatureFlagSet,
              collections,
              tagCategories: tagCategories || [],
            }}
          >
            <RetailerInformationContext.Provider value={retailerInformation}>
              <StorePreview.Provider value={sitePreview}>
                <CustomerInformationContext.Provider
                  value={{ customerInfo, updateCustomerInfo }}
                >
                  <OrderInformationContext.Provider
                    value={{ checkoutInfo, updateCheckoutInfo }}
                  >
                    <PaymentMethodProvider>
                      <CartContext.Provider
                        value={{
                          cartIsLoading: isLoading,
                          validationMessage: cart?.validationMessage,
                          sessionId,
                          items: cart?.cart?.cartItems,
                          expires: cart?.cart?.expires
                            ? new Date(cart.cart.expires)
                            : undefined,
                          subtotal: cart?.cart?.subtotal,
                          updateCartItem: updateCartItem(
                            cart,
                            mutateCart,
                            retailerInformation,
                            mutateInventory
                          ),
                          removeFromCart: removeFromCart(
                            cart,
                            mutateCart,
                            retailerInformation,
                            generateNewSessionId,
                            mutateInventory
                          ),
                          claimCartItemsFromToken: claimCartItemsFromToken(
                            cart,
                            mutateCart,
                            retailerInformation,
                            mutateInventory
                          ),
                        }}
                      >
                        <ScrollToTop />
                        <Helmet>
                          <meta
                            name="description"
                            content={`Shop ${storeName}'s ${tenantName} items.`}
                          />
                          <title>{`${tenantName} | ${storeName}'s Inventory`}</title>
                          {dsoFaviconObject?.url && (
                            <link
                              rel="icon"
                              type="image/png"
                              href={dsoFaviconObject.url}
                            />
                          )}
                        </Helmet>
                        <div
                          className={clsx('container-fluid', Styles.appWrapper)}
                        >
                          <div className="row">
                            <div className="col-xs-12">
                              <SnackbarAlertsDisplay />
                              <header className={Styles.app_header}>
                                {hasFreeShippingRule && freeShippingMin && (
                                  <Banner
                                    className={Styles.shipping_banner}
                                    minimumOrderTotalToEarnFreeShipping={
                                      freeShippingMin
                                    }
                                  />
                                )}
                                {featureFlags?.shoppingcollections ? (
                                  <NavigationCollections
                                    dsoLogoUrl={dsoLogoObject?.url}
                                    dsoName={tenantName}
                                    toggleCartDisplay={toggleCartDisplay}
                                    displayCart={displayCart}
                                    byDesignRepDID={
                                      retailerInformation?.store.byDesignRepDID
                                    }
                                  />
                                ) : (
                                  <Navigation
                                    dsoLogoUrl={dsoLogoObject?.url}
                                    dsoName={tenantName}
                                    myStoryPage={
                                      retailerInformation?.store.myStory
                                    }
                                    storeName={storeName}
                                    toggleCartDisplay={toggleCartDisplay}
                                    displayCart={displayCart}
                                    byDesignRepDID={
                                      retailerInformation?.store.byDesignRepDID
                                    }
                                  />
                                )}
                              </header>
                              {sitePreview && (
                                <div
                                  className={`${Styles.preview_banner} py-2`}
                                  data-testid="site-preview-banner"
                                >
                                  <h4 className="my-0">
                                    Online Store - Coming Soon
                                  </h4>
                                  <p className="my-0">
                                    Please check back later
                                  </p>
                                </div>
                              )}

                              {pageError && (
                                <>
                                  <Helmet>
                                    <meta
                                      name="description"
                                      content={`We're sorry but ${webAlias} store does not exist`}
                                    />
                                    <title>{`${tenantName} | ${storeName}'s Inventory`}</title>
                                  </Helmet>
                                  <NotFound
                                    heading="Sorry"
                                    message={`We're sorry but ${webAlias} store does not exist`}
                                  />
                                </>
                              )}
                              {isSuspended && !pageError && (
                                <>
                                  <Helmet>
                                    <meta
                                      name="description"
                                      content={`We are sorry but ${storeName}'s store is disabled.`}
                                    />
                                    <title>{`${tenantName} | ${storeName}'s Inventory`}</title>
                                  </Helmet>
                                  <NotFound
                                    heading="Store Disabled"
                                    message={`We are sorry but ${storeName}'s store is disabled.`}
                                  />
                                </>
                              )}
                              {!retailerInformation && !pageError && (
                                <LoadingSpinner />
                              )}
                              {retailerInformation &&
                                !pageError &&
                                !isSuspended && (
                                  <>
                                    <div className={Styles.contentWrapper}>
                                      <Routes>
                                        <Route
                                          path="/"
                                          element={
                                            <Navigate to="/shop" replace />
                                          }
                                        />
                                        {isFeatureFlagSet(
                                          'shoppingcollections'
                                        ) ? (
                                          <Route
                                            path="/shop/:categorytype?/:category?"
                                            element={
                                              <>
                                                <Helmet>
                                                  <meta
                                                    name="description"
                                                    content={`Shop ${storeName}'s ${tenantName} products.`}
                                                  />
                                                  <title>{`${tenantName} | ${storeName}'s Products`}</title>
                                                </Helmet>
                                                <ProductInventoryCollections />
                                              </>
                                            }
                                          />
                                        ) : (
                                          <Route
                                            path="/shop"
                                            element={
                                              <>
                                                <Helmet>
                                                  <meta
                                                    name="description"
                                                    content={`Shop ${storeName}'s ${tenantName} products.`}
                                                  />
                                                  <title>{`${tenantName} | ${storeName}'s Products`}</title>
                                                </Helmet>
                                                <ProductInventory />
                                              </>
                                            }
                                          />
                                        )}
                                        <Route
                                          path="/register"
                                          element={
                                            <>
                                              <Helmet>
                                                <meta
                                                  name="description"
                                                  content={`Customer register for ${storeName}'s ${tenantName}.`}
                                                />
                                                <title>{`${tenantName} | ${storeName}'s Customer Registration`}</title>
                                              </Helmet>
                                              <CustomerRegistration />
                                            </>
                                          }
                                        />
                                        <Route
                                          path="/products/:id"
                                          element={
                                            <>
                                              <Helmet>
                                                <meta
                                                  name="description"
                                                  content={`${storeName}'s ${tenantName} product details.`}
                                                />
                                                <title>{`${tenantName} | ${storeName}'s Product Details`}</title>
                                              </Helmet>
                                              <ProductDetail
                                                toggleCartDisplay={
                                                  toggleCartDisplay
                                                }
                                              />
                                            </>
                                          }
                                        />
                                        <Route
                                          path="/cart"
                                          element={
                                            <>
                                              <Helmet>
                                                <meta
                                                  name="description"
                                                  content={`Your shopping cart for ${storeName}'s ${tenantName} store.`}
                                                />
                                                <title>{`${tenantName} | ${storeName} Shopping Cart`}</title>
                                              </Helmet>
                                              <CartPage />
                                            </>
                                          }
                                        />
                                        <Route
                                          path="/about"
                                          element={
                                            <>
                                              <Helmet>
                                                <meta
                                                  name="description"
                                                  content={`About ${storeName}'s ${tenantName} store.`}
                                                />
                                                <title>{`${tenantName} | About ${storeName} store`}</title>
                                              </Helmet>
                                              <MyStory />
                                            </>
                                          }
                                        />
                                        <Route
                                          path="/rewards/:customerGuid"
                                          element={
                                            <>
                                              <Helmet>
                                                <meta
                                                  name="description"
                                                  content={`${storeName}'s ${tenantName} rewards program.`}
                                                />
                                                <title>{`${tenantName} | ${storeName}'s Rewards Program`}</title>
                                              </Helmet>
                                              <Rewards
                                                logo={dsoLogoObject?.url}
                                                phone={
                                                  retailerInformation?.store
                                                    .customerContactInfo
                                                    .phoneNumber
                                                }
                                                email={
                                                  retailerInformation?.store
                                                    .customerContactInfo
                                                    .emailAddress
                                                }
                                              />
                                            </>
                                          }
                                        />
                                        <Route
                                          path="/contact"
                                          element={
                                            <>
                                              <Helmet>
                                                <meta
                                                  name="description"
                                                  content={`Contact for ${storeName}'s ${tenantName} store.`}
                                                />
                                                <title>{`${tenantName} | ${storeName} Contact`}</title>
                                              </Helmet>
                                              <Contact
                                                pageTitle="Contact"
                                                phone={
                                                  retailerInformation?.store
                                                    .customerContactInfo
                                                    .phoneNumber
                                                }
                                                email={
                                                  retailerInformation?.store
                                                    .customerContactInfo
                                                    .emailAddress
                                                }
                                                address={
                                                  retailerInformation?.store
                                                    .customerContactInfo.address
                                                }
                                              />
                                            </>
                                          }
                                        />
                                        <Route
                                          path="/shipping-policy"
                                          element={
                                            <>
                                              <Helmet>
                                                <meta
                                                  name="description"
                                                  content={`The shipping policy for ${storeName}'s ${tenantName} store.`}
                                                />
                                                <title>{`${tenantName} | ${storeName} Shipping Policy`}</title>
                                              </Helmet>
                                              <ShippingPolicy
                                                shippingPolicy={
                                                  retailerInformation?.store
                                                    .shippingPolicy.policy
                                                }
                                              />
                                            </>
                                          }
                                        />
                                        <Route
                                          path="/return-policy"
                                          element={
                                            <>
                                              <Helmet>
                                                <meta
                                                  name="description"
                                                  content={`The return policy for ${storeName}'s ${tenantName} store.`}
                                                />
                                                <title>{`${tenantName} | ${storeName} Return Policy`}</title>
                                              </Helmet>
                                              <ReturnPolicy
                                                returnPolicy={
                                                  retailerInformation?.store
                                                    .returnPolicy.policy
                                                }
                                              />
                                            </>
                                          }
                                        />
                                        <Route
                                          path="/checkout/contact-shipping"
                                          element={
                                            <>
                                              <Helmet>
                                                <meta
                                                  name="description"
                                                  content={`Customer contact info for ${storeName}'s ${tenantName} store.`}
                                                />
                                                <title>{`${tenantName} | ${storeName} Customer Contact Info`}</title>
                                              </Helmet>
                                              <CheckoutInfo />
                                            </>
                                          }
                                        />
                                        <Route
                                          path="/checkout/confirmation"
                                          element={
                                            <>
                                              <Helmet>
                                                <meta
                                                  name="description"
                                                  content={`Purchase confirmation for ${storeName}'s ${tenantName} store.`}
                                                />
                                                <title>{`${tenantName} | ${storeName} Purchase Confirmation`}</title>
                                              </Helmet>
                                              <CheckoutConfirmation />
                                            </>
                                          }
                                        />
                                        <Route
                                          path="/reply-to-buy/:claimToken"
                                          element={<ReplyToBuy />}
                                        />
                                        <Route
                                          path="*"
                                          element={
                                            <>
                                              <Helmet>
                                                <meta
                                                  name="description"
                                                  content={`Page Not Found for ${storeName} - ${tenantName}`}
                                                />
                                                <title>{`${tenantName} | ${storeName} - Page Not Found`}</title>
                                              </Helmet>
                                              <NotFound />
                                            </>
                                          }
                                        />
                                      </Routes>
                                    </div>
                                  </>
                                )}
                            </div>
                          </div>
                          <div className="row">
                            {displayCart && <div className={Styles.dimmer} />}
                            {retailerInformation && (
                              <>
                                <PrivacyPolicyNotification
                                  privacyPolicyUrl={
                                    retailerInformation.store.tenant
                                      .staticDocuments.PrivacyPolicy
                                  }
                                />
                                <Footer storeInfo={retailerInformation.store} />
                              </>
                            )}
                          </div>
                          {process.env.NODE_ENV === 'development' ||
                            (process.env.NODE_ENV === 'test' && (
                              <>
                                <DevelopmentInfo
                                  isFreeShipping={!!hasFreeShippingRule}
                                  sitePreview={sitePreview}
                                />
                                <Breakpoints />
                              </>
                            ))}
                        </div>
                      </CartContext.Provider>
                    </PaymentMethodProvider>
                  </OrderInformationContext.Provider>
                </CustomerInformationContext.Provider>
              </StorePreview.Provider>
            </RetailerInformationContext.Provider>
          </AppContext.Provider>
        </GoogleReCaptchaProvider>
      </AlertsProvider>
    </ThemeWrapper>
  );
}

export default App;
