import React from 'react';
import SvgIcon from './SvgIcon';

type Props = {
  id: string;
  className?: string;
  isActive?: boolean;
};

const IconAdd = ({ className, id, isActive, ...other }: Props): JSX.Element => (
  <SvgIcon
    id={id}
    className={className}
    title="Add"
    isActive={isActive}
    viewBox="0 0 24 24"
    {...other}
  >
    <path
      d=" M 13 13 L 19 13 C 19.27 13 19.52 12.89 19.71 12.71 C 19.89 12.52
      20 12.27 20 12 C 20 11.73 19.89 11.48 19.71 11.29 C 19.52 11.11 19.27 11
      19 11 L 13 11 L 13 5 C 13 4.73 12.89 4.48 12.71 4.29 C 12.52 4.11 12.27 4
      12 4 C 11.73 4 11.48 4.11 11.29 4.29 C 11.11 4.48 11 4.73 11 5 L 11 11 L
      5 11 C 4.73 11 4.48 11.11 4.29 11.29 C 4.11 11.48 4 11.73 4 12 C 4 12.27
      4.11 12.52 4.29 12.71 C 4.48 12.89 4.73 13 5 13 L 11 13 L 11 19 C 11 19.27
      11.11 19.52 11.29 19.71 C 11.48 19.89 11.73 20 12 20 C 12.27 20 12.52
      19.89 12.71 19.71 C 12.89 19.52 13 19.27 13 19 L 13 13 Z "
    />
  </SvgIcon>
);

export default IconAdd;
