import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { usaZipRegex, emojiCheck } from '../../utilities/validation';
import { SupportedCountries } from '../../utilities/Countries';
import { SupportedStatesTerritoriesArmedForces } from '../../utilities/States';
import {
  CustomerBillingForm,
  CustomerInformationFormType,
} from '../../types/CustomerInformation';

interface Props {
  onSubmit: (customerBillingInfo: CustomerBillingForm) => void;
  sameAsShipping?: boolean;
  AVSerrors?: boolean | string[];
}

const defaultProps = {
  customerInfo: undefined,
  sameAsShipping: false,
};

const CheckoutBillingForm = ({
  onSubmit,
  sameAsShipping,
  AVSerrors,
}: Props): JSX.Element => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    trigger,
    reset,
    setValue,
    setError,
  } = useForm<CustomerInformationFormType>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    if (Array.isArray(AVSerrors)) {
      AVSerrors.forEach((entry: string) => {
        switch (entry) {
          case 'address':
            setError('line1', {
              type: 'avs failure',
              message: 'Could not verify street number',
            });
            break;
          case 'city':
            setError('city', {
              type: 'cvv failure',
              message: 'Could not verify city',
            });
            break;
          case 'state':
            setError('state', {
              type: 'cvv failure',
              message: 'Could not verify state',
            });
            break;
          case 'zipcode':
            setError('zip', {
              type: 'cvv failure',
              message: 'ZIP Code does not match the credit card',
            });
            break;
          default:
        }
      });
    }
  }, [AVSerrors, setError]);

  useEffect(() => {
    reset();
    if (sameAsShipping) trigger();
  }, [sameAsShipping, trigger, reset]);

  return (
    <form
      data-testid="billing-form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            id="ContactInfo_firstName"
            className="mb-3"
            label="First Name"
            variant="outlined"
            autoComplete="billing given-name"
            type="text"
            fullWidth
            inputProps={{
              maxLength: 255,
            }}
            {...register('firstName', {
              required: 'First Name is required',
              validate: {
                noEmoji: (value) => !emojiCheck(value) || 'Emojis are invalid',
                noWhiteSpacesOnly: (value) =>
                  !!value.trim() || 'First Name is required',
              },
              onBlur: (e) => setValue('firstName', e.target.value.trim()),
            })}
            helperText={errors.firstName?.message}
            error={!!errors.firstName}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="ContactInfo_lastName"
            className="mb-3"
            label="Last Name"
            variant="outlined"
            autoComplete="billing family-name"
            type="text"
            fullWidth
            inputProps={{
              maxLength: 255,
            }}
            {...register('lastName', {
              required: 'Last Name is required',
              validate: {
                noEmoji: (value) => !emojiCheck(value) || 'Emojis are invalid',
                noWhiteSpacesOnly: (value) =>
                  !!value.trim() || 'Last Name is required',
              },
              onBlur: (e) => setValue('lastName', e.target.value.trim()),
            })}
            helperText={errors.lastName?.message}
            error={!!errors.lastName}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="ContactInfo_line1"
            className="mb-3"
            label="Address"
            variant="outlined"
            autoComplete="billing address-line1"
            type="text"
            fullWidth
            inputProps={{
              maxLength: 255,
            }}
            {...register('line1', {
              required: 'Address is required',
              validate: {
                noEmoji: (value) => !emojiCheck(value) || 'Emojis are invalid',
                noWhiteSpacesOnly: (value) =>
                  !!value.trim() || 'Address is required',
              },
              onBlur: (e) => setValue('line1', e.target.value.trim()),
            })}
            helperText={errors.line1?.message}
            error={!!errors.line1}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="ContactInfo_line2"
            className="mb-3"
            label="Apartment, Suite, etc (Optional)"
            variant="outlined"
            autoComplete="billing address-line2"
            type="text"
            fullWidth
            inputProps={{
              maxLength: 255,
            }}
            {...register('line2', {
              validate: {
                noEmoji: (value) =>
                  !emojiCheck(value || '') || 'Emojis are invalid',
              },
              onBlur: (e) => setValue('line2', e.target.value.trim()),
            })}
            helperText={errors.line2?.message}
            error={!!errors.line2}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="ContactInfo_City"
            className="mb-3"
            label="City"
            variant="outlined"
            autoComplete="billing locality"
            type="text"
            fullWidth
            inputProps={{
              maxLength: 255,
            }}
            {...register('city', {
              required: 'City is required',
              validate: {
                noEmoji: (value) => !emojiCheck(value) || 'Emojis are invalid',
                noWhiteSpacesOnly: (value) =>
                  !!value.trim() || 'City is required',
              },
              onBlur: (e) => setValue('city', e.target.value.trim()),
            })}
            helperText={errors.city?.message}
            error={!!errors.city}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="ContactInfo_Country"
            className="mb-3"
            label="Country"
            variant="outlined"
            autoComplete="billing country"
            select
            fullWidth
            SelectProps={{ native: true }}
            inputProps={{
              'data-testid': 'billing-country',
            }}
            defaultValue="USA"
            {...register('country', {
              required: 'Country is required',
            })}
            helperText={errors.country?.message}
            error={!!errors.country}
          >
            {SupportedCountries.map((country) => (
              <option key={country.isoCode} value={country.isoCode}>
                {country.value}
              </option>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="ContactInfo_State"
            className="mb-3"
            label="State"
            variant="outlined"
            autoComplete="billing region"
            select
            fullWidth
            SelectProps={{ native: true }}
            inputProps={{
              'data-testid': 'billing-state',
            }}
            {...register('state', {
              required: 'State is required',
            })}
            helperText={errors.state?.message}
            error={!!errors.state}
          >
            <option value=""> </option>
            {SupportedStatesTerritoriesArmedForces.map((state) => (
              <option key={state.isoCode} value={state.isoCode}>
                {state.value}
              </option>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            id="ContactInfo_zip_code"
            className="mb-3"
            label="ZIP Code"
            variant="outlined"
            autoComplete="billing postal-code"
            type="text"
            fullWidth
            inputProps={{
              maxLength: 255,
            }}
            {...register('zip', {
              required: 'ZIP Code is required',
              pattern: {
                message: 'ZIP Code is invalid',
                value: usaZipRegex,
              },
              onBlur: (e) => setValue('zip', e.target.value.trim()),
            })}
            helperText={errors.zip?.message}
            error={!!errors.zip}
          />
        </Grid>
      </Grid>
      <div className="clearfix">
        <Button
          color="primary"
          variant="contained"
          className="float-end my-4"
          type="submit"
        >
          Continue
        </Button>
      </div>
    </form>
  );
};

CheckoutBillingForm.defaultProps = defaultProps;

export default CheckoutBillingForm;
