import useSwr, { KeyedMutator } from 'swr';
import { useMemo, useContext } from 'react';
import getProductGroupAdditionalSelectors from '../../api/ShoppingApi/clients/StoreInventoryClient';
import ProductGroupAdditionalSelectorsResponse from '../../api/responses/ProductGroupAdditionalSelectorsResponse';
import RetailerInformationContext from '../../context/RetailerInformationContext';
import TransitionalType from './TransitionalType';

const fetcher = (
  tenantId: number,
  storeId: number,
  productGroupId: number
): Promise<ProductGroupAdditionalSelectorsResponse> => {
  return getProductGroupAdditionalSelectors(tenantId, storeId, productGroupId);
};

type SelectorsForProduct = ProductGroupAdditionalSelectorsResponse & {
  mutate: KeyedMutator<ProductGroupAdditionalSelectorsResponse>;
};

export const useSelectorsForProduct = (
  productGroupId: number
): TransitionalType<SelectorsForProduct> => {
  const retailer = useContext(RetailerInformationContext);
  const { data, error, mutate } = useSwr(
    [retailer?.store.tenant.id, retailer?.store.id, productGroupId],
    fetcher
  );

  return useMemo<TransitionalType<SelectorsForProduct>>(() => {
    if (error) {
      return {
        isLoading: false,
        isError: true,
        error,
      };
    }

    if (!data) {
      return {
        isLoading: true,
      };
    }

    return {
      isLoading: false,
      isError: false,
      productGroup: data.productGroup,
      inventoryItems: data.inventoryItems,
      tagCategories: data.tagCategories.sort((a, b) => {
        if (a.sortOrder && b.sortOrder) {
          return a.sortOrder - b.sortOrder;
        }
        return 1;
      }),
      tags: data.tags.sort((a, b) => a.sortOrder - b.sortOrder),
      mutate,
    };
  }, [data, error, mutate]);
};

export default useSelectorsForProduct;
