import EmojiRegex from 'emoji-regex';

// http://emailregex.com/
export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// https://stackoverflow.com/questions/16699007/regular-expression-to-match-standard-10-digit-phone-number
// https://phoneregex.com/
// export const phoneNumberRegex = /^\(?[\d]{3}\)?[\s-]?[\d]{3}[\s-]?[\d]{4}$/;
// export const phoneNumberRegex = /1?\W*([2-9][0-8][0-9])\W*([2-9][0-9]{2})\W*([0-9]{4})(\se?x?t?(\d*))?/;
// export const phoneNumberRegex = /\+?1?\s*\(?-*\.*(\d{3})\)?\.*-*\s*(\d{3})\.*-*\s*(\d{4})$/;
// export const phoneNumberRegex =
//   /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
// export const phoneNumberRegex =
//   /^\s*[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
export const phoneNumberRegex = /^\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

export const usaZipRegex = /^[0-9]{5}(?:-[0-9]{4})?$/;

export const youtubeVideoId =
  /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|&v(?:i)?=))([^#&?]*).*/;

export const getYoutubeVideoId = (youtubeLink: string): string | undefined =>
  youtubeLink.match(youtubeVideoId)?.[1];

export const validateEmail = (mail: string): boolean => emailRegex.test(mail);

export const validatePhoneNumber = (phoneNumber: string): boolean =>
  phoneNumberRegex.test(phoneNumber);

export const validateUSZipcode = (zipcode: string): boolean =>
  usaZipRegex.test(zipcode);

export const emojiCheck = (value: string): boolean =>
  !!EmojiRegex().exec(value)?.length;
