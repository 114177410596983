import React from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useGetRewards } from '../../api/ShoppingApi/clients/SWRCartClient';
import isPlural from '../../utilities/isPlural';
import EmailIcon from '../../components/Icons/EmailIcon';
import PhoneIcon from '../../components/Icons/PhoneIcon';
import RewardsCard from '../../components/RewardsCard';
import LoadingSpinner from '../../components/LoadingSpinner';
import Styles from './Rewards.module.scss';

type Props = {
  logo?: string;
  email?: string;
  phone?: string;
};

const Rewards = ({ email, phone, logo }: Props): JSX.Element => {
  const theme = useTheme();
  const MUIStyles = makeStyles({
    socialLogoContainer: {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    contactLink: {
      '&:hover': {
        '& span:first-child': {
          background: theme.palette.primary.dark,
        },
      },
    },
  })();
  const { customerGuid } = useParams() as { customerGuid: string };
  const { rewards, isLoading, isError } = useGetRewards(customerGuid);

  if (isLoading)
    return (
      <div className="row">
        <div className="col-12 mb-3">
          <LoadingSpinner />
        </div>
      </div>
    );

  if (isError || !rewards)
    return (
      <div className="col-12">
        <h2 className="mb-3">Rewards Not Found</h2>
        <p>
          Confirm that the URL you were given is correct otherwise contact the
          retailer.
        </p>
        {email && (
          <p>
            <a
              className={`${MUIStyles.contactLink} ${Styles.contactLink}`}
              href={`mailto:${email}`}
            >
              <span
                className={`${MUIStyles.socialLogoContainer} ${Styles.socialLogoContainer}`}
              >
                <EmailIcon
                  id="footerContactEmail"
                  className={Styles.socialLogo}
                />
              </span>
              <span className={Styles.contactLinkText}>{email}</span>
            </a>
          </p>
        )}
        {phone && (
          <p>
            <a
              className={`${MUIStyles.contactLink} ${Styles.contactLink}`}
              href={`tel:${phone}`}
            >
              <span
                className={`${MUIStyles.socialLogoContainer} ${Styles.socialLogoContainer}`}
              >
                <PhoneIcon
                  id="footerContactPhone"
                  className={Styles.socialLogo}
                />
              </span>
              <span className={Styles.contactLinkText}>{phone}</span>
            </a>
          </p>
        )}
      </div>
    );

  const {
    rewardPointGoal,
    pointsNeededForNextReward,
    pointsAvailable,
    rewardsAvailable,
    rewardsRedeemed,
  } = rewards;
  const cardsCeil = Math.ceil(pointsAvailable / rewardPointGoal);
  const remainingPoints = pointsAvailable % rewardPointGoal;

  let headingText = <>Shop now and earn reward points!</>;

  if (pointsAvailable >= 1) {
    headingText = (
      <>
        Congrats! You have earned{' '}
        <span className="fw-bold">
          {pointsAvailable} {isPlural(pointsAvailable, 'Point', 'Points')}
        </span>
      </>
    );
  }
  if (rewardsAvailable > 1) {
    headingText = (
      <>
        Congrats! You have{' '}
        <span className="fw-bold">{rewardsAvailable} Free</span> items that can
        be redeemed on your next order.
      </>
    );
  }
  if (rewardsAvailable === 1) {
    headingText = (
      <>
        Congrats! You have{' '}
        <span className="fw-bold">{rewardsAvailable} Free reward</span> that can
        be redeemed on your next order!
      </>
    );
  }

  return (
    <div className="col-12">
      <div className="row">
        <div className="col-12 mb-3 text-center">
          <h1 className={Styles.rewardHeader}>Your Rewards</h1>
          <p className={Styles.rewardSubheader}>{headingText}</p>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6 mb-3">
          <div className="d-flex justify-content-center justify-content-md-end h-100 position-relative">
            {[...Array(cardsCeil || 1)].slice(0, 3).map((_, index) => (
              <div
                className={`${Styles.card} ${Styles[`card_${index}`]}`}
                key={index}
              >
                <RewardsCard
                  remainingPoints={remainingPoints}
                  rewardPointGoal={rewardPointGoal}
                  logo={logo || ''}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="col-12 col-md-6 mb-3">
          <div className="d-flex justify-content-center justify-content-md-start h-100 position-relative">
            <div className={`${Styles.card} ${Styles.summaryCard}`}>
              <div className="d-flex align-items-center h-100">
                <div className="w-100">
                  <h5 className={Styles.summaryTitle}>Summary</h5>
                  <div className="mb-2 d-flex fs-6">
                    <div className="w-100"> Total Points</div>
                    <div className="flex-shrink-1 fw-bold">
                      {pointsAvailable}
                    </div>
                  </div>
                  <div className="mb-2 d-flex fs-6">
                    <div className="w-100">Points to Earn Next Reward</div>
                    <div className="flex-shrink-1 fw-bold">
                      {pointsNeededForNextReward}
                    </div>
                  </div>
                  <div className="mb-2 d-flex fs-6">
                    <div className="w-100">Rewards Available to Redeem</div>
                    <div className="flex-shrink-1 fw-bold">
                      {rewardsAvailable}
                    </div>
                  </div>
                  <div className="d-flex fs-6">
                    <div className="w-100">Total Rewards Redeemed</div>
                    <div className="flex-shrink-1 fw-bold">
                      {rewardsRedeemed}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 mb-3 text-center">
          <h2 className={Styles.thankYouText}>Thank you.</h2>
          <p className={Styles.thankYouMsg}>
            Thank you for shopping with me! <br /> I appreciate your support.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Rewards;
