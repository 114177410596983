import React, {
  useState,
  createContext,
  useContext,
  useCallback,
  PropsWithChildren,
} from 'react';
import {
  createNewSessionId,
  getOrCreateSessionId,
  setExistingSessionId,
} from '../utilities/SessionIdStorage';

type SessionContextType = {
  sessionId: string;
  generateNewSessionId(): void;
  specifySessionId(sessionId: string): void;
};

const SessionContext = createContext<SessionContextType>({
  sessionId: '',
  generateNewSessionId: () => undefined,
  specifySessionId: () => undefined,
});

function SessionProvider(
  props: PropsWithChildren<{
    value?: SessionContextType;
  }>
): JSX.Element {
  const [sessionId, setSessionId] = useState<string>(getOrCreateSessionId());

  const generateNewSessionId = useCallback(() => {
    setSessionId(createNewSessionId());
  }, []);

  const specifySessionId = useCallback((newSessionId: string) => {
    setExistingSessionId(newSessionId);
    setSessionId(newSessionId);
  }, []);

  return (
    <SessionContext.Provider
      value={{ sessionId, generateNewSessionId, specifySessionId }}
      {...props}
    >
      {props.children}
    </SessionContext.Provider>
  );
}

function useSession(): SessionContextType {
  const session = useContext(SessionContext);
  return session;
}

export { SessionProvider, useSession };
