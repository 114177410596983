import React from 'react';
import SvgIcon from './SvgIcon';

type Props = {
  id?: string;
  className?: string;
  isActive?: boolean;
};

const IconCrown = ({
  className,
  id,
  isActive,
  ...other
}: Props): JSX.Element => (
  <SvgIcon
    id={id}
    className={className}
    title="Crown"
    isActive={isActive}
    viewBox="0 0 24 24"
    {...other}
  >
    <path
      d="M11.733,8.608l0.036-0.023L11.733,8.608l0.23,0.242L12,8.888l0.254-0.268c0,0,0.497-0.533,0.995-1.201
      c0.497-0.679,1.019-1.456,1.043-2.172c0-1.249-1.03-2.245-2.292-2.257c-1.274,0-2.293,1.008-2.305,2.257
      c0.024,0.716,0.546,1.493,1.042,2.172C11.235,8.074,11.733,8.608,11.733,8.608 M10.35,5.247h0.036c0-0.849,0.704-1.553,1.59-1.553
      c0.885,0,1.589,0.704,1.589,1.553v0.024c0,0.34-0.438,1.129-0.898,1.724c-0.255,0.339-0.51,0.643-0.691,0.849
      c-0.183-0.206-0.437-0.51-0.691-0.849c-0.461-0.607-0.911-1.396-0.898-1.735V5.235L10.35,5.247z M20.421,20.306H3.555
      c-0.194,0-0.351,0.157-0.351,0.351c0,0.194,0.157,0.354,0.351,0.354h16.866c0.194,0,0.352-0.159,0.352-0.354
      C20.772,20.463,20.615,20.306,20.421,20.306 M11.49,11.242v-0.013c0-2.245-1.821-4.064-4.064-4.064
      c-2.246,0-4.066,1.819-4.066,4.064c0,0.946,0.328,1.809,0.862,2.499c-0.364-0.155-0.776-0.254-1.202-0.254
      c-1.651,0-2.997,1.334-3.021,2.984v0.048c0,1.675,1.358,3.022,3.021,3.022h8.482v-8.287H11.49z M3.009,18.729
      c-1.226,0-2.22-0.996-2.232-2.233c0-1.237,0.995-2.231,2.232-2.231c1.226,0,2.221,0.994,2.233,2.231
      C5.242,17.732,4.247,18.729,3.009,18.729 M5.06,18.803H4.865l0.146-0.134c0.255-0.231,0.461-0.512,0.631-0.814l0.11-0.206
      l0.036,0.231c0.049,0.29,0.158,0.569,0.34,0.799l0.097,0.124H5.06z M7.535,18.729c-0.268-0.036-0.473-0.074-0.546-0.122
      c-0.365-0.169-0.583-0.522-0.619-0.91v-0.084c0-0.169,0.036-0.327,0.122-0.499l-0.037-0.012l0.037,0.012
      c0.182-0.375,0.545-0.616,0.934-0.643h0.084c0.146,0,0.291,0.024,0.424,0.086l0.024,0.013h0.013l0.036,0.012
      c0.243,0.134,0.886,0.824,1.372,1.443c0.193,0.255,0.388,0.484,0.534,0.692H7.535V18.729z M10.702,18.606
      c-0.182-0.241-0.461-0.618-0.776-1.008c-0.546-0.666-1.116-1.357-1.614-1.625l-0.049-0.025c-0.255-0.12-0.509-0.18-0.777-0.18
      c-0.049,0-0.084,0-0.133,0.012c-0.521,0.036-1.006,0.302-1.322,0.738v-0.012c0-0.618-0.183-1.188-0.498-1.66
      c0.57,0.289,1.213,0.461,1.894,0.461c1.346,0,2.536-0.657,3.275-1.663V18.606z M7.426,14.505c-1.809,0-3.276-1.467-3.276-3.275
      s1.468-3.276,3.276-3.276c1.807,0,3.275,1.468,3.275,3.276C10.689,13.038,9.233,14.505,7.426,14.505 M23.988,16.459
      c-0.024-1.65-1.371-2.984-3.021-2.984c-0.425,0-0.837,0.084-1.201,0.254c0.533-0.69,0.86-1.553,0.86-2.499
      c0-2.245-1.819-4.064-4.064-4.064s-4.064,1.819-4.064,4.064v8.3h8.481c1.674,0,3.021-1.359,3.021-3.022L23.988,16.459L23.988,16.459
      z M16.55,7.953c1.809,0,3.276,1.468,3.276,3.276s-1.468,3.275-3.276,3.275s-3.265-1.467-3.275-3.275
      C13.285,9.421,14.741,7.953,16.55,7.953 M17.605,17.696c-0.023,0.388-0.255,0.741-0.618,0.91c-0.074,0.048-0.278,0.086-0.546,0.122
      h-2.379c0.158-0.208,0.341-0.438,0.546-0.692c0.485-0.617,1.129-1.31,1.372-1.443l0.035-0.012h0.014l0.022-0.012
      c0.146-0.063,0.292-0.086,0.425-0.086h0.085c0.39,0.023,0.753,0.268,0.936,0.645l0.036-0.015l-0.036,0.015
      c0.072,0.157,0.108,0.327,0.108,0.497V17.696 M16.61,15.78c-0.049,0-0.084-0.012-0.134-0.012c-0.254,0-0.521,0.06-0.777,0.18
      l-0.047,0.025c-0.485,0.28-1.068,0.959-1.614,1.625c-0.315,0.39-0.595,0.767-0.776,1.008v-4.962c0.74,1.006,1.93,1.663,3.275,1.663
      c0.68,0,1.323-0.172,1.893-0.461c-0.313,0.473-0.496,1.042-0.496,1.66v0.012C17.618,16.082,17.132,15.816,16.61,15.78
      M17.764,18.803l0.097-0.124c0.183-0.229,0.303-0.509,0.34-0.799l0.036-0.231l0.109,0.206c0.157,0.303,0.376,0.583,0.618,0.814
      l0.146,0.134H17.764z M20.967,18.729c-1.238,0-2.233-0.996-2.233-2.233s0.995-2.231,2.233-2.231c1.225,0,2.22,0.994,2.231,2.231
      C23.187,17.732,22.191,18.729,20.967,18.729"
    />
  </SvgIcon>
);

export default IconCrown;
