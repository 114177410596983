import React from 'react';
import IconCrown from '../Icons/IconCrown';
import Styles from './RewardsCard.module.scss';

const RewardsCard = ({
  remainingPoints,
  rewardPointGoal,
  logo,
}: {
  remainingPoints: number;
  rewardPointGoal: number;
  logo: string;
}): JSX.Element => {
  const renderPunchCard = (checkedCount: number): JSX.Element => (
    <div className={Styles.punchCard}>
      {[...Array(rewardPointGoal)].map((_, index) => (
        <div key={index} className={Styles.punchCardMark}>
          <div className={Styles.punchCardPunch}>
            {index < checkedCount ? (
              <IconCrown className={Styles.iconPunch} />
            ) : (
              <>{index + 1}</>
            )}
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <div className={Styles.wrapper}>
      <div className={Styles.content}>
        <div className="algin-center">
          <img src={logo} className={Styles.logo} />
        </div>
        {renderPunchCard(remainingPoints)}
        <div className={Styles.bottomText}>
          Buy {rewardPointGoal} accessories & get 1 free!
        </div>
      </div>
      <IconCrown className={Styles.iconBackground} />
    </div>
  );
};

export default RewardsCard;
