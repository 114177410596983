import { createContext } from 'react';
import FeatureFlags, { FeatureFlagEnum } from '../types/FeatureFlags';
import {
  StoreProductCategory,
  StoreProductCollection,
} from '../types/StoreCollectionResponse';

export type AppContextType = {
  featureFlags: FeatureFlags | undefined;
  isFeatureFlagSet: (flag: FeatureFlagEnum) => boolean;
  collections?: StoreProductCollection[];
  tagCategories?: StoreProductCategory[];
};
// add information once its on the backend api url
// storeID: number;
// tenneteID: number;

const AppContext = createContext<AppContextType>({
  featureFlags: {},
  isFeatureFlagSet: () => false,
});

export default AppContext;
