import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import ImageCover from '../ImageCover';
import { useSession } from '../../context/SessionProvider';
import QuantityPicker from '../QuantityPicker';
import FormatUSD from '../../utilities/Formatter';
import Discount from '../../types/Discount';
import CartItemType from '../../types/CartItemType';
import PlaceholderImage from '../../images/inventory_image_1024x1024.png';
import Styles from './CartItem.module.scss';

type Props = {
  claimCode?: string;
  streamId?: string;
  discount?: Discount;
  item: CartItemType;
  productGroupId: number;
  quantity: number;
  readOnly?: boolean;
  removeItem?: (
    productId: number,
    claimCode?: string,
    streamId?: string
  ) => void;
  smallLayout?: boolean;
  updateCartItem?: (
    id: number,
    quantity: number,
    sessionId: string,
    claimCode?: string,
    streamId?: string
  ) => void;
};

const defaultProps = {
  claimCode: undefined,
  streamId: undefined,
  discount: undefined,
  readOnly: false,
  removeItem: undefined,
  smallLayout: false,
  updateCartItem: undefined,
};

const CartItem = ({
  claimCode,
  streamId,
  discount,
  item,
  productGroupId,
  quantity,
  readOnly,
  removeItem,
  smallLayout,
  updateCartItem,
}: Props): JSX.Element => {
  const primaryImage = item.productThumbnailImageUrl;
  const itemName = item.productName;
  const { sessionId } = useSession();

  let discountText: string | undefined;
  let discountAmount = 0;
  if (discount) {
    switch (discount.discountType) {
      case 'Dollar':
        discountText = `${FormatUSD.format(discount.discountAmount)} Off`;
        discountAmount = discount.discountAmount;
        break;
      case 'Percentage':
        discountText = `${discount.discountAmount * 100}% Off`;
        discountAmount = discount.discountAmount * item.productSalePrice;
        break;
      default:
        discountText = undefined;
        discountAmount = 0;
    }
  }

  return (
    <li
      data-testid="cart-item"
      className={clsx('clearfix', Styles.shopping_cart_item, {
        [Styles.small]: smallLayout,
      })}
    >
      <div
        className={clsx('row', {
          'g-0': smallLayout,
        })}
      >
        <div className="col-4">
          <Link to={`/products/${productGroupId}`}>
            <div className={Styles.shopping_cart_item_image_area}>
              <ImageCover
                src={primaryImage || PlaceholderImage}
                alt={itemName}
                objectFit="contain"
              />
            </div>
          </Link>
        </div>
        <div className="col-8">
          <div
            className={clsx('row g-0', {
              'pb-2': !smallLayout,
            })}
          >
            <div className="col-8">
              <Link
                to={`/products/${productGroupId}`}
                className={clsx('d-block text-break', {
                  'fw-bold': !smallLayout,
                })}
              >
                {itemName}
              </Link>
            </div>
            <div className={`col-4 text-end ${Styles.align_center_right}`}>
              <span className="ps-2">
                {FormatUSD.format(item.productSalePrice)}
              </span>
            </div>
          </div>
          {itemName && (
            <>
              <div
                className={clsx({
                  'pb-2': !smallLayout,
                })}
              >
                {claimCode && <div>Claim Code: {claimCode}</div>}
              </div>
              {discountText && (
                <div
                  className={clsx('row g-0', {
                    'pb-2': !smallLayout,
                  })}
                >
                  <div className="col">{discountText}</div>
                  <div className="col-auto text-end">
                    <span className="ps-2">
                      -{FormatUSD.format(discountAmount)}
                    </span>
                  </div>
                </div>
              )}
            </>
          )}
          <div
            className={clsx({
              'pb-2': !smallLayout,
            })}
          >
            <div className="row g-0">
              {readOnly ? (
                <div>{`Quantity: ${quantity}`}</div>
              ) : (
                <>
                  <div
                    className={`col align-self-center ${Styles.shopping_cart_quantity}`}
                  >
                    Quantity:
                  </div>
                  <div className="col">
                    <QuantityPicker
                      quantity={quantity}
                      maximumQuantity={item.additionalQuantityAvailable}
                      minimumQuantity={1}
                      onAfterIncrement={(newQuantity: number) => {
                        if (!readOnly && updateCartItem) {
                          updateCartItem(
                            item.productId,
                            newQuantity,
                            sessionId,
                            claimCode,
                            streamId
                          );
                        }
                      }}
                      onAfterDecrement={(newQuantity: number) => {
                        if (!readOnly && updateCartItem) {
                          updateCartItem(
                            item.productId,
                            newQuantity,
                            sessionId,
                            claimCode,
                            streamId
                          );
                        }
                      }}
                    />
                  </div>
                </>
              )}
            </div>
            {removeItem && (
              <div className="pb-2">
                <Button
                  data-testid="remove-item-btn"
                  color="primary"
                  className={clsx('float-end', Styles.remove_button, {
                    'fw-bold': !smallLayout,
                  })}
                  onClick={() =>
                    removeItem(item.productId, claimCode, streamId)
                  }
                >
                  Remove
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </li>
  );
};

CartItem.defaultProps = defaultProps;

export default CartItem;
