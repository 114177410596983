import React, { ChangeEvent } from 'react';
import clsx from 'clsx';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Skeleton from 'react-loading-skeleton';
import { TagFiltersTypes } from '../../types/InventorySearchParams';
import TagCategoryType from '../../types/TagCategoryType';
import Styles from './FilterList.module.scss';

interface Props {
  categories?: TagCategoryType[];
  displayFeatured?: boolean;
  featuredState?: boolean;
  tagFilters: TagFiltersTypes;
  loading: boolean;
  onFeaturedChange: () => void;
  onChange: (event: ChangeEvent<HTMLInputElement>, filterProp: number) => void;
  mobileFilterToggle: (clear: boolean) => void;
}

const defaultProps = {
  categories: [],
};

const FilterList = ({
  categories,
  displayFeatured,
  featuredState,
  tagFilters,
  loading,
  onFeaturedChange,
  onChange,
  mobileFilterToggle,
}: Props): JSX.Element => (
  <div className={`${Styles.filterList} pb-4`}>
    <h2 className="pt-0 pb-3 d-md-none">Filter By</h2>
    <div
      className={clsx(Styles.loadingWrapper, {
        [Styles.fadeOut]: !loading,
      })}
      data-testid="loading-filter-skeleton"
    >
      <Skeleton count={1} className="h5" />
      <div className="ps-4">
        <Skeleton count={3} className="mt-2" />
      </div>
      <Skeleton count={1} className="h5 mt-3" />
      <div className="ps-4">
        <Skeleton count={3} className="mt-2" />
      </div>
    </div>
    <div
      className={clsx(Styles.contentWrapper, {
        [Styles.fadeIn]: !loading,
      })}
    >
      {displayFeatured && (
        <>
          <h5 className="pt-0">Filter</h5>
          <FormGroup>
            <FormControlLabel
              className="pb-3"
              control={
                <Checkbox
                  id="displayFeatured"
                  onChange={onFeaturedChange}
                  name="displayFeatured"
                  color="primary"
                  checked={featuredState}
                />
              }
              label="Featured"
            />
          </FormGroup>
        </>
      )}
      {!loading &&
        categories?.map((category, index) => (
          <div key={`${category.id}-${index}`} className="pt-3">
            <h5>{category.name}</h5>
            <FormGroup>
              {category.tags.map((tag) => (
                <FormControlLabel
                  key={tag.id}
                  control={
                    <Checkbox
                      id={`pf-checkbox-${tag.name.replace(/\s+/g, '-')}`}
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        onChange(event, category.id)
                      }
                      name={`${tag.id}`}
                      color="primary"
                      checked={
                        tagFilters[category.id]?.includes(tag.id.toString()) ||
                        false
                      }
                    />
                  }
                  label={tag.name}
                />
              ))}
            </FormGroup>
          </div>
        ))}
    </div>
    <div
      className={`d-md-none row ms-0 py-3 ps-3 ${Styles.bottomButton_wrapper}`}
    >
      <div className="col-6 ps-0">
        <Button
          className="px-1"
          variant="outlined"
          color="primary"
          fullWidth
          onClick={() => mobileFilterToggle(true)}
        >
          Clear Filters
        </Button>
      </div>
      <div className="col-6 ps-0">
        <Button
          className="px-1"
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => mobileFilterToggle(false)}
        >
          Done
        </Button>
      </div>
    </div>
  </div>
);

FilterList.defaultProps = defaultProps;

export default FilterList;
