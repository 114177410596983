import React, { useState, MouseEvent } from 'react';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import HelpOutlineIcon from '../Icons/HelpOutlineIcon';
import FormatUSD from '../../utilities/Formatter';
import FreeShippingThreshold from '../FreeShippingThreshold';
import TaxStrategyType from '../../types/TaxStrategyType';
import Styles from './CheckoutPriceSummary.module.scss';

type Props = {
  shippingMethod?: string;
  shipping?: number;
  shippingTax?: number;
  topBorder?: boolean;
  subtotal: number;
  taxes?: number;
  total: number;
  displayFreeShippingThreshold?: boolean;
  qualifiesForFreeShipping?: boolean;
  taxStrategy?: TaxStrategyType;
};

const CheckoutPriceSummary = ({
  shippingMethod,
  shipping,
  shippingTax,
  subtotal,
  taxes,
  topBorder,
  total,
  taxStrategy,
  displayFreeShippingThreshold,
  qualifiesForFreeShipping,
}: Props): JSX.Element => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = (event: MouseEvent): void => {
    event.preventDefault();
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <div className={topBorder ? `${Styles.checkout_border_top} mt-3 pt-2` : ''}>
      <div className="row pb-2">
        <div className="col-6 fw-bold">Subtotal</div>
        <div className={`${Styles.right} col-6`}>
          {FormatUSD.format(subtotal)}
        </div>
      </div>
      {taxStrategy !== 'None' && (
        <div className="row pb-2">
          <div className="col-6 fw-bold">
            {taxStrategy === 'TaxReimbursement' ? (
              <a href="#" onClick={handleClickOpen}>
                Consultant Cost Reimbursement <HelpOutlineIcon />
              </a>
            ) : (
              'Sales Tax'
            )}
          </div>
          {taxes !== undefined ? (
            <div className={`${Styles.right} col-6`}>
              {FormatUSD.format(taxes)}
            </div>
          ) : (
            <div className={`${Styles.right} col-6 fst-italic`}>
              to be calculated
            </div>
          )}
        </div>
      )}
      {shippingMethod === 'Pickup' ? (
        <div className="row pb-2">
          <div className="col-6 fw-bold">Shipping</div>
          <div className={`${Styles.right} col-6`}>Pick Up</div>
        </div>
      ) : (
        <div className="row pb-2">
          <div className="col-6 fw-bold">Shipping</div>
          {shipping !== undefined ? (
            <div className={`${Styles.right} col-6`}>
              {shipping <= 0 ? 'Free' : FormatUSD.format(shipping)}
            </div>
          ) : (
            <div
              className={clsx(Styles.right, `col-6`, {
                [`fst-italic`]: !qualifiesForFreeShipping,
              })}
            >
              {qualifiesForFreeShipping ? 'Free' : 'to be calculated'}
            </div>
          )}
        </div>
      )}
      {shippingTax !== undefined &&
        shippingTax > 0 &&
        taxStrategy === 'ChargeTax' && (
          <div className="row pb-2">
            <div className="col-6 fw-bold">Shipping Tax</div>
            <div className={`${Styles.right} col-6`}>
              {FormatUSD.format(shippingTax)}
            </div>
          </div>
        )}
      {displayFreeShippingThreshold && <FreeShippingThreshold />}
      <div className={`${Styles.checkout_total} row py-2 mb-3`}>
        <div className="col-6">Order Total</div>
        <div className={`${Styles.right} col-6`}>{FormatUSD.format(total)}</div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-describedby="alert-dialog-Consultant-Cost-Reimbursement"
        data-testid="ccr-info-dialog"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-Consultant-Cost-Reimbursement">
            &quot;Consultant Cost Reimbursement&quot; denotes the reimbursement
            for the retail sales tax previously paid by Consultants on these
            items.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CheckoutPriceSummary;
