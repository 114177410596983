export const SupportedCountries = [
  {
    isoCode: 'USA',
    value: 'United States',
  },
];

export const Countries = [
  {
    isoCode: 'USA',
    value: 'United States',
  },
  {
    isoCode: 'AFG',
    value: 'Afghanistan',
  },
  {
    isoCode: 'ALA',
    value: 'Åland Islands',
  },
  {
    isoCode: 'ALB',
    value: 'Albania',
  },
  {
    isoCode: 'DZA',
    value: 'Algeria',
  },
  {
    isoCode: 'ASM',
    value: 'American Samoa',
  },
  {
    isoCode: 'AND',
    value: 'Andorra',
  },
  {
    isoCode: 'AGO',
    value: 'Angola',
  },
  {
    isoCode: 'AIA',
    value: 'Anguilla',
  },
  {
    isoCode: 'ATA',
    value: 'Antarctica',
  },
  {
    isoCode: 'ATG',
    value: 'Antigua and Barbuda',
  },
  {
    isoCode: 'ARG',
    value: 'Argentina',
  },
  {
    isoCode: 'ARM',
    value: 'Armenia',
  },
  {
    isoCode: 'ABW',
    value: 'Aruba',
  },
  {
    isoCode: 'AUS',
    value: 'Australia',
  },
  {
    isoCode: 'AUT',
    value: 'Austria',
  },
  {
    isoCode: 'AZE',
    value: 'Azerbaijan',
  },
  {
    isoCode: 'BHS',
    value: 'Bahamas',
  },
  {
    isoCode: 'BHR',
    value: 'Bahrain',
  },
  {
    isoCode: 'BGD',
    value: 'Bangladesh',
  },
  {
    isoCode: 'BRB',
    value: 'Barbados',
  },
  {
    isoCode: 'BLR',
    value: 'Belarus',
  },
  {
    isoCode: 'BEL',
    value: 'Belgium',
  },
  {
    isoCode: 'BLZ',
    value: 'Belize',
  },
  {
    isoCode: 'BEN',
    value: 'Benin',
  },
  {
    isoCode: 'BMU',
    value: 'Bermuda',
  },
  {
    isoCode: 'BTN',
    value: 'Bhutan',
  },
  {
    isoCode: 'BOL',
    value: 'Bolivia, Plurinational State of',
  },
  {
    isoCode: 'BES',
    value: 'Bonaire, Sint Eustatius and Saba',
  },
  {
    isoCode: 'BIH',
    value: 'Bosnia and Herzegovina',
  },
  {
    isoCode: 'BWA',
    value: 'Botswana',
  },
  {
    isoCode: 'BVT',
    value: 'Bouvet Island',
  },
  {
    isoCode: 'BRA',
    value: 'Brazil',
  },
  {
    isoCode: 'IOT',
    value: 'British Indian Ocean Territory',
  },
  {
    isoCode: 'BRN',
    value: 'Brunei Darussalam',
  },
  {
    isoCode: 'BGR',
    value: 'Bulgaria',
  },
  {
    isoCode: 'BFA',
    value: 'Burkina Faso',
  },
  {
    isoCode: 'BDI',
    value: 'Burundi',
  },
  {
    isoCode: 'KHM',
    value: 'Cambodia',
  },
  {
    isoCode: 'CMR',
    value: 'Cameroon',
  },
  {
    isoCode: 'CAN',
    value: 'Canada',
  },
  {
    isoCode: 'CPV',
    value: 'Cape Verde',
  },
  {
    isoCode: 'CYM',
    value: 'Cayman Islands',
  },
  {
    isoCode: 'CAF',
    value: 'Central African Republic',
  },
  {
    isoCode: 'TCD',
    value: 'Chad',
  },
  {
    isoCode: 'CHL',
    value: 'Chile',
  },
  {
    isoCode: 'CHN',
    value: 'China',
  },
  {
    isoCode: 'CXR',
    value: 'Christmas Island',
  },
  {
    isoCode: 'CCK',
    value: 'Cocos (Keeling) Islands',
  },
  {
    isoCode: 'COL',
    value: 'Colombia',
  },
  {
    isoCode: 'COM',
    value: 'Comoros',
  },
  {
    isoCode: 'COG',
    value: 'Congo',
  },
  {
    isoCode: 'COD',
    value: 'Congo, the Democratic Republic of the',
  },
  {
    isoCode: 'COK',
    value: 'Cook Islands',
  },
  {
    isoCode: 'CRI',
    value: 'Costa Rica',
  },
  {
    isoCode: 'CIV',
    value: "Côte d'Ivoire",
  },
  {
    isoCode: 'HRV',
    value: 'Croatia',
  },
  {
    isoCode: 'CUB',
    value: 'Cuba',
  },
  {
    isoCode: 'CUW',
    value: 'Curaçao',
  },
  {
    isoCode: 'CYP',
    value: 'Cyprus',
  },
  {
    isoCode: 'CZE',
    value: 'Czech Republic',
  },
  {
    isoCode: 'DNK',
    value: 'Denmark',
  },
  {
    isoCode: 'DJI',
    value: 'Djibouti',
  },
  {
    isoCode: 'DMA',
    value: 'Dominica',
  },
  {
    isoCode: 'DOM',
    value: 'Dominican Republic',
  },
  {
    isoCode: 'ECU',
    value: 'Ecuador',
  },
  {
    isoCode: 'EGY',
    value: 'Egypt',
  },
  {
    isoCode: 'SLV',
    value: 'El Salvador',
  },
  {
    isoCode: 'GNQ',
    value: 'Equatorial Guinea',
  },
  {
    isoCode: 'ERI',
    value: 'Eritrea',
  },
  {
    isoCode: 'EST',
    value: 'Estonia',
  },
  {
    isoCode: 'ETH',
    value: 'Ethiopia',
  },
  {
    isoCode: 'FLK',
    value: 'Falkland Islands (Malvinas)',
  },
  {
    isoCode: 'FRO',
    value: 'Faroe Islands',
  },
  {
    isoCode: 'FJI',
    value: 'Fiji',
  },
  {
    isoCode: 'FIN',
    value: 'Finland',
  },
  {
    isoCode: 'FRA',
    value: 'France',
  },
  {
    isoCode: 'GUF',
    value: 'French Guiana',
  },
  {
    isoCode: 'PYF',
    value: 'French Polynesia',
  },
  {
    isoCode: 'ATF',
    value: 'French Southern Territories',
  },
  {
    isoCode: 'GAB',
    value: 'Gabon',
  },
  {
    isoCode: 'GMB',
    value: 'Gambia',
  },
  {
    isoCode: 'GEO',
    value: 'Georgia',
  },
  {
    isoCode: 'DEU',
    value: 'Germany',
  },
  {
    isoCode: 'GHA',
    value: 'Ghana',
  },
  {
    isoCode: 'GIB',
    value: 'Gibraltar',
  },
  {
    isoCode: 'GRC',
    value: 'Greece',
  },
  {
    isoCode: 'GRL',
    value: 'Greenland',
  },
  {
    isoCode: 'GRD',
    value: 'Grenada',
  },
  {
    isoCode: 'GLP',
    value: 'Guadeloupe',
  },
  {
    isoCode: 'GUM',
    value: 'Guam',
  },
  {
    isoCode: 'GTM',
    value: 'Guatemala',
  },
  {
    isoCode: 'GGY',
    value: 'Guernsey',
  },
  {
    isoCode: 'GIN',
    value: 'Guinea',
  },
  {
    isoCode: 'GNB',
    value: 'Guinea-Bissau',
  },
  {
    isoCode: 'GUY',
    value: 'Guyana',
  },
  {
    isoCode: 'HTI',
    value: 'Haiti',
  },
  {
    isoCode: 'HMD',
    value: 'Heard Island and McDonald Islands',
  },
  {
    isoCode: 'VAT',
    value: 'Holy See (Vatican City State)',
  },
  {
    isoCode: 'HND',
    value: 'Honduras',
  },
  {
    isoCode: 'HKG',
    value: 'Hong Kong',
  },
  {
    isoCode: 'HUN',
    value: 'Hungary',
  },
  {
    isoCode: 'ISL',
    value: 'Iceland',
  },
  {
    isoCode: 'IND',
    value: 'India',
  },
  {
    isoCode: 'IDN',
    value: 'Indonesia',
  },
  {
    isoCode: 'IRN',
    value: 'Iran, Islamic Republic of',
  },
  {
    isoCode: 'IRQ',
    value: 'Iraq',
  },
  {
    isoCode: 'IRL',
    value: 'Ireland',
  },
  {
    isoCode: 'IMN',
    value: 'Isle of Man',
  },
  {
    isoCode: 'ISR',
    value: 'Israel',
  },
  {
    isoCode: 'ITA',
    value: 'Italy',
  },
  {
    isoCode: 'JAM',
    value: 'Jamaica',
  },
  {
    isoCode: 'JPN',
    value: 'Japan',
  },
  {
    isoCode: 'JEY',
    value: 'Jersey',
  },
  {
    isoCode: 'JOR',
    value: 'Jordan',
  },
  {
    isoCode: 'KAZ',
    value: 'Kazakhstan',
  },
  {
    isoCode: 'KEN',
    value: 'Kenya',
  },
  {
    isoCode: 'KIR',
    value: 'Kiribati',
  },
  {
    isoCode: 'PRK',
    value: "Korea, Democratic People's Republic of",
  },
  {
    isoCode: 'KOR',
    value: 'Korea, Republic of',
  },
  {
    isoCode: 'KWT',
    value: 'Kuwait',
  },
  {
    isoCode: 'KGZ',
    value: 'Kyrgyzstan',
  },
  {
    isoCode: 'LAO',
    value: "Lao People's Democratic Republic",
  },
  {
    isoCode: 'LVA',
    value: 'Latvia',
  },
  {
    isoCode: 'LBN',
    value: 'Lebanon',
  },
  {
    isoCode: 'LSO',
    value: 'Lesotho',
  },
  {
    isoCode: 'LBR',
    value: 'Liberia',
  },
  {
    isoCode: 'LBY',
    value: 'Libya',
  },
  {
    isoCode: 'LIE',
    value: 'Liechtenstein',
  },
  {
    isoCode: 'LTU',
    value: 'Lithuania',
  },
  {
    isoCode: 'LUX',
    value: 'Luxembourg',
  },
  {
    isoCode: 'MAC',
    value: 'Macao',
  },
  {
    isoCode: 'MKD',
    value: 'Macedonia, the former Yugoslav Republic of',
  },
  {
    isoCode: 'MDG',
    value: 'Madagascar',
  },
  {
    isoCode: 'MWI',
    value: 'Malawi',
  },
  {
    isoCode: 'MYS',
    value: 'Malaysia',
  },
  {
    isoCode: 'MDV',
    value: 'Maldives',
  },
  {
    isoCode: 'MLI',
    value: 'Mali',
  },
  {
    isoCode: 'MLT',
    value: 'Malta',
  },
  {
    isoCode: 'MHL',
    value: 'Marshall Islands',
  },
  {
    isoCode: 'MTQ',
    value: 'Martinique',
  },
  {
    isoCode: 'MRT',
    value: 'Mauritania',
  },
  {
    isoCode: 'MUS',
    value: 'Mauritius',
  },
  {
    isoCode: 'MYT',
    value: 'Mayotte',
  },
  {
    isoCode: 'MEX',
    value: 'Mexico',
  },
  {
    isoCode: 'FSM',
    value: 'Micronesia, Federated States of',
  },
  {
    isoCode: 'MDA',
    value: 'Moldova, Republic of',
  },
  {
    isoCode: 'MCO',
    value: 'Monaco',
  },
  {
    isoCode: 'MNG',
    value: 'Mongolia',
  },
  {
    isoCode: 'MNE',
    value: 'Montenegro',
  },
  {
    isoCode: 'MSR',
    value: 'Montserrat',
  },
  {
    isoCode: 'MAR',
    value: 'Morocco',
  },
  {
    isoCode: 'MOZ',
    value: 'Mozambique',
  },
  {
    isoCode: 'MMR',
    value: 'Myanmar',
  },
  {
    isoCode: 'NAM',
    value: 'Namibia',
  },
  {
    isoCode: 'NRU',
    value: 'Nauru',
  },
  {
    isoCode: 'NPL',
    value: 'Nepal',
  },
  {
    isoCode: 'NLD',
    value: 'Netherlands',
  },
  {
    isoCode: 'NCL',
    value: 'New Caledonia',
  },
  {
    isoCode: 'NZL',
    value: 'New Zealand',
  },
  {
    isoCode: 'NIC',
    value: 'Nicaragua',
  },
  {
    isoCode: 'NER',
    value: 'Niger',
  },
  {
    isoCode: 'NGA',
    value: 'Nigeria',
  },
  {
    isoCode: 'NIU',
    value: 'Niue',
  },
  {
    isoCode: 'NFK',
    value: 'Norfolk Island',
  },
  {
    isoCode: 'MNP',
    value: 'Northern Mariana Islands',
  },
  {
    isoCode: 'NOR',
    value: 'Norway',
  },
  {
    isoCode: 'OMN',
    value: 'Oman',
  },
  {
    isoCode: 'PAK',
    value: 'Pakistan',
  },
  {
    isoCode: 'PLW',
    value: 'Palau',
  },
  {
    isoCode: 'PSE',
    value: 'Palestinian Territory, Occupied',
  },
  {
    isoCode: 'PAN',
    value: 'Panama',
  },
  {
    isoCode: 'PNG',
    value: 'Papua New Guinea',
  },
  {
    isoCode: 'PRY',
    value: 'Paraguay',
  },
  {
    isoCode: 'PER',
    value: 'Peru',
  },
  {
    isoCode: 'PHL',
    value: 'Philippines',
  },
  {
    isoCode: 'PCN',
    value: 'Pitcairn',
  },
  {
    isoCode: 'POL',
    value: 'Poland',
  },
  {
    isoCode: 'PRT',
    value: 'Portugal',
  },
  {
    isoCode: 'PRI',
    value: 'Puerto Rico',
  },
  {
    isoCode: 'QAT',
    value: 'Qatar',
  },
  {
    isoCode: 'REU',
    value: 'Réunion',
  },
  {
    isoCode: 'ROU',
    value: 'Romania',
  },
  {
    isoCode: 'RUS',
    value: 'Russian Federation',
  },
  {
    isoCode: 'RWA',
    value: 'Rwanda',
  },
  {
    isoCode: 'BLM',
    value: 'Saint Barthélemy',
  },
  {
    isoCode: 'SHN',
    value: 'Saint Helena, Ascension and Tristan da Cunha',
  },
  {
    isoCode: 'KNA',
    value: 'Saint Kitts and Nevis',
  },
  {
    isoCode: 'LCA',
    value: 'Saint Lucia',
  },
  {
    isoCode: 'MAF',
    value: 'Saint Martin (French part)',
  },
  {
    isoCode: 'SPM',
    value: 'Saint Pierre and Miquelon',
  },
  {
    isoCode: 'VCT',
    value: 'Saint Vincent and the Grenadines',
  },
  {
    isoCode: 'WSM',
    value: 'Samoa',
  },
  {
    isoCode: 'SMR',
    value: 'San Marino',
  },
  {
    isoCode: 'STP',
    value: 'Sao Tome and Principe',
  },
  {
    isoCode: 'SAU',
    value: 'Saudi Arabia',
  },
  {
    isoCode: 'SEN',
    value: 'Senegal',
  },
  {
    isoCode: 'SRB',
    value: 'Serbia',
  },
  {
    isoCode: 'SYC',
    value: 'Seychelles',
  },
  {
    isoCode: 'SLE',
    value: 'Sierra Leone',
  },
  {
    isoCode: 'SGP',
    value: 'Singapore',
  },
  {
    isoCode: 'SXM',
    value: 'Sint Maarten (Dutch part)',
  },
  {
    isoCode: 'SVK',
    value: 'Slovakia',
  },
  {
    isoCode: 'SVN',
    value: 'Slovenia',
  },
  {
    isoCode: 'SLB',
    value: 'Solomon Islands',
  },
  {
    isoCode: 'SOM',
    value: 'Somalia',
  },
  {
    isoCode: 'ZAF',
    value: 'South Africa',
  },
  {
    isoCode: 'SGS',
    value: 'South Georgia and the South Sandwich Islands',
  },
  {
    isoCode: 'SSD',
    value: 'South Sudan',
  },
  {
    isoCode: 'ESP',
    value: 'Spain',
  },
  {
    isoCode: 'LKA',
    value: 'Sri Lanka',
  },
  {
    isoCode: 'SDN',
    value: 'Sudan',
  },
  {
    isoCode: 'SUR',
    value: 'Suriname',
  },
  {
    isoCode: 'SJM',
    value: 'Svalbard and Jan Mayen',
  },
  {
    isoCode: 'SWZ',
    value: 'Swaziland',
  },
  {
    isoCode: 'SWE',
    value: 'Sweden',
  },
  {
    isoCode: 'CHE',
    value: 'Switzerland',
  },
  {
    isoCode: 'SYR',
    value: 'Syrian Arab Republic',
  },
  {
    isoCode: 'TWN',
    value: 'Taiwan, Province of China',
  },
  {
    isoCode: 'TJK',
    value: 'Tajikistan',
  },
  {
    isoCode: 'TZA',
    value: 'Tanzania, United Republic of',
  },
  {
    isoCode: 'THA',
    value: 'Thailand',
  },
  {
    isoCode: 'TLS',
    value: 'Timor-Leste',
  },
  {
    isoCode: 'TGO',
    value: 'Togo',
  },
  {
    isoCode: 'TKL',
    value: 'Tokelau',
  },
  {
    isoCode: 'TON',
    value: 'Tonga',
  },
  {
    isoCode: 'TTO',
    value: 'Trinidad and Tobago',
  },
  {
    isoCode: 'TUN',
    value: 'Tunisia',
  },
  {
    isoCode: 'TUR',
    value: 'Turkey',
  },
  {
    isoCode: 'TKM',
    value: 'Turkmenistan',
  },
  {
    isoCode: 'TCA',
    value: 'Turks and Caicos Islands',
  },
  {
    isoCode: 'TUV',
    value: 'Tuvalu',
  },
  {
    isoCode: 'UGA',
    value: 'Uganda',
  },
  {
    isoCode: 'UKR',
    value: 'Ukraine',
  },
  {
    isoCode: 'ARE',
    value: 'United Arab Emirates',
  },
  {
    isoCode: 'GBR',
    value: 'United Kingdom',
  },
  {
    isoCode: 'UMI',
    value: 'United States Minor Outlying Islands',
  },
  {
    isoCode: 'URY',
    value: 'Uruguay',
  },
  {
    isoCode: 'UZB',
    value: 'Uzbekistan',
  },
  {
    isoCode: 'VUT',
    value: 'Vanuatu',
  },
  {
    isoCode: 'VEN',
    value: 'Venezuela, Bolivarian Republic of',
  },
  {
    isoCode: 'VNM',
    value: 'Viet Nam',
  },
  {
    isoCode: 'VGB',
    value: 'Virgin Islands, British',
  },
  {
    isoCode: 'VIR',
    value: 'Virgin Islands, U.S.',
  },
  {
    isoCode: 'WLF',
    value: 'Wallis and Futuna',
  },
  {
    isoCode: 'ESH',
    value: 'Western Sahara',
  },
  {
    isoCode: 'YEM',
    value: 'Yemen',
  },
  {
    isoCode: 'ZMB',
    value: 'Zambia',
  },
  {
    isoCode: 'ZWE',
    value: 'Zimbabwe',
  },
];
