import React from 'react';

const IconDefaultStore = (): JSX.Element => (
  <div
    className="rounded-circle overflow-hidden m-2"
    style={{
      background: 'rgb(218 218 218 / 100%)',
      margin: '2vw',
      padding: '1rem',
      maxWidth: '180px',
    }}
  >
    <svg
      width="77"
      height="77"
      data-testid="my-store-image-default"
      className="img-fluid"
      viewBox="0 0 77 77"
      fill="#dfdfdf"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        background: 'rgb(218 218 218 / 100%)',
      }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6221 24.178C12.4497 28.1985 15.4644 32.219 19.6514 32.219C23.12 32.219 25.9318 29.4065 25.9318 25.937V13.3729H16.7728L13.6221 24.178ZM19.6514 38.5011C23.403 38.5011 26.7705 36.856 29.0721 34.2476C31.3737 36.856 34.7412 38.5011 38.4928 38.5011C42.2444 38.5011 45.6119 36.8559 47.9135 34.2476C50.2151 36.8559 53.5826 38.5011 57.3342 38.5011V63.6293H54.194V51.0652C54.194 45.861 49.9762 41.6421 44.7733 41.6421C39.695 41.6421 35.5553 45.6612 35.3598 50.6928V60.4888H35.3525V63.6293H19.6514V58.9177H19.6546V52.6357H19.6514V38.5011ZM57.3342 69.9113H51.0537H38.4928H19.6514C16.1827 69.9113 13.3709 67.0988 13.3709 63.6293V36.8271C8.58361 34.0548 5.89639 28.2369 7.59285 22.419L11.0128 10.6905C11.6348 8.55737 13.5897 7.09082 15.8112 7.09082H29.0721H47.9135H61.1743C63.3958 7.09082 65.3507 8.55738 65.9728 10.6905L69.3927 22.419C71.0892 28.2369 68.4019 34.0548 63.6147 36.8271V63.6293C63.6147 67.0988 60.8028 69.9113 57.3342 69.9113ZM47.9135 51.0652V63.6293H41.633V51.0652C41.633 49.3304 43.0389 47.9242 44.7733 47.9242C46.5076 47.9242 47.9135 49.3304 47.9135 51.0652ZM51.0537 13.3729L51.0537 25.937C51.0537 29.4065 53.8656 32.219 57.3342 32.219C61.5212 32.219 64.5358 28.1985 63.3635 24.178L60.2128 13.3729H51.0537ZM44.7733 13.3729H32.2123L32.2123 25.937C32.2123 29.4065 35.0242 32.219 38.4928 32.219C41.9614 32.219 44.7733 29.4065 44.7733 25.937V13.3729Z"
        fill="#6E6E6E"
      />
    </svg>
  </div>
);

export default IconDefaultStore;
