import React, { FC } from 'react';
import { Grid } from '@material-ui/core';
import clsx from 'clsx';
import Store from '../../types/Store';
import FooterLinks from '../../types/FooterLinks';
import Contact from './Contact';
import LinkGroup from './LinkGroup';
import Styles from './Footer.module.scss';

interface FooterProps {
  storeInfo: Store;
}

const Footer: FC<FooterProps> = ({ storeInfo }) => {
  const defaultInformationLinks: FooterLinks[] = [
    { linkText: 'Shipping Policy', url: '/shipping-policy' },
    { linkText: 'Return Policy', url: '/return-policy' },
    { linkText: 'Contact', url: '/contact' },
  ];
  const defaultLegalLinks: FooterLinks[] = [];

  const {
    myStory: { pageName },
    customerContactInfo: { emailAddress, phoneNumber },
    tenant: {
      staticDocuments: { PrivacyPolicy, TermsOfUse },
    },
    byDesignRepDID,
    facebookAccount,
    instagramAccount,
    pinterestAccount,
    twitterAccount,
    youtubeAccount,
  } = storeInfo;

  if (pageName) {
    defaultInformationLinks.unshift({
      linkText: pageName,
      url: '/about',
    });
  }

  if (byDesignRepDID) {
    defaultInformationLinks.push({
      linkText: 'Join My Team',
      url: `https://paparazziaccessories.com/join/${byDesignRepDID}/#/`,
      external: true,
    });
  }

  if (PrivacyPolicy) {
    defaultLegalLinks.push({
      linkText: 'Privacy Policy',
      url: PrivacyPolicy,
      external: true,
    });
  }

  if (TermsOfUse) {
    defaultLegalLinks.push({
      linkText: 'Terms of Use',
      url: TermsOfUse,
      external: true,
    });
  }

  return (
    <Grid container className={clsx(Styles.footer, 'p-5')}>
      <Grid container style={{ maxWidth: '1400px' }} className="mx-auto px-1">
        <Grid
          item
          xs={12}
          md={6}
          className={'order-md-2 text-center text-md-end'}
        >
          <Contact
            emailAddress={emailAddress}
            phoneNumber={phoneNumber}
            facebookAccount={facebookAccount}
            instagramAccount={instagramAccount}
            pinterestAccount={pinterestAccount}
            twitterAccount={twitterAccount}
            youtubeAccount={youtubeAccount}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <LinkGroup header="Information" links={defaultInformationLinks} />
        </Grid>
        <Grid item xs={6} md={3}>
          <LinkGroup header="Legal" links={defaultLegalLinks} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Footer;
