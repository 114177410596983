import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';

type Props = {
  className?: string;
  expirationDate?: Date;
  onTimeZero: () => void;
};

const defaultProps = {
  className: undefined,
  expirationDate: undefined,
};

const CountdownTimer = ({
  className,
  onTimeZero,
  expirationDate,
}: Props): JSX.Element => {
  const [timeLeft, setTimeLeft] = useState<string>();

  useEffect(() => {
    const timer = setInterval(() => {
      if (expirationDate) {
        const luxonTime = DateTime.fromJSDate(expirationDate).diff(
          DateTime.fromJSDate(new Date())
        );

        const daysHoursMinutesSeconds = luxonTime
          .toFormat('d h m s')
          .split(' ');
        const day = Number(daysHoursMinutesSeconds[0]);
        const hr = Number(daysHoursMinutesSeconds[1]);
        const min = Number(daysHoursMinutesSeconds[2]);
        const sec = Number(daysHoursMinutesSeconds[3]);

        if (day) {
          const hrDisplay = hr ? ` ${hr} hr` : '';
          const pluralDay = day > 1 ? 'days' : 'day';
          setTimeLeft(`${day} ${pluralDay}${hrDisplay}`);
        } else if (hr) {
          const minDisplay = min ? ` ${min} min` : '';
          setTimeLeft(`${hr} hr${minDisplay}`);
        } else if (min > 9) {
          setTimeLeft(`${min} min`);
        } else if (min) {
          setTimeLeft(`${min} min ${sec} sec`);
        } else if (sec) {
          setTimeLeft(`${sec} sec`);
        } else {
          onTimeZero();
          clearTimeout(timer);
        }
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [onTimeZero, expirationDate]);

  return <div className={className}>{timeLeft}</div>;
};

CountdownTimer.defaultProps = defaultProps;

export default CountdownTimer;
