import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import LoadingSpinner from '../../components/LoadingSpinner';
import RetailerInformationContext from '../../context/RetailerInformationContext';
import { useSession } from '../../context/SessionProvider';
import { claimCartToken } from '../../api/ShoppingApi/clients/CartClient';
import Styles from './ReplyToBuy.module.scss';

function ReplyToBuy(): JSX.Element {
  const navigate = useNavigate();
  const { claimToken } = useParams();
  const retailerInfo = useContext(RetailerInformationContext);
  const { sessionId, specifySessionId } = useSession();
  const [cartError, setCartError] = useState<string>();

  useEffect(() => {
    if (retailerInfo && claimToken) {
      const fetchData = async (): Promise<void> => {
        await claimCartToken(
          retailerInfo.store.tenant.id,
          retailerInfo.store.id,
          sessionId,
          {
            claimToken,
          }
        )
          .then((res) => {
            specifySessionId(res.sessionId);
            if (res.claimFailureMessage) {
              setCartError(res.claimFailureMessage);
            } else if (res.cartItems.length) {
              navigate('/cart');
            } else {
              setCartError('Your claim has expired. Please reclaim the item.');
            }
          })
          .catch((error) => {
            setCartError('There was an issue processing this claim.');
            Sentry.withScope((scope) => {
              scope.setLevel('warning');
              Sentry.captureMessage(
                `Warning - ${error}: Reply to Buy - ${error}`
              );
            });
          });
      };

      fetchData();
    }
  }, [retailerInfo, claimToken, sessionId, navigate, specifySessionId]);

  return (
    <div className="col-12">
      <div className="row">
        {cartError ? (
          <div>{cartError}</div>
        ) : (
          <div className={Styles.loading}>
            <LoadingSpinner />
          </div>
        )}
      </div>
    </div>
  );
}

export default ReplyToBuy;
