import React, { ReactNode, useRef, useEffect } from 'react';

interface Props {
  children: ReactNode;
  displayCart: boolean;
  clickOutside: () => void;
}

function useOutsideAlerter(
  ref: React.RefObject<HTMLDivElement>,
  clickOutside: () => void,
  displayCart: boolean
): void {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent): void {
      if (
        ref.current &&
        !ref.current.contains(event.target as Node) &&
        displayCart
      ) {
        clickOutside();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, displayCart, clickOutside]);
}

const OutsideAlerter = ({
  children,
  clickOutside,
  displayCart,
}: Props): JSX.Element => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  useOutsideAlerter(wrapperRef, clickOutside, displayCart);

  return <div ref={wrapperRef}>{children}</div>;
};

export default OutsideAlerter;
