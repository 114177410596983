import { useCallback, useMemo, useState } from 'react';
import InventoryItemSummaryResponse from '../../api/responses/InventoryItemSummaryResponse';
import TransitionalType from './TransitionalType';
import useSelectorsForProduct from './useSelectorsForProduct';

/**
 * Represents the availability of an option/tag in product selection.
 */
enum ProductOptionAvailability {
  /**
   * This option is available without having to change any other options.
   */
  Available = 'available',
  /**
   * This option is available, but only by changing a currently selected option.
   */
  OnlyWithOtherOptions = 'only_with_other_options',
  /**
   * This options is unavailable, regardless of other selections.
   */
  Unavailable = 'unavailable',
}

type ProductSelectionOption = {
  /**
   * The ID of the tag associated with this option.
   */
  tagId: number;
  /**
   * The ID of the category this option belongs to.
   */
  categoryId: number;
  /**
   * The display name of the tag.
   */
  tagName: string;
  /**
   * Whether this option is included in the user's current selection.
   */
  selected: boolean;
  /**
   * The availibity of this option, to know how to display its button.
   */
  availabililty: ProductOptionAvailability;
};

type ProductSelectionCategory = {
  /**
   * The tag category ID of this section.
   */
  categoryId: number;
  /**
   * The display name of this tag category.
   */
  categoryName: string;
  /**
   * Information about the options (tags) within this tag category.
   */
  options: ProductSelectionOption[];
  /**
   * If an option is selected, the ID of its tag. Otherwise null.
   */
  selectedTagId: number | null;
};

type SelectedProduct = {
  /** The ID of the selected product. */
  productId: number;
  /**
   * The quantity of the product available in this store (not sold and not
   * reserved on a mobile order). */
  quantityAvailable: number;
};

/**
 * Information about the selection of products within a group.
 */
type ProductSelection = {
  /**
   * False if no items in this group have any availability.
   */
  anyAvailable: boolean;
  /**
   * The categories of options (tags) possible within the product group,
   * with the possible options listed under each one.
   */
  categories: ProductSelectionCategory[];
  /**
   * A list of tag IDs in the current selection of options.
   */
  selectedTags: number[];
  /**
   * If enough tags are selected to narrow down the group to a single product,
   * this property will have information about that product.
   */
  selectedProduct: SelectedProduct | undefined;
  /**
   * A callback that lets you add a tag to the selection. If the tag collides
   * with another tag already selected in the same category, the colliding tag
   * will be deselected and replaced with the new one.
   */
  selectTag: (tagId: number) => void;
  /**
   * A callback that lets you remove a tag from the selection.
   */
  deselectTagCategory: (categoryId: number) => void;
};

/**
 * Provides information about options within a product group, and actions to
 * allow the selection of those options, with the goal of deriving a specifc
 * product ID for purchase.
 * @param productGroupId The ID of the product group to select a product from.
 * @param initialTags The tags to initialize the filter with; to be used for
 * deeplinking to particular products/selections within a group.
 */
export const useProductSelection = (
  productGroupId: number,
  initialTags: number[]
): TransitionalType<ProductSelection> => {
  const { isError, isLoading, tags, tagCategories, error, inventoryItems } =
    useSelectorsForProduct(productGroupId);
  const [selectedTags, setSelectedTags] = useState<number[]>(initialTags);

  const filterProductsByTags = useCallback(
    (filterTags: number[]) => {
      if (!inventoryItems) {
        return [];
      }
      return inventoryItems.filter((item) => {
        if (filterTags.some((tag) => !item.product.tagIds.includes(tag))) {
          return false;
        }
        return true;
      });
    },
    [inventoryItems]
  );

  /**
   * Returns a list of tag IDs if the given tag were to be selected. Tags from
   * the same category get removed; the given tag gets added.
   */
  const substituteTag = useCallback(
    (tagId: number) => {
      if (!tags) {
        return selectedTags;
      }
      if (selectedTags.includes(tagId)) {
        // It's already in there, dude.
        return selectedTags;
      }
      const foundTag = tags.find((tag) => tag.id === tagId);
      if (!foundTag) {
        return selectedTags;
      }
      return [
        ...selectedTags.filter((existing) => {
          const foundExistingTag = tags.find((tag) => tag.id === existing);
          if (foundExistingTag?.tagCategory.id === foundTag.tagCategory.id) {
            return false;
          }
          return true;
        }),
        tagId,
      ];
    },
    [selectedTags, tags]
  );

  const allOptions = useMemo<ProductSelectionOption[]>(() => {
    if (!tags) {
      return [];
    }
    return tags.map<ProductSelectionOption>((tag) => {
      let availabililty = ProductOptionAvailability.Unavailable;

      const anyAvailableWithThisTag = filterProductsByTags([tag.id]).filter(
        (item) => item.quantityAvailable
      );

      if (anyAvailableWithThisTag.length) {
        availabililty = ProductOptionAvailability.OnlyWithOtherOptions;
        const alsoAvailableWithCurrensSelection = filterProductsByTags(
          substituteTag(tag.id)
        ).filter((item) => item.quantityAvailable);
        if (alsoAvailableWithCurrensSelection.length) {
          availabililty = ProductOptionAvailability.Available;
        }
      }

      return {
        tagId: tag.id,
        categoryId: tag.tagCategory.id,
        tagName: tag.name,
        selected: selectedTags.includes(tag.id),
        availabililty,
      };
    });
  }, [filterProductsByTags, selectedTags, substituteTag, tags]);

  const categories = useMemo<ProductSelectionCategory[]>(() => {
    if (!tagCategories) {
      return [];
    }
    return tagCategories.map<ProductSelectionCategory>((category) => {
      const options = allOptions.filter(
        (option) => option.categoryId === category.id
      );

      const selectedTagId = options.find((option) =>
        selectedTags.includes(option.tagId)
      )?.tagId;

      return {
        categoryId: category.id,
        categoryName: category.name,
        options,
        selectedTagId: selectedTagId || null,
      };
    });
  }, [allOptions, selectedTags, tagCategories]);

  const filteredProducts = useMemo<InventoryItemSummaryResponse[]>(
    () => filterProductsByTags(selectedTags),
    [filterProductsByTags, selectedTags]
  );

  const anyAvailable = useMemo<boolean>(
    () => inventoryItems?.some((item) => item.quantityAvailable > 0) || false,
    [inventoryItems]
  );

  const selectedProduct = useMemo<SelectedProduct | undefined>(() => {
    if (categories.some((category) => !category.selectedTagId)) {
      // Don't consider a product selected if we haven't picked an option
      // in each category.
      return undefined;
    }
    if (filteredProducts.length === 1) {
      return {
        productId: filteredProducts[0].product.id,
        quantityAvailable: filteredProducts[0].quantityAvailable,
      };
    }
    return undefined;
  }, [categories, filteredProducts]);

  const deselectTagCategory = useCallback(
    (categoryId: number) => {
      if (!tags) {
        return;
      }
      const tagsInCategory = tags
        .filter((tag) => tag.tagCategory.id === categoryId)
        .map((tag) => tag.id);
      setSelectedTags(
        selectedTags.filter((tag) => !tagsInCategory.includes(tag))
      );
    },
    [selectedTags, tags]
  );

  const selectTag = useCallback(
    (tagId: number) => {
      const newSelection = substituteTag(tagId);
      const filteredAfterChange = filterProductsByTags(newSelection);
      if (filteredAfterChange.some((item) => item.quantityAvailable)) {
        // Good. There's at least one item available after changing that tag.
        setSelectedTags(newSelection);
      } else {
        // The change would result in filtering down to items that aren't
        // available. Select this tag, but deselect the ones in other categories
        setSelectedTags([tagId]);
      }
    },
    [filterProductsByTags, substituteTag]
  );

  return useMemo(() => {
    if (isLoading) {
      return {
        isLoading: true,
      };
    }
    if (isError) {
      return {
        isLoading: false,
        isError: true,
        error,
      };
    }

    return {
      isLoading: false,
      isError: false,
      anyAvailable,
      categories,
      selectedTags,
      selectedProduct,
      selectTag,
      deselectTagCategory,
    };
  }, [
    anyAvailable,
    categories,
    deselectTagCategory,
    error,
    isError,
    isLoading,
    selectTag,
    selectedProduct,
    selectedTags,
  ]);
};

export default useProductSelection;
