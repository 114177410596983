import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import Styles from './LoadingSpinner.module.scss';

function LoadingSpinner(): JSX.Element {
  const theme = useTheme();

  return (
    <div
      className={Styles.loading_spinner}
      style={{ color: theme.palette.primary.main }}
      data-testid="loading-spinner"
    >
      Loading...
    </div>
  );
}

export default LoadingSpinner;
