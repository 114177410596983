import React from 'react';
import Layout from '../../components/Layout/Layout';

interface Props {
  shippingPolicy: string;
}

const ShippingPolicy = ({ shippingPolicy }: Props): JSX.Element => (
  <Layout>
    <h1>Shipping Policy</h1>
    <p>{shippingPolicy}</p>
  </Layout>
);

export default ShippingPolicy;
