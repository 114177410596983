import { apiVersion, baseUrl } from '../apiConfig';

export type CustomerInformationRequestType = {
  emailAddress: string;
  firstName: string;
  lastName: string;
  phoneNumber: string | null;
};

export type CustomerInformationResponseErrorType = {
  errors: { [key: string]: string[] };
  title: string;
  status: string;
  detail: string;
  instance: string;
};

export async function postCustomerInformation(
  tenantId: number,
  storeId: number,
  request: CustomerInformationRequestType
): Promise<CustomerInformationResponseErrorType | null> {
  const url = `${baseUrl}/${apiVersion}/tenants/${tenantId}/stores/${storeId}/customers/register`;

  const response = await fetch(url, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(request),
  });

  const contentType = response.headers.get('content-type');
  if (contentType && contentType.indexOf('application/json') !== -1) {
    return response.json() as Promise<CustomerInformationResponseErrorType>;
  }

  return null;
}
