const shoppingApiBaseUrl = process.env
  .REACT_APP_SHOPPING_API_BASE_URL as string;
if (shoppingApiBaseUrl === undefined) {
  throw new Error('REACT_APP_SHOPPING_API_BASE_URL not set in .env file');
}

export const hostUrl = process.env.REACT_APP_DEV_HOST || window.location.host;
export const baseUrl = shoppingApiBaseUrl;
// TODO: Move the API version into a .env variable.
export const apiVersion = 'v6.1' as string;
export const futureApiVersion = 'v7' as string;

if (
  process.env.NODE_ENV !== 'production' ||
  process.env.REACT_APP_SHOPPING_API_BASE_URL?.includes('-dev.com') ||
  process.env.REACT_APP_SHOPPING_API_BASE_URL?.includes('-qa.com')
) {
  // eslint-disable-next-line no-console
  console.log(
    `Active API Version: ${process.env.REACT_APP_API_VERSION} | Stable: ${apiVersion} | Future: ${futureApiVersion}`
  );
}
