import * as Sentry from '@sentry/react';
import useSWR from 'swr';
import { apiVersion, baseUrl } from '../apiConfig';
import {
  StoreCollectionResponse,
  StoreProductCategory,
  StoreProductCollection,
} from '../../../types/StoreCollectionResponse';

async function getStoreCollections(
  tenantId: number,
  storeId: number
): Promise<StoreCollectionResponse> {
  const response = await fetch(
    `${baseUrl}/${apiVersion}/tenants/${tenantId}/stores/${storeId}/store-product-collections/collections-and-tags`
  );

  if (response.ok) {
    if (response.status === 204) {
      Sentry.withScope((scope) => {
        scope.setLevel('warning');
        Sentry.captureMessage(
          `Warning - ${response.status}: getStoreCollections - ${response.statusText}`
        );
      });

      throw new Error(`Status code: ${response.status}`);
    }

    return response.json() as Promise<StoreCollectionResponse>;
  }

  Sentry.captureMessage(`Error - ${response.status}: getStoreCollections`);

  throw new Error('Unable to retrieve store collections.');
}

// TODO: Should be fitting to change these all to other names besides Collection since it contains both Collections and categories.
export function useStoreCollections(
  tenantId: number | undefined,
  storeId: number | undefined
): {
  collections: StoreProductCollection[] | undefined;
  tagCategories: StoreProductCategory[] | undefined;
  collectionsError: Error | null | undefined;
} {
  const { data, error } = useSWR<StoreCollectionResponse>(
    `/collections/${tenantId}/${storeId}`,
    async () => {
      if (!tenantId || !storeId) {
        return Promise.resolve({
          tagCategories: [],
          collections: [],
        } as StoreCollectionResponse);
      }

      return getStoreCollections(tenantId, storeId);
    },
    { revalidateOnFocus: false }
  );

  return {
    collections: data?.collections,
    tagCategories: data?.tagCategories,
    collectionsError: error,
  };
}

export default getStoreCollections;
