// src/components/InventoryItem/InventoryItem.tsx

import React from 'react';
import { Link } from 'react-router-dom';
import { Card, makeStyles } from '@material-ui/core';
import ImageCover from '../ImageCover';
import Styles from './InventoryItem.module.scss';
import PlaceholderImage from '../../images/inventory_image_1024x1024.png';
import { RangeFormatter } from '../../utilities/RangeFormatter';
import {
  InventoryRecord,
  InventoryRecordApiV6,
  InventoryRecordApiV7,
} from '../../api/responses/InventoryResponse';
import { apiVersion } from '../../api/ShoppingApi/apiConfig';

const useStyles = makeStyles((theme) => ({
  productGroupItem: {
    border: 'none',
    '& a': {
      textDecoration: 'none',
      color: theme.palette.common.black,

      '&:hover': {
        textDecoration: 'none',
        color: theme.palette.primary.main,
      },
    },
    boxShadow: 'none',
    backgroundColor: 'transparent',
  },
}));

const InventoryItem = ({
  inventoryItem,
}: {
  inventoryItem: InventoryRecord;
}): JSX.Element => {
  const styles = useStyles();

  // DEPRECATED: The version 6 API DTO for inventory items should be removed
  // after API version 7 is released.
  // Type guarding against uncertain API versions
  // Creating a backwards compatible item

  const item =
    apiVersion === 'v6.1'
      ? (inventoryItem as InventoryRecordApiV6)
      : (inventoryItem as InventoryRecordApiV7);

  // Backwards compatible item identifier
  const itemIdentifier =
    apiVersion === 'v6.1'
      ? (inventoryItem as InventoryRecordApiV6).productGroupId
      : (inventoryItem as InventoryRecordApiV7).id;

  // Check if the item is a bundle
  const isBundle =
    apiVersion === 'v7' &&
    (inventoryItem as InventoryRecordApiV7).searchItemType === 1;

  // Number of items in a bundle
  const itemCountInBundle =
    apiVersion === 'v7' && isBundle
      ? (inventoryItem as InventoryRecordApiV7).itemsCount || 0
      : null;

  return (
    <div
      key={`inventory-item-${itemIdentifier}`}
      className="col-xl-2 col-lg-3 col-sm-4 col-6 ps-0 pb-4"
    >
      <Card className={styles.productGroupItem} data-testid="inventory-item">
        <Link key={itemIdentifier} to={`/products/${itemIdentifier}`}>
          <ImageCover
            objectFit="contain"
            className={Styles.productGroupImage}
            src={item.thumbnailImageUrl || PlaceholderImage}
            alt={item.name}
          />
          <div className={`${Styles.productGroupName} mb-0`}>{item.name}</div>
          {(item.priceLow > 0 || item.priceHigh > 0) && (
            <div className={`${Styles.productGroupPrice} mb-0`}>
              {RangeFormatter.format(item.priceLow, item.priceHigh)}
            </div>
          )}
          {isBundle && (
            <div className={`${Styles.productGroupPrice} mb-0`}>
              ({itemCountInBundle || 0} items)
            </div>
          )}
        </Link>
      </Card>
    </div>
  );
};

export default InventoryItem;
