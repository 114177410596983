import React, { FC, ReactNode } from 'react';
import { Grid, Hidden } from '@material-ui/core';
import StoreProfile from '../StoreProfile';

interface LayoutProps {
  children: ReactNode;
}

const Layout: FC<LayoutProps> = ({ children }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Hidden smDown>
              <StoreProfile />
            </Hidden>
          </Grid>
          <Grid item xs={12} md={9}>
            {children}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Layout;
