import * as Sentry from '@sentry/react';
import { apiVersion, baseUrl, hostUrl } from '../apiConfig';
import Store from '../../../types/Store';

/**
 * Get Store By Alias or Id
 * @param alias
 */
async function getStoreByAlias(alias: string): Promise<Store> {
  const response = await fetch(
    `${baseUrl}/${apiVersion}/stores?domain=${hostUrl}&alias=${alias}`
  );

  if (response.ok) {
    if (response.status === 204) {
      Sentry.withScope((scope) => {
        scope.setLevel('warning');
        Sentry.captureMessage(
          `Warning - ${response.status}: getStoreByAlias - ${response.statusText}`
        );
      });

      throw new Error(`Status code: ${response.status}`);
    }

    return response.json() as Promise<Store>;
  }

  Sentry.captureMessage(`Error - ${response.status}: getStoreByAlias`);

  throw new Error('Unable to retrieve retailer store information.');
}

export default getStoreByAlias;
