import React from 'react';
import clsx from 'clsx';
import Styles from './ImageCover.module.scss';

interface ImageCoverProps {
  alt: string;
  className?: string;
  objectFit: 'fill' | 'contain' | 'cover' | 'scale-down' | 'none';
  src: string;
}

const defaultProps = {
  objectFit: 'cover',
};

const ImageCover = ({
  alt,
  className,
  objectFit,
  src,
  ...props
}: ImageCoverProps): JSX.Element => (
  <div className={clsx([Styles.itemImageArea], className)}>
    <img
      src={src}
      className={Styles.itemImage}
      style={{ objectFit }}
      alt={alt}
      {...props}
    />
  </div>
);

ImageCover.defaultProps = defaultProps;

export default ImageCover;
