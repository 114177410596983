import React from 'react';
import SvgIcon from './SvgIcon';

type Props = {
  id: string;
  className?: string;
  isActive?: boolean;
};

const PhoneIcon = ({
  className,
  id,
  isActive,
  ...other
}: Props): JSX.Element => (
  <SvgIcon
    id={id}
    className={className}
    title="Phone"
    isActive={isActive}
    viewBox="0 0 100 100"
    {...other}
  >
    <path
      d="M17.67,6.698c-5.442,3.196-10.036,8.438-11.834,13.43c-1.048,2.847-1.048,8.637-0.049,13.23
        c3.345,15.529,12.631,30.208,26.86,42.439c9.786,8.438,19.122,14.131,28.51,17.376c4.242,1.445,5.091,1.547,11.084,1.596
        c6.489,0,6.489,0,10.384-1.848c6.938-3.345,12.332-10.284,12.332-15.925v-2.35l-8.836-5.24c-10.686-6.292-11.786-6.839-13.181-6.839
        c-1.149,0-3.795,2.544-8.839,8.387c-1.448,1.696-3.048,3.095-3.545,3.095c-2.597,0-14.778-9.285-23.317-17.725
        c-7.589-7.539-11.332-13.58-10.484-16.974c0.2-0.699,2.147-2.746,4.494-4.694c4.943-4.144,6.79-6.24,6.79-7.738
        c0-0.599-2.795-5.691-6.19-11.333L25.657,5.4l-2.695-0.15C20.765,5.151,19.866,5.4,17.67,6.698z"
    />
  </SvgIcon>
);

export default PhoneIcon;
