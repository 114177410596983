import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { SessionProvider } from './context/SessionProvider';
import reportWebVitals from './reportWebVitals';
import './index.scss';

const {
  REACT_APP_SENTRY_DSN,
  REACT_APP_SENTRY_ENVIRONMENT_NAME,
  REACT_APP_SENTRY_TRACE_RATE,
} = process.env;

// Fix for pingbot causing promise errors in Sentry.io
if (!/pingbot/.test(navigator.userAgent)) {
  Sentry.init({
    dsn: REACT_APP_SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing()],
    environment: REACT_APP_SENTRY_ENVIRONMENT_NAME,
    allowUrls:
      // If running in the wild, only capture errors that orignate from our own
      // code, so we don't get noise from browser extensions or console commands.
      REACT_APP_SENTRY_ENVIRONMENT_NAME === 'local'
        ? undefined
        : [/^https?:\/\/shop\..*\/static\/js\/.*\.js/i],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: parseFloat(REACT_APP_SENTRY_TRACE_RATE || '1'),
  });
}

// MSW - Start the mocking conditionally.
if (process.env.NODE_ENV === 'development' && 'REACT_APP_MOCK' in process.env) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires, global-require
  const { worker } = require('./mocks/browser');
  worker.start();
}

const parsedUrl = window.location.pathname.split('/');
const webAlias = parsedUrl[1];

ReactDOM.render(
  <SessionProvider>
    <BrowserRouter basename={`/${webAlias}`}>
      <App />
    </BrowserRouter>
  </SessionProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
