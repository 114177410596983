import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import Snackbar, { SnackbarProps } from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Fade from '@material-ui/core/Fade';
import useAlerts from './useAlerts';

type Props = Pick<SnackbarProps, 'anchorOrigin' | 'TransitionComponent'>;
// The thought here is that if we want to implement a different library to
// render our alerts, we should be able to keep the rest of the context in place
// and just create a new display component that leverages the context like
// this one does.
const SnackbarAlertsDisplay: FunctionComponent<Props> = ({
  anchorOrigin = {
    horizontal: 'right',
    vertical: 'bottom',
  },
  TransitionComponent = Fade,
}) => {
  const { currentAlert, dismissAlert } = useAlerts();
  const [open, setOpen] = useState(false);

  const closeAlert = useCallback(
    (_event: React.SyntheticEvent | MouseEvent, reason?: string) => {
      if (reason !== 'clickaway') {
        setOpen(false);
      }
    },
    []
  );

  const handleExit = useCallback(() => {
    if (currentAlert) {
      dismissAlert(currentAlert.key);
    }
  }, [currentAlert, dismissAlert]);

  useEffect(() => {
    if (currentAlert) {
      setOpen(true);
    }
  }, [currentAlert]);

  if (currentAlert) {
    const { autoClose, key, message, severity } = currentAlert;
    return (
      <Snackbar
        anchorOrigin={anchorOrigin}
        key={key}
        open={open}
        autoHideDuration={autoClose ? 5000 : null}
        onClose={closeAlert}
        TransitionComponent={TransitionComponent}
        TransitionProps={{
          onExited: handleExit,
        }}
      >
        <Alert severity={severity} onClose={closeAlert}>
          {message}
        </Alert>
      </Snackbar>
    );
  }

  return null;
};

export default SnackbarAlertsDisplay;
