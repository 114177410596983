import React from 'react';
import { Link } from 'react-router-dom';
import FooterLinks from '../../types/FooterLinks';
import FooterStyles from './Footer.module.scss';

interface LinkGroupProps {
  header: string;
  links: FooterLinks[];
}

const LinkGroup = ({ header, links }: LinkGroupProps): JSX.Element => (
  <>
    <h6 className={FooterStyles.linkGroupHeader}>{header}</h6>
    {links.map((link) => (
      <div key={link.linkText}>
        {link.external ? (
          <a
            className={FooterStyles.link}
            href={link.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {link.linkText}
          </a>
        ) : (
          <Link className={FooterStyles.link} to={link.url}>
            {link.linkText}
          </Link>
        )}
      </div>
    ))}
  </>
);

export default LinkGroup;
