import React, { useState } from 'react';
import Cookies from 'universal-cookie';
import Button from '@material-ui/core/Button';
import Styles from './PrivacyPolicyNotification.module.scss';

interface Props {
  privacyPolicyUrl: string;
}

const PrivacyPolicyNotification = ({
  privacyPolicyUrl,
}: Props): JSX.Element => {
  const cookies = new Cookies();
  const [agree, setAgree] = useState<boolean>(false);
  const acknowledge = (): void => {
    cookies.set('WayRoo-CCPA-agreement', true, { maxAge: 5184000 });
    setAgree(true);
  };

  const ccpaCookie = cookies.get('WayRoo-CCPA-agreement');

  return agree || ccpaCookie ? (
    <></>
  ) : (
    <div className={Styles.notification} data-testid="ccpa-disclaimer">
      <div className="row">
        <div className="col-lg-10 col-md-12">
          <p className="mb-lg-0">
            To comply with the California Consumer Privacy Act, we&#39;re
            informing you of our use of cookies. By clicking <i>Agree</i>, you
            agree to the use of these cookies. View our{' '}
            <a
              href={privacyPolicyUrl}
              className={Styles.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
            . If you continue browsing, you are consenting to our use of these
            cookies.
          </p>
        </div>
        <div className="col-lg-2 col-md-12 align-self-center text-center text-md-end">
          <Button
            color="primary"
            variant="contained"
            onClick={acknowledge}
            data-testid="ccpa-agree-button"
          >
            Agree
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicyNotification;
