import React, { createContext } from 'react';
import AlertMessage from './AlertMessage';
import AlertSeverity from './AlertSeverity';

type AlertsContextType = {
  clearAlerts(): void;
  dismissAlert(key: number): void;
  sendAlert(
    message: string | React.ReactNode,
    severity?: AlertSeverity,
    autoclose?: boolean
  ): void;
  currentAlert?: AlertMessage;
};

export default createContext<AlertsContextType>({
  clearAlerts: () => undefined,
  dismissAlert: () => undefined,
  sendAlert: () => undefined,
  currentAlert: undefined,
});
