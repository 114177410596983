import React, { ReactNode, ChangeEvent } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IconRemove from '../Icons/IconRemove';
import IconAdd from '../Icons/IconAdd';
import Styles from './QuantityPicker.module.scss';

interface Props {
  /**
   * Quantity to display
   */
  quantity: number;

  /**
   * Make input instead of buttons
   */
  input?: boolean;

  /**
   * Maximum quantity before the increment button becomes disabled
   */
  maximumQuantity?: number;

  /**
   * Minimum quantity before the decrement button becomes disabled
   */
  minimumQuantity?: number;

  /**
   * Function to call before the quantity decrements.
   */
  onBeforeDecrement?: (currentQuantity: number) => boolean;

  /**
   * Function to call after the quantity has decremented.
   */
  onAfterDecrement?: (newQuantity: number) => void;

  /**
   * Function to call before the quantity increments.
   */
  onBeforeIncrement?: (currentQuantity: number) => boolean;

  /**
   * Function to call after the quantity has incremented
   */
  onAfterIncrement?: (newQuantity: number) => void;

  /**
   * Render a custom decrement icon
   */
  decrementIcon?: () => ReactNode;

  /**
   * Render a custom increment icon
   */
  incrementIcon?: () => ReactNode;
}

const QuantityPicker = ({
  quantity,
  input = false,
  maximumQuantity = undefined,
  minimumQuantity = 0,
  onBeforeDecrement = undefined,
  onAfterDecrement = undefined,
  onBeforeIncrement = undefined,
  onAfterIncrement = undefined,
  decrementIcon = undefined,
  incrementIcon = undefined,
}: Props): JSX.Element => {
  const onChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (onAfterDecrement) onAfterDecrement(parseInt(event.target.value, 10));
  };

  const decrement = (): void => {
    // Allow the parent to determine if a decrement should occur.
    if (onBeforeDecrement && !onBeforeDecrement(quantity)) {
      return;
    }

    if (quantity > 0) {
      const newQuantity = quantity - 1;
      if (onAfterDecrement) onAfterDecrement(newQuantity);
    }
  };

  const increment = (): void => {
    if (onBeforeIncrement && !onBeforeIncrement(quantity)) {
      return;
    }

    if (!maximumQuantity || (maximumQuantity && quantity < maximumQuantity)) {
      const newQuantity = quantity + 1;
      if (onAfterIncrement) onAfterIncrement(newQuantity);
    }
  };

  return input ? (
    <TextField
      label="Quantity"
      variant="outlined"
      type="number"
      fullWidth
      value={quantity}
      onChange={onChange}
    />
  ) : (
    <div className={Styles.container}>
      <Button
        variant="text"
        data-testid="decrement-quantity-btn"
        disabled={quantity === minimumQuantity || quantity === 0}
        onClick={decrement}
        className={Styles.buttons}
      >
        {decrementIcon !== undefined ? (
          decrementIcon
        ) : (
          <IconRemove id="remove-quantity-svg" className={Styles.icon} />
        )}
      </Button>
      <div className={Styles.quantity}>{quantity}</div>
      <Button
        data-testid="increment-quantity-btn"
        disabled={quantity === maximumQuantity || quantity === 0}
        onClick={increment}
        className={Styles.buttons}
      >
        {incrementIcon !== undefined ? (
          incrementIcon
        ) : (
          <IconAdd id="add-quantity-svg" className={Styles.icon} />
        )}
      </Button>
    </div>
  );
};

export default QuantityPicker;
