import FormatUSD from './Formatter';

/*
would rather use
https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/formatRange
but it's still experimental...
*/
export class RangeFormatter {
  static format = (smallValue: number, bigValue: number): string =>
    smallValue === bigValue
      ? FormatUSD.format(smallValue)
      : `${FormatUSD.format(smallValue)} - ${FormatUSD.format(bigValue)}`;
}
