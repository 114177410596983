import React, { FC } from 'react';
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import Breakpoints from '../Breakpoints/Breakpoints';
import HelpOutlineIcon from '../Icons/HelpOutlineIcon';

interface DevelopmentInfoProps {
  isFreeShipping: boolean;
  sitePreview: boolean;
}

const DevelopmentInfo: FC<DevelopmentInfoProps> = ({
  isFreeShipping,
  sitePreview,
}) => {
  if (process.env.NODE_ENV === 'production') {
    return <></>;
  }
  return (
    <Tooltip
      placement="top-end"
      title={
        <Box
          style={{ position: 'fixed', bottom: '50%', right: '10px' }}
          bgcolor="white"
          color="black"
          borderRadius={1}
          boxShadow={3}
        >
          <TableContainer component={Paper}>
            <Table aria-label="environment variables">
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2}>
                    <Typography component="h6">
                      Developer and Environment Info
                      <br />
                      <caption>
                        Note this is only display in Development.
                      </caption>
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Variable</TableCell>
                  <TableCell>Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Node Environment</TableCell>
                  <TableCell>{process.env.NODE_ENV}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>REACT_APP_API_BASE_URL</TableCell>
                  <TableCell>{process.env.REACT_APP_API_BASE_URL}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>REACT_APP_SENTRY_DSN</TableCell>
                  <TableCell>{process.env.REACT_APP_SENTRY_DSN}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>REACT_APP_SENTRY_ENVIRONMENT_NAME</TableCell>
                  <TableCell>
                    {process.env.REACT_APP_SENTRY_ENVIRONMENT_NAME}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>REACT_APP_SENTRY_TRACE_RATE</TableCell>
                  <TableCell>
                    {process.env.REACT_APP_SENTRY_TRACE_RATE}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>REACT_APP_AUTH_DOMAIN</TableCell>
                  <TableCell>{process.env.REACT_APP_AUTH_DOMAIN}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>REACT_APP_AUTH_CLIENT_ID</TableCell>
                  <TableCell>{process.env.REACT_APP_AUTH_CLIENT_ID}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>REACT_APP_AUTH_REQUESTED_SCOPES</TableCell>
                  <TableCell>
                    {process.env.REACT_APP_AUTH_REQUESTED_SCOPES}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>BREAKPOINT</TableCell>
                  <TableCell>
                    <Breakpoints />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>REACT_APP_VERSION_CODE</TableCell>
                  <TableCell>{process.env.REACT_APP_VERSION_CODE}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>REACT_APP_BUILD_ID</TableCell>
                  <TableCell>{process.env.REACT_APP_BUILD_ID}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Free Shipping:</TableCell>
                  <TableCell>{isFreeShipping ? 'Yes' : 'No'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Site Preview:</TableCell>
                  <TableCell>{sitePreview ? 'Enabled' : 'Disabled'}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      }
    >
      <IconButton color="primary" className="justify-content-end">
        <HelpOutlineIcon />
      </IconButton>
    </Tooltip>
  );
};

export default DevelopmentInfo;
