import React, { useContext, useState, FocusEvent } from 'react';
import { Link } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { useForm } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import Layout from '../../components/Layout';
import {
  phoneNumberRegex,
  emailRegex,
  emojiCheck,
} from '../../utilities/validation';
import RetailerInformationContext from '../../context/RetailerInformationContext';
import {
  postCustomerInformation,
  CustomerInformationRequestType,
} from '../../api/ShoppingApi/clients/CustomerRegistrationClient';
import LoadingSpinner from '../../components/LoadingSpinner';

const CustomerRegistration = (): JSX.Element => {
  const retailerInformation = useContext(RetailerInformationContext);
  const [customerRegisterStatus, setCustomerRegisterStatus] =
    useState<string>('');

  const storeId = retailerInformation?.store.id;
  const tenantId = retailerInformation?.store.tenant.id;

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm<CustomerInformationRequestType>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const onSubmit = async (
    data: CustomerInformationRequestType
  ): Promise<void> => {
    setCustomerRegisterStatus('loading');
    const { phoneNumber, ...rest } = data;

    const formattedData = {
      phoneNumber: phoneNumber ? phoneNumber.replace(/\D+/g, '') : null,
      ...rest,
    };

    if (tenantId && storeId) {
      await postCustomerInformation(tenantId, storeId, formattedData)
        .then((response) => {
          if (response?.status) {
            setCustomerRegisterStatus('error');
          } else {
            setCustomerRegisterStatus('registered');
          }
        })
        .catch((error) => {
          Sentry.withScope((scope) => {
            scope.setLevel('error');
            Sentry.captureMessage(
              `Error: Registering Customer ${JSON.stringify(error)}`
            );
          });
          setCustomerRegisterStatus('error');
        });
    }
  };

  if (customerRegisterStatus === 'registered') {
    return (
      <Layout>
        <h2 className="mb-5">Thank You!</h2>
        <p>Thank you for registering.</p>
        <Button component={Link} to="/shop" variant="contained" color="primary">
          Continue Shopping
        </Button>
      </Layout>
    );
  }

  return (
    <Grid item xs={12}>
      <h2 className="mb-5">Register</h2>
      <p>
        Please enter your contact information if you are not already registered.
        This will make check out quicker.
      </p>
      <Grid item xs={12} sm={9} md={6}>
        {customerRegisterStatus === 'error' && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Alert severity="error">
                There was an issue with registration. Please check your form
                information below.
              </Alert>
              <br />
            </Grid>
          </Grid>
        )}
        {customerRegisterStatus === 'loading' ? (
          <LoadingSpinner />
        ) : (
          <form
            data-testid="customer-registration-form"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  id="customer_registration_firstName"
                  label="First Name"
                  variant="outlined"
                  autoComplete="billing given-name"
                  type="text"
                  fullWidth
                  inputProps={{
                    maxLength: 255,
                  }}
                  {...register('firstName', {
                    required: 'First Name is required',
                    validate: {
                      noEmoji: (value: string) =>
                        !emojiCheck(value) || 'Emojis are invalid',
                      noWhiteSpacesOnly: (value: string) =>
                        !!value.trim() || 'First Name is required',
                    },
                    onBlur: (event: FocusEvent<HTMLInputElement>) =>
                      setValue('firstName', event.target.value.trim()),
                  })}
                  helperText={errors.firstName?.message}
                  error={!!errors.firstName}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="customer_registration_lastName"
                  label="Last Name"
                  variant="outlined"
                  autoComplete="billing family-name"
                  type="text"
                  fullWidth
                  inputProps={{
                    maxLength: 255,
                  }}
                  {...register('lastName', {
                    required: 'Last Name is required',
                    validate: {
                      noEmoji: (value: string) =>
                        !emojiCheck(value) || 'Emojis are invalid',
                      noWhiteSpacesOnly: (value: string) =>
                        !!value.trim() || 'Last Name is required',
                    },
                    onBlur: (event: FocusEvent<HTMLInputElement>) =>
                      setValue('lastName', event.target.value.trim()),
                  })}
                  helperText={errors.lastName?.message}
                  error={!!errors.lastName}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="customer_registration_Email"
                  label="Email address"
                  variant="outlined"
                  autoComplete="email"
                  type="text"
                  fullWidth
                  inputProps={{
                    'data-testid': 'shipping-email',
                    maxLength: 255,
                  }}
                  {...register('emailAddress', {
                    required: 'Email is required',
                    pattern: {
                      value: emailRegex,
                      message: 'Email address is invalid',
                    },
                    onBlur: (event: FocusEvent<HTMLInputElement>) =>
                      setValue('emailAddress', event.target.value.trim()),
                  })}
                  helperText={errors.emailAddress?.message}
                  error={!!errors.emailAddress}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="customer_registration_Phone"
                  label="Phone"
                  variant="outlined"
                  autoComplete="tel-national"
                  type="text"
                  fullWidth
                  inputProps={{
                    'data-testid': 'shipping-phone',
                    maxLength: 255,
                  }}
                  {...register('phoneNumber', {
                    required: 'Phone number is required',
                    pattern: {
                      message: 'Phone number is invalid',
                      value: phoneNumberRegex,
                    },
                    onBlur: (event: FocusEvent<HTMLInputElement>) =>
                      setValue('phoneNumber', event.target.value.trim()),
                  })}
                  helperText={errors.phoneNumber?.message}
                  error={!!errors.phoneNumber}
                />
              </Grid>
            </Grid>
            <div className="clearfix">
              <Button
                color="primary"
                variant="contained"
                className="float-end my-4"
                type="submit"
              >
                Register
              </Button>
            </div>
          </form>
        )}
      </Grid>
    </Grid>
  );
};

export default CustomerRegistration;
