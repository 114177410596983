import React from 'react';
import SvgIcon from './SvgIcon';

type Props = {
  id?: string;
  className?: string;
  isActive?: boolean;
};

const IconClose = ({
  className,
  id,
  isActive,
  ...other
}: Props): JSX.Element => (
  <SvgIcon
    id={id}
    className={className}
    title="Close"
    isActive={isActive}
    viewBox="0 0 24 24"
    {...other}
  >
    <path
      d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41
        17.59 19 19 17.59 13.41 12z"
    />
  </SvgIcon>
);

export default IconClose;
