import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import RetailerInformationContext from '../../context/RetailerInformationContext';
import { getYoutubeVideoId } from '../../utilities/validation';
import ImageCover from '../../components/ImageCover';
import LoadingSpinner from '../../components/LoadingSpinner';
import APIError from '../../components/APIError';
import Styles from './MyStory.module.scss';

const MyStory = (): JSX.Element => {
  const retailerInformation = useContext(RetailerInformationContext);

  let pageContent: JSX.Element;

  if (!retailerInformation) {
    pageContent = <LoadingSpinner />;
  } else if (!retailerInformation.store) {
    pageContent = <APIError />;
  } else {
    const { byDesignRepDID, myStory } = retailerInformation.store;
    const { image, story, youtubeVideoUrl, pageName } = myStory;

    pageContent = (
      <>
        {image && (
          <div className="col-md-4">
            <ImageCover
              className={Styles.avatar}
              src={image.url}
              alt="Retailer Avatar"
            />
          </div>
        )}
        <div className={image ? 'col-md-8' : 'col-md-12'}>
          <h4 className="d-none d-md-block">My Story</h4>
          {story && (
            <div style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
              {story.split('\n').map((paragraph, index) => (
                <p key={`${index}-${paragraph.length}`}>{paragraph}</p>
              ))}
            </div>
          )}
          {youtubeVideoUrl && (
            <div className={Styles.videoContainer}>
              <iframe
                title={`${pageName || ''} Video`}
                src={`https://www.youtube.com/embed/${getYoutubeVideoId(
                  youtubeVideoUrl
                )}`}
              />
            </div>
          )}
          {byDesignRepDID && (
            <div className="col-sm-4 mt-3">
              <Button
                color="primary"
                variant="contained"
                fullWidth
                target="_blank"
                href={`https://paparazziaccessories.com/join/${byDesignRepDID}/#/`}
              >
                Join My Team
              </Button>
            </div>
          )}
        </div>
      </>
    );
  }

  return (
    <div className="col-12">
      <div className="row mb-3">{pageContent}</div>
    </div>
  );
};

export default MyStory;
