import React from 'react';
import clsx from 'clsx';
import { formatCurrencyDropZerosDecimal } from '../../utilities/Formatter';
import Styles from './Banner.module.scss';

const Banner = ({
  className,
  minimumOrderTotalToEarnFreeShipping,
}: {
  className?: string;
  minimumOrderTotalToEarnFreeShipping: number;
}): JSX.Element => {
  return (
    <section className={clsx(Styles.background, 'text-center', className)}>
      <span className={Styles.boldUppercase}>Free Shipping</span> on all orders{' '}
      <span className={Styles.bold}>
        {formatCurrencyDropZerosDecimal(minimumOrderTotalToEarnFreeShipping)}
      </span>{' '}
      or more
    </section>
  );
};

export default Banner;
