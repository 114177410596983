import { createContext } from 'react';
import * as Sentry from '@sentry/react';
import {
  updateProductCartItem,
  removeProductFromCart,
  claimCartToken,
} from '../api/ShoppingApi/clients/CartClient';
import CartValidationType from '../types/CartValidationType';
import RetailerInformation from '../types/RetailerInformation';
import CartItemType from '../types/CartItemType';
import { GATransactionEvent } from '../utilities/analytics';

interface CartContextType {
  cartIsLoading?: boolean;
  validationMessage: string;
  sessionId: string;
  items?: CartItemType[];
  expires?: Date;
  subtotal?: number;
  updateCartItem: (
    productId: number,
    quantity: number,
    sessionId: string,
    claimCode?: string,
    streamId?: string
  ) => void;
  removeFromCart: (
    productId: number,
    claimCode?: string,
    streamId?: string
  ) => void;
  claimCartItemsFromToken: (claimToken: string, sessionId: string) => void;
}

const CartContext = createContext<CartContextType | null>(null);

/**
 * Update a specific Cart item.
 *
 * @param cart - The current state of the Cart.
 * @param mutateCart - Use SWR mutate to pull cart validation endpoint.
 * @param retailerInformation - The RetailerInformation in context
 *
 * @returns a Function to update the cart item by productId and quantity.
 */
export const updateCartItem =
  (
    cart: CartValidationType | undefined,
    mutateCart: () => void,
    retailerInformation: RetailerInformation | undefined,
    mutateInventory: () => void
  ) =>
  (
    productId: number,
    quantity: number,
    sessionId: string,
    claimCode?: string,
    streamId?: string
  ): void => {
    if (cart && retailerInformation) {
      updateProductCartItem(
        retailerInformation.store.tenant.id,
        retailerInformation.store.id,
        {
          productId,
          quantity,
          sessionId,
          claimCode,
          streamId,
        }
      )
        .then(() => {
          GATransactionEvent('add_to_cart', {
            productId,
            quantity,
            claimCode,
            streamId,
          });
          mutateCart();
          mutateInventory();
        })
        .catch((error) => Sentry.captureException(error));
    }
  };

/**
 * Remove a specific item from the Cart.
 *
 * @param cart - The current state of the Cart.
 * @param mutateCart - Use SWR mutate to pull cart validation endpoint.
 * @param retailerInformation - The RetailerInformation in context
 *
 * @returns a Function that removes an item from the Cart by productId.
 */
export const removeFromCart =
  (
    cart: CartValidationType | undefined,
    mutateCart: () => void,
    retailerInformation: RetailerInformation | undefined,
    generateNewSessionId: () => void,
    mutateInventory: () => void
  ) =>
  (productId: number, claimCode?: string, streamId?: string): void => {
    if (cart && retailerInformation) {
      if (cart.cart.cartItems.length <= 1) generateNewSessionId();
      removeProductFromCart(
        retailerInformation.store.tenant.id,
        retailerInformation.store.id,
        {
          productId,
          sessionId: cart.cart.sessionId,
          claimCode,
          streamId,
        }
      )
        .then(() => {
          GATransactionEvent('remove_from_cart', {
            productId,
            claimCode,
            streamId,
          });
          mutateCart();
          mutateInventory();
        })
        .catch((error) => Sentry.captureException(error));
    }
  };

/**
 * Update a specific Cart item.
 *
 * @param cart - The current state of the Cart.
 * @param mutateCart - Use SWR mutate to pull cart validation endpoint.
 * @param retailerInformation - The RetailerInformation in context
 *
 * @returns a Function to update the cart item by productId and quantity.
 */
export const claimCartItemsFromToken =
  (
    cart: CartValidationType | undefined,
    mutateCart: () => void,
    retailerInformation: RetailerInformation | undefined,
    mutateInventory: () => void
  ) =>
  (sessionId: string, claimToken: string): void => {
    if (cart && retailerInformation) {
      claimCartToken(
        retailerInformation.store.tenant.id,
        retailerInformation.store.id,
        sessionId,
        {
          claimToken,
        }
      )
        .then(() => {
          mutateCart();
          mutateInventory();
        })
        .catch((error) => Sentry.captureException(error));
    }
  };

export default CartContext;
