import React from 'react';
import SvgIcon from './SvgIcon';

type Props = {
  id: string;
  className?: string;
  isActive?: boolean;
};

const YoutubeLogo = ({
  className,
  id,
  isActive,
  ...other
}: Props): JSX.Element => (
  <SvgIcon
    id={id}
    className={className}
    title="Youtube Logo"
    isActive={isActive}
    viewBox="0 0 100 100"
    {...other}
  >
    <path
      d="M93.12,28.338c-1.036-3.873-4.085-6.922-7.958-7.958C78.144,18.5,49.999,18.5,49.999,18.5
        s-28.142,0-35.162,1.881c-3.871,1.035-6.921,4.085-7.957,7.958C5,35.357,5,50.001,5,50.001s0,14.644,1.88,21.662
        c1.036,3.871,4.086,6.922,7.957,7.956C21.857,81.5,49.999,81.5,49.999,81.5s28.145,0,35.163-1.881
        c3.873-1.034,6.922-4.085,7.958-7.956C95,64.645,95,50.001,95,50.001S95,35.357,93.12,28.338z M40.999,63.501V36.5l23.383,13.501
        L40.999,63.501z"
    />
  </SvgIcon>
);

export default YoutubeLogo;
