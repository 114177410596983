import React, {
  useContext,
  useState,
  useEffect,
  ChangeEvent,
  FC,
  useMemo,
} from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { Breadcrumbs, Hidden, Typography, Grid, Box } from '@material-ui/core';
import Skeleton from 'react-loading-skeleton';
import RetailerInformationContext from '../../context/RetailerInformationContext';
import { useGetInventorySearch } from '../../api/ShoppingApi/clients/SWRCartClient';
import { InventoryRecord } from '../../api/responses/InventoryResponse';
import InventorySearchParams, {
  TagFiltersTypes,
} from '../../types/InventorySearchParams';
import { emojiCheck } from '../../utilities/validation';
import PaginationControls from '../../components/PaginationControls';
import FilterTags from '../../components/FilterTags';
import FilterList from '../../components/FilterList';
import IconSearch from '../../components/Icons/IconSearch';
import Styles from './ProductInventory.module.scss';
import ApplicationContext from '../../context/ApplicationContext';
import StoreProfile from '../../components/StoreProfile';
import InventoryItem from '../../components/InventoryItem';

const ProductInventoryCollections: FC = () => {
  const retailerInformation = useContext(RetailerInformationContext);
  const storeId = retailerInformation?.store.id;
  const tenantId = retailerInformation?.store.tenant.id;

  const { categorytype, category: codedCategory } = useParams();
  const category = codedCategory?.replace(/-/g, ' ');

  // Helper function to turn the tag filters into URL parameters.
  function buildURLTagFiltersParams(
    object: TagFiltersTypes
  ): Record<string, string> {
    const buildURLParamsObject: Record<string, string> = {};
    Object.entries(object).forEach(([key, value]) => {
      buildURLParamsObject[key] = value.join(',');
    });
    return buildURLParamsObject;
  }

  // Retrieve information about the store and collections that were requested in the app.
  const { tagCategories } = useContext(ApplicationContext);
  const categoryDict = useMemo(() => {
    const r: Record<string, { category: number; value: number }> = {};
    tagCategories?.forEach((c) => {
      c.tags.forEach((t) => {
        r[`${c.name}/${t.name}`] = {
          category: c.id,
          value: t.id,
        };
      });
    });
    return r;
  }, [tagCategories]);

  // Make information easier to use.
  const availableResultsPerPage = [18, 24, 36, 48];

  const [searchURLParams, setSearchURLParams] = useSearchParams();
  const { search, pageNumber, pageSize, featuredProductSearch, vip, ...rest } =
    Object.fromEntries([...Array.from(searchURLParams)]);

  // In the mobile fiew the filters are a popup instead of a side nav.
  const [displayMobileFilter, setDisplayMobileFilter] =
    useState<boolean>(false);

  // Page size, until we get infinite scroll.
  const [resultsPerPage, setResultsPerPage] = useState<number>(
    parseInt(pageSize, 10) || availableResultsPerPage[0]
  );

  // This extracts the search from the url information.  Its memoized so we are not
  // executing this function any more than necessary.  The function is not computationally
  // complex, so this is not really necessary.
  const searchParams = useMemo(() => {
    const queryTagFilters: TagFiltersTypes = {};

    // Retrieve the query tag filters from the URL
    Object.entries(rest).forEach(([key, value]) => {
      // helps to remove tracking appended query such as fbclid
      const parsedKey = parseInt(key, 10);
      if (!Number.isNaN(parsedKey)) {
        queryTagFilters[key] = value.split(',');
      }
    });

    // Add in the category to the query tag filters if it is defined
    if (
      categorytype &&
      categorytype.toLowerCase() !== 'collections' &&
      category
    ) {
      const key = `${categorytype}/${category}`;
      const v = categoryDict[key];
      if (v) {
        queryTagFilters[v.category] = [String(v.value)];
      }
    }

    let collectionName: string | undefined;
    let vipCode: string | undefined;
    if (category && categorytype?.toLowerCase() === 'collections') {
      collectionName = category;
      vipCode = vip;
    }

    return {
      searchTerm: search || null,
      tagFilters: queryTagFilters,
      pageNumber: parseInt(pageNumber, 10) || 1,
      resultsPerPage,
      featuredProductSearch: featuredProductSearch
        ? featuredProductSearch === 'true'
        : false,
      collectionName,
      vipCode,
    } as InventorySearchParams;
  }, [
    category,
    categorytype,
    rest,
    categoryDict,
    featuredProductSearch,
    pageNumber,
    resultsPerPage,
    search,
    vip,
  ]);

  // For simplicity the inventory search uses some ids that are not valid.
  // This removes those ids.  Its a bit hacky, but we are trying to isolate changes
  // to within the feature flag.
  const cleanedSearchParams: InventorySearchParams = {
    ...searchParams,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    tagFilters: { ...searchParams.tagFilters, '-1': undefined! },
  };

  // Grab the inventory from the server
  const { inventorySearch, isLoading: inventorySearchIsLoading } =
    useGetInventorySearch(tenantId, storeId, cleanedSearchParams);

  useEffect(() => {
    // If sharing a url with featuredProductSearch=true but displayFeatured is false change query param to featuredProductSearch=false
    if (
      inventorySearch?.displayFeatured !== undefined &&
      !inventorySearch?.displayFeatured
    ) {
      setSearchURLParams((oldValue) => {
        const oldFeatured = oldValue.get('featuredProductSearch');
        if (oldFeatured === 'true') {
          oldValue.delete('featuredProductSearch');
          oldValue.set('pageNumber', '1');
        }
        return oldValue;
      });
    }
  }, [inventorySearch?.displayFeatured, searchURLParams, setSearchURLParams]);

  // This removes the category that is navigated from the items in the filter view.
  // If you have selected a specific color in the navigation, then color shouldn't show up in the filters.
  const visibleCategories = [
    ...(tagCategories?.filter((n) => n.name !== categorytype) ?? []),
  ];

  // Form to track the search term.
  const {
    handleSubmit,
    register,
    trigger,
    formState: { errors },
    setValue,
  } = useForm<{ search: string }>({
    reValidateMode: 'onSubmit',
  });

  // Handles a change in the search term.
  const onSubmit = (form: { search: string }): void => {
    const searchValue = form.search.trim();
    setValue('search', searchValue.trim());
    trigger();
    if (searchValue.length > 1 || searchValue.length === 0) {
      if (displayMobileFilter) {
        setDisplayMobileFilter(!displayMobileFilter);
      }
      setSearchURLParams((previousParameters) => {
        const params = new URLSearchParams(previousParameters);
        if (searchValue) {
          params.set('search', searchValue);
        } else {
          params.delete('search');
        }

        params.set('pageNumber', '1');
        return params;
      });
    }
  };

  // Called from the mobile view.  That view has a reset filters button.
  const resetFilters = (): void => {
    setValue('search', '');
    setSearchURLParams((oldValue) => {
      const newValue = new URLSearchParams();
      const oldVip = oldValue.get('vip');
      if (oldVip) {
        newValue.set('vip', oldVip);
      }
      newValue.set('pageNumber', '1');
      newValue.set('pageSize', resultsPerPage.toString());
      return newValue;
    });
  };
  const handleCheckboxSelect = (
    event: ChangeEvent<HTMLInputElement>,
    filterProp: number
  ): void => {
    const { name } = event.target;
    let tagFiltersValue: TagFiltersTypes = {};

    if (searchParams.tagFilters[filterProp]) {
      tagFiltersValue = {
        ...searchParams.tagFilters,
        [filterProp]: searchParams.tagFilters[filterProp].includes(name)
          ? searchParams.tagFilters[filterProp].filter((e) => e !== name)
          : [...searchParams.tagFilters[filterProp], name],
      };
    } else {
      tagFiltersValue = {
        ...searchParams.tagFilters,
        [filterProp]: [name],
      };
    }

    // Remove key value pairs with empty arrays
    tagFiltersValue = JSON.parse(
      JSON.stringify(tagFiltersValue, (key, value) =>
        typeof value === 'object' && value.length < 1 ? undefined : value
      )
    );

    setSearchURLParams((oldValue) => {
      const newValue = new URLSearchParams(
        buildURLTagFiltersParams(tagFiltersValue)
      );
      newValue.set('pageNumber', '1');
      const oldPageSize = oldValue.get('pageSize') ?? resultsPerPage.toString();
      const oldSearch = oldValue.get('search');
      const oldFeatured = oldValue.get('featuredProductSearch');
      const oldVip = oldValue.get('vip');

      newValue.set('pageSize', oldPageSize);
      if (oldSearch) {
        newValue.set('search', oldSearch);
      }
      if (oldFeatured && oldFeatured !== 'false') {
        newValue.set('featuredProductSearch', oldFeatured);
      }
      if (oldVip) {
        newValue.set('vip', oldVip);
      }

      return newValue;
    });
  };
  // Paging functions
  const onResultsPerPage = (event: ChangeEvent<{ value: string }>): void => {
    setResultsPerPage(parseInt(event.target.value as string, 10));
    setSearchURLParams((oldValue) => {
      oldValue.set('pageSize', event.target.value);
      oldValue.set('pageNumber', '1');
      return oldValue;
    });
  };
  const handlePaginate = (paginateNumber: number): void => {
    setSearchURLParams((oldValue) => {
      oldValue.set('pageNumber', paginateNumber.toString());
      return oldValue;
    });
  };
  const handleFeatureCheckboxSelect = (): void => {
    setSearchURLParams((oldValue) => {
      oldValue.set('pageNumber', '1');
      oldValue.set(
        'featuredProductSearch',
        (
          featuredProductSearch === undefined ||
          featuredProductSearch === 'false'
        ).toString()
      );
      return oldValue;
    });
  };

  // Toggle the mobile filter panel.
  const mobileFilterToggle = (clear: boolean): void => {
    document.body.classList.add('overflow-hidden');

    if (clear) {
      resetFilters();
    } else {
      setDisplayMobileFilter(!displayMobileFilter);

      if (displayMobileFilter) {
        document.body.classList.remove('overflow-hidden');
      }
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12} md={2}>
            <Hidden smDown>
              <StoreProfile />
            </Hidden>
            <FilterTags
              searchParams={searchParams}
              tagFilters={visibleCategories}
              resetFilters={resetFilters}
            />
            <Button
              className="d-md-none mb-3"
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => mobileFilterToggle(false)}
            >
              Filter
            </Button>
            <Box
              className={clsx('d-lg-block', {
                'd-none': !displayMobileFilter,
              })}
            >
              <FilterList
                categories={visibleCategories}
                displayFeatured={inventorySearch?.displayFeatured}
                loading={inventorySearchIsLoading}
                onFeaturedChange={handleFeatureCheckboxSelect}
                featuredState={featuredProductSearch === 'true'}
                onChange={handleCheckboxSelect}
                tagFilters={searchParams.tagFilters}
                mobileFilterToggle={mobileFilterToggle}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={10}>
            <form
              className={Styles.searchForm}
              data-testid="product_search_form"
              onSubmit={handleSubmit(onSubmit)}
              noValidate
            >
              <TextField
                id="product_search_input"
                className="mb-xs-3 mb-md-0"
                label="Search"
                size="small"
                variant="outlined"
                type="text"
                autoComplete="off"
                fullWidth
                defaultValue={search}
                inputProps={{
                  maxLength: 255,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton type="submit" aria-label="search inventory">
                        <IconSearch id="inventory-search" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                {...register('search', {
                  pattern: {
                    message: 'Please fill out field',
                    value: /[^\s]/,
                  },
                  minLength: {
                    message: 'Minimum 2 characters required',
                    value: 2,
                  },
                  validate: {
                    noEmoji: (value) =>
                      !emojiCheck(value) || 'Emojis are invalid',
                  },
                })}
                helperText={errors.search?.message}
                error={!!errors.search}
              />
              <span>
                {inventorySearchIsLoading ? (
                  <Skeleton count={2} />
                ) : (
                  inventorySearch &&
                  `${inventorySearch.currentPage.totalRecords} ${
                    inventorySearch.currentPage.totalRecords === 1
                      ? 'Result'
                      : 'Results'
                  }`
                )}
              </span>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'right',
                }}
              >
                <InputLabel
                  id="resultsPerPage"
                  className="m-0 pe-3 d-inline-block"
                >
                  Results Per Page
                </InputLabel>
                <TextField
                  size="small"
                  id="resultsPerPage"
                  select
                  value={resultsPerPage}
                  onChange={onResultsPerPage}
                  variant="outlined"
                  className="d-inline-block"
                >
                  {availableResultsPerPage.map((item, index) => (
                    <MenuItem key={`${item}-${index}`} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            </form>

            <Grid item xs={12} className="my-3">
              <Breadcrumbs separator=">">
                <Link
                  color="inherit"
                  className="text-decoration-underline"
                  to="/shop"
                  aria-current={!category ? 'true' : 'false'}
                >
                  Shop
                </Link>
                {category && (
                  <Typography aria-current="page">{category}</Typography>
                )}
              </Breadcrumbs>
            </Grid>
            <Grid item xs={12} className="my-3">
              <Typography variant="h4" component="h1">
                {category || 'Shop All'}
              </Typography>
            </Grid>
            {inventorySearchIsLoading ? (
              <Grid item xs={12} className="mx-1 my-3">
                <div className={`${Styles.gutterLeft} row`}>
                  {[...Array(resultsPerPage)].map(
                    (value: undefined, index: number) => (
                      <div
                        key={`${value}-${index}`}
                        className="col-xl-2 col-lg-3 col-sm-4 col-6 ps-0 pb-4"
                        data-testid="loading-products-skeleton-collections"
                      >
                        <Skeleton
                          inline
                          className={Styles.productGroupSkeleton}
                        />
                        <Skeleton inline count={2} className="mt-2" />
                      </div>
                    )
                  )}
                </div>
              </Grid>
            ) : (
              <Grid item xs={12} className="mx-1 my-3">
                <div className={`${Styles.gutterLeft} row`}>
                  {inventorySearch?.currentPage.records.length === 0 && (
                    <p className="col-12 ps-0 text-center fw-bold">
                      We couldn&apos;t find any matching products. Please try
                      another search or change your filters.
                    </p>
                  )}
                  {inventorySearch?.currentPage?.records.map(
                    (inventoryItem: InventoryRecord, index: number) => (
                      <InventoryItem
                        key={`inventory-item-id-${index}`}
                        inventoryItem={inventoryItem}
                      />
                    )
                  )}
                </div>
              </Grid>
            )}
          </Grid>
          {inventorySearch && (
            <Grid container className="mb-3 justify-content-end">
              <Grid item xs={12} md={10}>
                <PaginationControls
                  currentPage={inventorySearch.currentPage.currentPage}
                  itemCount={inventorySearch.currentPage.totalRecords}
                  itemsPerPage={inventorySearch.currentPage.pageSize}
                  onChange={handlePaginate}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProductInventoryCollections;
