import React from 'react';
import Button from '@material-ui/core/Button';
import InventorySearchParams from '../../types/InventorySearchParams';
import TagCategoryType from '../../types/TagCategoryType';

interface Props {
  searchParams: InventorySearchParams;
  tagFilters?: TagCategoryType[];
  resetFilters: () => void;
}

const FilterTags = ({
  searchParams,
  tagFilters,
  resetFilters,
}: Props): JSX.Element => {
  let filterListArray: (string | undefined)[] = [];

  if (tagFilters) {
    Object.entries(searchParams.tagFilters).forEach(([key, values]) => {
      filterListArray = [
        ...filterListArray,
        ...values.map(
          (value) =>
            tagFilters
              .find((x) => x.id.toString() === key)
              ?.tags.find((y) => y.id.toString() === value)?.name
        ),
      ];
    });
  }

  if (searchParams.featuredProductSearch) filterListArray.unshift('Featured');

  return filterListArray.length > 0 ? (
    <div className="row d-md-none mb-3 d-flex">
      <div className="col-8">
        <span className="fw-bold">Filters:</span> {filterListArray.join(', ')}
      </div>
      <div className="col-4 text-end">
        <Button
          className="p-0 lh-1 text-capitalize"
          variant="text"
          color="primary"
          onClick={resetFilters}
        >
          Clear All
        </Button>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default FilterTags;
