import React, { useEffect, useContext, useState, ChangeEvent } from 'react';
import { useForm } from 'react-hook-form';
// eslint-disable-next-line import/no-named-default
import { default as MUILink } from '@material-ui/core/Link';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import RetailerInformationContext from '../../context/RetailerInformationContext';
import {
  emailRegex,
  phoneNumberRegex,
  usaZipRegex,
  emojiCheck,
} from '../../utilities/validation';
import { SupportedCountries } from '../../utilities/Countries';
import { SupportedStatesTerritoriesArmedForces } from '../../utilities/States';
import CustomerFormInformation, {
  CustomerShippingForm,
  CustomerInformationFormType,
} from '../../types/CustomerInformation';
import DeliveryMethod from '../../types/DeliveryMethod';
import Styles from './CheckoutInfo.module.scss';

interface ModalProps {
  body?: string;
  heading?: string;
  handleClose: () => void;
  open: boolean;
}

const CenteredModalDefaultProps = {
  body: '',
  heading: '',
};

const CenteredModal = ({
  body,
  heading,
  handleClose,
  open,
}: ModalProps): JSX.Element => (
  <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">{heading}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {body}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color="primary" autoFocus>
        Close
      </Button>
    </DialogActions>
  </Dialog>
);

CenteredModal.defaultProps = CenteredModalDefaultProps;

interface Props {
  customerInfo?: CustomerFormInformation;
  onSubmit: (customerInfo: CustomerShippingForm) => void;
  handleShippingMethod: (event: ChangeEvent<HTMLInputElement>) => void;
  shippingMethod: DeliveryMethod;
  allowInStorePickup: boolean;
  returnPolicy: string;
  shippingPolicy: string;
  termsOfUse: string;
  privacyPolicy: string;
  AVSerrors?: boolean | string[];
}

const CheckoutShippingForm = ({
  customerInfo,
  onSubmit,
  handleShippingMethod,
  shippingMethod,
  allowInStorePickup,
  returnPolicy,
  shippingPolicy,
  termsOfUse,
  privacyPolicy,
  AVSerrors,
}: Props): JSX.Element => {
  const retailerInformation = useContext(RetailerInformationContext);
  const { store } = retailerInformation || {};

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    setError,
  } = useForm<CustomerInformationFormType>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    if (Array.isArray(AVSerrors)) {
      AVSerrors.forEach((entry: string) => {
        switch (entry) {
          case 'address':
            setError('line1', {
              type: 'avs failure',
              message: 'Could not verify street number',
            });
            break;
          case 'city':
            setError('city', {
              type: 'cvv failure',
              message: 'Could not verify city',
            });
            break;
          case 'state':
            setError('state', {
              type: 'cvv failure',
              message: 'Could not verify state',
            });
            break;
          case 'zipcode':
            setError('zip', {
              type: 'cvv failure',
              message: 'ZIP Code does not match the credit card',
            });
            break;
          default:
        }
      });
    }
  }, [AVSerrors, setError]);

  const [modalState, setModalState] = useState<{
    open: boolean;
    content: 'shipping-policy' | 'return-policy';
  }>({
    open: false,
    content: 'return-policy',
  });

  const handleShowShippingPolicy = (): void =>
    setModalState({ open: true, content: 'shipping-policy' });
  const handleShowReturnPolicy = (): void =>
    setModalState({ open: true, content: 'return-policy' });
  const handleClose = (): void =>
    setModalState((state) => ({ ...state, open: false }));

  let modalHeading: string | undefined;
  let modalBody: string | undefined;

  switch (modalState.content) {
    case 'return-policy':
      modalBody = returnPolicy;
      modalHeading = 'Return Policy';
      break;
    case 'shipping-policy':
      modalBody = shippingPolicy;
      modalHeading = 'Shipping Policy';
      break;
    default:
  }

  return (
    <>
      <form
        data-testid="shipping-form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <h5 className="my-3">Contact Information</h5>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              id="ContactInfo_Email"
              label="Email address"
              variant="outlined"
              autoComplete="email"
              type="email"
              fullWidth
              inputProps={{
                'data-testid': 'shipping-email',
                maxLength: 255,
              }}
              defaultValue={customerInfo?.customerEmailAddress}
              {...register('customerEmailAddress', {
                required: 'Email is required',
                pattern: {
                  value: emailRegex,
                  message: 'Email address is invalid',
                },
                onBlur: (e) =>
                  setValue('customerEmailAddress', e.target.value.trim()),
              })}
              helperText={errors.customerEmailAddress?.message}
              error={!!errors.customerEmailAddress}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="ContactInfo_Phone"
              label="Phone (Optional)"
              variant="outlined"
              autoComplete="tel-national"
              type="tel"
              fullWidth
              inputProps={{
                'data-testid': 'shipping-phone',
                maxLength: 255,
              }}
              defaultValue={customerInfo?.customerPhoneNumber}
              {...register('customerPhoneNumber', {
                pattern: {
                  message: 'Phone number is invalid',
                  value: phoneNumberRegex,
                },
                onBlur: (e) =>
                  setValue('customerPhoneNumber', e.target.value.trim()),
              })}
              helperText={errors.customerPhoneNumber?.message}
              error={!!errors.customerPhoneNumber}
            />
          </Grid>
          {allowInStorePickup && (
            <Grid item xs={12}>
              <FormControl>
                <RadioGroup
                  row
                  name="shipping-method-radio-group"
                  value={shippingMethod}
                  onChange={handleShippingMethod}
                >
                  <FormControlLabel
                    value="Shipping"
                    control={<Radio />}
                    label="Ship Order"
                  />
                  <FormControlLabel
                    value="Pickup"
                    control={<Radio />}
                    label="Pick Up"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          )}
          {shippingMethod === 'Shipping' ? (
            <>
              <Grid item xs={12}>
                <h5>Shipping Address</h5>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="ContactInfo_firstName"
                  label="First Name"
                  variant="outlined"
                  autoComplete="shipping given-name"
                  type="text"
                  fullWidth
                  inputProps={{
                    'data-testid': 'shipping-firstName',
                    maxLength: 255,
                  }}
                  defaultValue={customerInfo?.shippingAddress?.firstName}
                  {...register('firstName', {
                    required: 'First Name is required',
                    validate: {
                      noEmoji: (value) =>
                        !emojiCheck(value) || 'Emojis are invalid',
                      noWhiteSpacesOnly: (value) =>
                        !!value.trim() || 'First Name is required',
                    },
                    onBlur: (e) => setValue('firstName', e.target.value.trim()),
                  })}
                  helperText={errors.firstName?.message}
                  error={!!errors.firstName}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="ContactInfo_lastName"
                  label="Last Name"
                  variant="outlined"
                  autoComplete="shipping family-name"
                  type="text"
                  fullWidth
                  inputProps={{
                    'data-testid': 'shipping-lastName',
                    maxLength: 255,
                  }}
                  defaultValue={customerInfo?.shippingAddress?.lastName}
                  {...register('lastName', {
                    required: 'Last Name is required',
                    validate: {
                      noEmoji: (value) =>
                        !emojiCheck(value) || 'Emojis are invalid',
                      noWhiteSpacesOnly: (value) =>
                        !!value.trim() || 'Last Name is required',
                    },
                    onBlur: (e) => setValue('lastName', e.target.value.trim()),
                  })}
                  helperText={errors.lastName?.message}
                  error={!!errors.lastName}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="ContactInfo_line1"
                  label="Address"
                  variant="outlined"
                  autoComplete="shipping address-line1"
                  type="text"
                  fullWidth
                  inputProps={{
                    'data-testid': 'shipping-line1',
                    maxLength: 255,
                  }}
                  defaultValue={customerInfo?.shippingAddress?.line1}
                  {...register('line1', {
                    required: 'Address is required',
                    validate: {
                      noEmoji: (value) =>
                        !emojiCheck(value) || 'Emojis are invalid',
                      noWhiteSpacesOnly: (value) =>
                        !!value.trim() || 'Address is required',
                    },
                    onBlur: (e) => setValue('line1', e.target.value.trim()),
                  })}
                  helperText={errors.line1?.message}
                  error={!!errors.line1}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="ContactInfo_line2"
                  label="Apartment, Suite, etc (Optional)"
                  variant="outlined"
                  autoComplete="shipping address-line2"
                  type="text"
                  fullWidth
                  inputProps={{
                    'data-testid': 'shipping-line2',
                    maxLength: 255,
                  }}
                  defaultValue={customerInfo?.shippingAddress?.line2}
                  {...register('line2', {
                    validate: {
                      noEmoji: (value) =>
                        !emojiCheck(value || '') || 'Emojis are invalid',
                    },
                    onBlur: (e) => setValue('line2', e.target.value.trim()),
                  })}
                  helperText={errors.line2?.message}
                  error={!!errors.line2}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="ContactInfo_City"
                  label="City"
                  variant="outlined"
                  autoComplete="shipping locality"
                  type="text"
                  fullWidth
                  inputProps={{
                    'data-testid': 'shipping-city',
                    maxLength: 255,
                  }}
                  defaultValue={customerInfo?.shippingAddress?.city}
                  {...register('city', {
                    required: 'City is required',
                    validate: {
                      noEmoji: (value) =>
                        !emojiCheck(value) || 'Emojis are invalid',
                      noWhiteSpacesOnly: (value) =>
                        !!value.trim() || 'City is required',
                    },
                    onBlur: (e) => setValue('city', e.target.value.trim()),
                  })}
                  helperText={errors.city?.message}
                  error={!!errors.city}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="ContactInfo_Country"
                  label="Country"
                  variant="outlined"
                  autoComplete="shipping country"
                  select
                  fullWidth
                  SelectProps={{ native: true }}
                  inputProps={{
                    'data-testid': 'shipping-country',
                  }}
                  defaultValue={customerInfo?.shippingAddress?.country || 'USA'}
                  {...register('country', {
                    required: 'Country is required',
                  })}
                  helperText={errors.country?.message}
                  error={!!errors.country}
                >
                  {SupportedCountries.map((country) => (
                    <option key={country.isoCode} value={country.isoCode}>
                      {country.value}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="ContactInfo_State"
                  label="State"
                  variant="outlined"
                  autoComplete="shipping region"
                  select
                  fullWidth
                  SelectProps={{ native: true }}
                  inputProps={{
                    'data-testid': 'shipping-state',
                  }}
                  defaultValue={customerInfo?.shippingAddress?.state || ''}
                  {...register('state', {
                    required: 'State is required',
                  })}
                  helperText={errors.state?.message}
                  error={!!errors.state}
                >
                  <option value=""> </option>
                  {SupportedStatesTerritoriesArmedForces.map((state) => (
                    <option key={state.isoCode} value={state.isoCode}>
                      {state.value}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="ContactInfo_zip_code"
                  label="ZIP Code"
                  variant="outlined"
                  autoComplete="shipping postal-code"
                  type="text"
                  fullWidth
                  inputProps={{
                    'data-testid': 'shipping-zip',
                    maxLength: 255,
                  }}
                  defaultValue={customerInfo?.shippingAddress?.zip}
                  {...register('zip', {
                    required: 'ZIP Code is required',
                    pattern: {
                      message: 'ZIP Code is invalid',
                      value: usaZipRegex,
                    },
                    onBlur: (e) => setValue('zip', e.target.value.trim()),
                  })}
                  helperText={errors.zip?.message}
                  error={!!errors.zip}
                />
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <div className="pb-3">
                <h5>Pick up Address</h5>
                <div className={`${Styles.wordWrap} pb-3`}>
                  {store?.pickupPolicy.pickupAddress.line1}
                  {store?.pickupPolicy.pickupAddress.line2 &&
                    ` ${store?.pickupPolicy.pickupAddress.line2}`}
                  <br />
                  {store?.pickupPolicy.pickupAddress.city}{' '}
                  {store?.pickupPolicy.pickupAddress.state},{' '}
                  {store?.pickupPolicy.pickupAddress.zip},{' '}
                  {store?.pickupPolicy.pickupAddress.country}
                </div>
                <h5>Pick up Instructions</h5>
                <div className={Styles.wordWrap}>
                  {store?.pickupPolicy.instructions}
                </div>
              </div>
            </Grid>
          )}
        </Grid>
        <div className="clearfix">
          <Button
            data-testid="submit-shipping-form"
            color="primary"
            variant="contained"
            className="float-end my-4"
            type="submit"
          >
            Continue
          </Button>
        </div>
      </form>
      <p>
        <small>
          By clicking the Continue button, you confirm that you have read and
          understood this Consultant&rsquo;s{' '}
          <MUILink
            component="button"
            onClick={handleShowShippingPolicy}
            className={Styles.linkInlineButtons}
          >
            Shipping Policy
          </MUILink>{' '}
          and{' '}
          <MUILink
            component="button"
            onClick={handleShowReturnPolicy}
            className={Styles.linkInlineButtons}
          >
            Return Policy
          </MUILink>
          {(termsOfUse || privacyPolicy) && <>, and accept our </>}
          {termsOfUse && (
            <a href={termsOfUse} target="_blank" rel="noopener noreferrer">
              Terms of Use
            </a>
          )}
          {termsOfUse && privacyPolicy && <> and </>}
          {privacyPolicy && (
            <a href={privacyPolicy} target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a>
          )}
          .
        </small>
      </p>
      <CenteredModal
        open={modalState.open}
        body={modalBody}
        handleClose={handleClose}
        heading={modalHeading}
      />
    </>
  );
};

export default CheckoutShippingForm;
