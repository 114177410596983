import React, { ReactNode } from 'react';
import clsx from 'clsx';
import Styles from './SvgIcon.module.scss';

type Props = {
  id?: string;
  children: ReactNode;
  className?: string;
  description?: string;
  isActive?: boolean;
  style?: Record<string, unknown>;
  title: string;
  viewBox?: string;
};

const SvgIcon = ({
  id,
  children = '',
  className,
  description = '',
  isActive = false,
  style = undefined,
  title,
  viewBox = '0 0 24 24',
  ...props
}: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(className, Styles.svg_icon, {
      [Styles.svg_icon__active]: isActive,
    })}
    style={style}
    preserveAspectRatio="xMidYMid meet"
    viewBox={viewBox}
    aria-labelledby={`${id}TitleID ${description ? `${id}DescID` : ''}`}
    {...props}
  >
    <title id={`${id}TitleID`}>{title}</title>
    {description && <desc id={`${id}DescID`}>{description}</desc>}
    {children}
  </svg>
);

export default SvgIcon;
