import React, { useContext } from 'react';
import { formatCurrencyDropZerosDecimal } from '../../utilities/Formatter';
import CartContext from '../../context/CartContext';
import RetailerInformationContext from '../../context/RetailerInformationContext';
import Styles from './FreeShippingThreshold.module.scss';

function FreeShippingThreshold(): JSX.Element {
  const cart = useContext(CartContext);
  const retailerInfo = useContext(RetailerInformationContext);

  const moneyUntilFreeShipping =
    retailerInfo?.store.minimumOrderTotalToEarnFreeShipping &&
    cart?.subtotal &&
    retailerInfo.store.minimumOrderTotalToEarnFreeShipping - cart.subtotal;
  const hasFreeShippingRule = retailerInfo?.store.hasFreeShippingRule;

  if (
    hasFreeShippingRule &&
    moneyUntilFreeShipping &&
    moneyUntilFreeShipping > 0
  ) {
    return (
      <div className={Styles.freeShippingLeft}>
        Just{' '}
        <span className={Styles.bold}>
          {formatCurrencyDropZerosDecimal(moneyUntilFreeShipping)}
        </span>{' '}
        left to get <span className={Styles.boldUppercase}>free shipping</span>
      </div>
    );
  }

  if (hasFreeShippingRule && cart?.items?.length) {
    return (
      <div className={Styles.freeShippingLeft}>
        You have earned{' '}
        <span className={Styles.boldUppercase}>free shipping!</span>
      </div>
    );
  }

  return <></>;
}

export default FreeShippingThreshold;
