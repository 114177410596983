import React from 'react';
import {
  PayPalScriptProvider,
  ReactPayPalScriptOptions,
} from '@paypal/react-paypal-js';
import WebCheckoutCaptureResponsePayPalTypes from '../../../api/responses/WebCheckoutCaptureResponsePayPalTypes';
import WebCheckoutResponse from '../../../api/responses/WebCheckoutResponse';
import PayPal from './PayPal';

type PropTypes = {
  onPaymentSubmit: (
    paymentToken: string,
    paypalresponse?: WebCheckoutResponse & WebCheckoutCaptureResponsePayPalTypes
  ) => Promise<void>;
  cost: number;
  merchantId: string;
};

const PayPalWrapper = ({
  onPaymentSubmit,
  cost,
  merchantId,
}: PropTypes): JSX.Element => {
  const fundingOptions = cost < 30 ? 'card,paylater' : 'card';

  const initialOptions: ReactPayPalScriptOptions = {
    clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID as string,
    merchantId,
    dataPartnerAttributionId: process.env.REACT_APP_PAYPAL_BN_CODE,
    disableFunding: fundingOptions,
    components: 'messages,buttons',
  };

  return (
    <PayPalScriptProvider options={initialOptions}>
      <PayPal onPaymentSubmit={onPaymentSubmit} />
    </PayPalScriptProvider>
  );
};

export default PayPalWrapper;
