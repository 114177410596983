import { apiVersion, baseUrl } from '../apiConfig';
import WebCheckoutEstimateResponse from '../../responses/WebCheckoutEstimateResponse';
import CustomerFormInformation from '../../../types/CustomerInformation';

/**
 * Get Shipping and Tax estimate
 * @param sessionId
 * @param tenantId
 * @param storeId
 * @param request
 */
async function postWebCheckoutEstimate(
  sessionId: string,
  tenantId: number,
  storeId: number,
  request: CustomerFormInformation
): Promise<WebCheckoutEstimateResponse> {
  const url = `${baseUrl}/${apiVersion}/tenants/${tenantId}/stores/${storeId}/cartItems/${sessionId}/prepare-checkout`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(request),
  });

  if (response.ok) {
    return (await response.json()) as WebCheckoutEstimateResponse;
  }

  throw new Error('Unable to retrieve retailer store information.');
}

export default postWebCheckoutEstimate;
