import * as Sentry from '@sentry/react';
import { apiVersion, baseUrl } from '../apiConfig';
import WebCheckoutResponse from '../../responses/WebCheckoutResponse';
import WebCheckoutRequest from '../../requests/WebCheckoutRequest';

/**
 * Post payment
 * @param tenantId
 * @param storeId
 * @param request
 */
async function postWebCheckout(
  tenantId: number,
  storeId: number,
  request: WebCheckoutRequest
): Promise<WebCheckoutResponse> {
  const url = `${baseUrl}/${apiVersion}/tenants/${tenantId}/stores/${storeId}/orders/webcheckout`;
  const errorDisplayMessage = 'Unable to process transaction.';

  try {
    const response = await fetch(url, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request),
    });
    const body = (await response.json()) as WebCheckoutResponse;

    if (!response.ok) {
      const level = response.status >= 500 ? 'error' : 'warning';

      Sentry.withScope((scope) => {
        scope.setLevel(level);
        Sentry.captureMessage(
          `${level} - ${response.status}: webcheckout - ${response.statusText}`
        );
      });

      throw new Error(
        `Response status: ${response.status} ${response.statusText}`
      );
    }

    return body;
  } catch (e) {
    throw new Error(`Network error: ${errorDisplayMessage}`);
  }
}

export default postWebCheckout;
