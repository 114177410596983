import React from 'react';
import PhoneIcon from '../Icons/PhoneIcon';
import EmailIcon from '../Icons/EmailIcon';
import FacebookLogo from '../Icons/FacebookLogo';
import InstagramLogo from '../Icons/InstagramLogo';
import PinterestLogo from '../Icons/PinterestLogo';
import TwitterLogo from '../Icons/TwitterLogo';
import YoutubeLogo from '../Icons/YoutubeLogo';
import Styles from './Contact.module.scss';

interface Props {
  phoneNumber?: string;
  emailAddress?: string;
  facebookAccount?: string;
  instagramAccount?: string;
  pinterestAccount?: string;
  twitterAccount?: string;
  youtubeAccount?: string;
}

const defaultProps = {
  phoneNumber: null,
  emailAddress: null,
  facebookAccount: null,
  instagramAccount: null,
  pinterestAccount: null,
  twitterAccount: null,
  youtubeAccount: null,
};

const Contact = ({
  phoneNumber,
  emailAddress,
  facebookAccount,
  instagramAccount,
  pinterestAccount,
  twitterAccount,
  youtubeAccount,
}: Props): JSX.Element => (
  <>
    {phoneNumber && (
      <a className={Styles.contactLink} href={`tel:${phoneNumber}`}>
        <PhoneIcon id="footerContactPhone" className={Styles.socialLogo} />
      </a>
    )}
    {emailAddress && (
      <a className={Styles.contactLink} href={`mailto:${emailAddress}`}>
        <EmailIcon id="footerContactEmail" className={Styles.socialLogo} />
      </a>
    )}
    {facebookAccount && (
      <a
        className={Styles.contactLink}
        href={facebookAccount}
        target="_blank"
        rel="noopener noreferrer"
      >
        <FacebookLogo
          id="footerContactFacebook"
          className={Styles.socialLogo}
        />
      </a>
    )}
    {instagramAccount && (
      <a
        className={Styles.contactLink}
        href={instagramAccount}
        target="_blank"
        rel="noopener noreferrer"
      >
        <InstagramLogo
          id="footerContactInstagram"
          className={Styles.socialLogo}
        />
      </a>
    )}
    {pinterestAccount && (
      <a
        className={Styles.contactLink}
        href={pinterestAccount}
        target="_blank"
        rel="noopener noreferrer"
      >
        <PinterestLogo
          id="footerContactPinterest"
          className={Styles.socialLogo}
        />
      </a>
    )}
    {twitterAccount && (
      <a
        className={Styles.contactLink}
        href={twitterAccount}
        target="_blank"
        rel="noopener noreferrer"
      >
        <TwitterLogo id="footerContactTwitter" className={Styles.socialLogo} />
      </a>
    )}
    {youtubeAccount && (
      <a
        className={Styles.contactLink}
        href={youtubeAccount}
        target="_blank"
        rel="noopener noreferrer"
      >
        <YoutubeLogo id="footerContactYoutube" className={Styles.socialLogo} />
      </a>
    )}
  </>
);

Contact.defaultProps = defaultProps;

export default Contact;
