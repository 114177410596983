import React, { useContext, useEffect, useState } from 'react';
import CartContext from '../../context/CartContext';
import { useSession } from '../../context/SessionProvider';
import CustomerInformationContext from '../../context/CustomerInformationContext';
import OrderInformationContext from '../../context/OrderInformationContext';
import RetailerInformationContext from '../../context/RetailerInformationContext';
import { PaymentMethodContext } from '../../context/PaymentProviderContext';
import APIError from '../../components/APIError';
import CartItem from '../../components/CartItem';
import CheckoutPriceSummary from '../../components/CheckoutPriceSummary';
import LoadingSpinner from '../../components/LoadingSpinner';
import PayPalLogo from '../../images/PayPal_Logo_Horizontal_Full_Color_RGB.png';
import FormatUSD from '../../utilities/Formatter';
import CartItemType from '../../types/CartItemType';
import CustomerFormInformation from '../../types/CustomerInformation';
import Styles from './CheckoutConfirmation.module.scss';

const CheckoutConfirmation = (): JSX.Element => {
  const cart = useContext(CartContext);
  const { generateNewSessionId } = useSession();
  const customerInformation = useContext(CustomerInformationContext);
  const orderInformation = useContext(OrderInformationContext);
  const retailerInformation = useContext(RetailerInformationContext);
  const { paymentMethod } = useContext(PaymentMethodContext);

  const [purchasedItems, setPurchasedItems] = useState<CartItemType[]>([]);
  const [customerInfo, setCustomerInfo] = useState<CustomerFormInformation>();

  useEffect(() => {
    if (customerInformation?.customerInfo) {
      setCustomerInfo(customerInformation.customerInfo);
      customerInformation.updateCustomerInfo(undefined);
    }
  }, [customerInformation]);

  useEffect(() => {
    if (cart?.items && cart?.items.length > 0 && purchasedItems.length === 0) {
      setPurchasedItems(cart.items);
      generateNewSessionId();
    }
  }, [cart, purchasedItems, generateNewSessionId]);

  const checkoutCost = orderInformation?.checkoutInfo;
  const {
    customerEmailAddress,
    billingAddress,
    shippingAddress,
    deliveryMethod,
  } = customerInfo || {};
  const { store } = retailerInformation || {};

  return (
    <div className="col-12">
      {!cart && <LoadingSpinner data-testid="loading-spinner" />}
      {cart instanceof Error && <APIError />}
      {!(cart instanceof Error) && cart && (
        <>
          <div className="row">
            <div className="col-12">
              <h3 className="mb-5">Thank you for your order</h3>
            </div>
          </div>
          <div className="row justify-content-between">
            <div className="col-12 col-md-8 col-lg-6">
              <div className="mb-5">
                <p>
                  Your order number is <b>{checkoutCost?.orderId}</b>
                </p>
                <p>
                  A confirmation email has been sent to {customerEmailAddress}
                </p>
                {paymentMethod === 'PayPal' && checkoutCost?.transactionId && (
                  <p>PayPal Transaction ID: {checkoutCost.transactionId}</p>
                )}
              </div>
              {paymentMethod === 'Card' && (
                <div className="row">
                  <div className="col-lg-6 my-3">
                    <h6>Billing Address</h6>
                    <div className={Styles.wordWrap}>
                      <div>
                        {`${billingAddress?.firstName} ${billingAddress?.lastName}`}
                      </div>
                      <div>{billingAddress?.line1}</div>
                      <div>{billingAddress?.line2}</div>
                      <div>
                        {billingAddress?.city}
                        {', '}
                        {billingAddress?.state} {billingAddress?.zip}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 my-3">
                    <h6>Payment Method</h6>
                    <div>Card XXXX XXXX XXXX XXXX</div>
                    <div>
                      Paid{' '}
                      {checkoutCost &&
                        FormatUSD.format(checkoutCost.grandTotal)}
                    </div>
                  </div>
                </div>
              )}
              {paymentMethod === 'PayPal' && (
                <div className="row">
                  <div className="col-12 my-3">
                    <h6>Payment Method</h6>
                    <div>
                      <img
                        src={PayPalLogo}
                        className={Styles.paypalLogo}
                        alt="PayPal"
                      />
                    </div>
                    <div>
                      Paid{' '}
                      {checkoutCost &&
                        FormatUSD.format(checkoutCost.grandTotal)}
                    </div>
                  </div>
                </div>
              )}
              <div className="row">
                {deliveryMethod === 'Shipping' ? (
                  <>
                    <div className="col-lg-6 my-3">
                      <h6>Shipping Address</h6>
                      <div className={Styles.wordWrap}>
                        <div>
                          {`${shippingAddress?.firstName} ${shippingAddress?.lastName}`}
                        </div>
                        <div>{shippingAddress?.line1}</div>
                        <div>{shippingAddress?.line2}</div>
                        <div>
                          {shippingAddress?.city}
                          {', '}
                          {shippingAddress?.state} {shippingAddress?.zip}
                        </div>
                      </div>
                    </div>
                    <div className={`col-lg-6 my-3 ${Styles.wordWrap}`}>
                      <h6>Shipping Policy</h6>
                      {store?.shippingPolicy.policy}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-lg-6 my-3">
                      <h6>Pick Up Address</h6>
                      <div className={Styles.wordWrap}>
                        <div>{store?.pickupPolicy.pickupAddress.line1}</div>
                        {store?.pickupPolicy.pickupAddress.line2 && (
                          <div>{store?.pickupPolicy.pickupAddress.line2}</div>
                        )}
                        <div>
                          {store?.pickupPolicy.pickupAddress.city}{' '}
                          {store?.pickupPolicy.pickupAddress.state},{' '}
                          {store?.pickupPolicy.pickupAddress.zip}
                        </div>
                      </div>
                    </div>
                    <div className={`col-lg-6 my-3 ${Styles.wordWrap}`}>
                      <h6>Pick Up Instructions</h6>
                      {store?.pickupPolicy.instructions}
                    </div>
                  </>
                )}
                <div className={`col-12 my-3 ${Styles.wordWrap}`}>
                  <h6>Return Policy</h6>
                  <div className={Styles.wordWrap}>
                    {store?.returnPolicy.policy}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <ul className={Styles.checkout_cart_list}>
                {purchasedItems.map((cartItem) => (
                  <CartItem
                    key={`${cartItem.productId}${cartItem.claimCode || ''}`}
                    claimCode={cartItem.claimCode}
                    streamId={cartItem.streamId}
                    // discount={cartItem.discount}
                    item={cartItem}
                    productGroupId={cartItem.productGroupId}
                    quantity={cartItem.quantity}
                    readOnly
                  />
                ))}
              </ul>
              {purchasedItems && checkoutCost && store && (
                <CheckoutPriceSummary
                  subtotal={checkoutCost.subtotal}
                  shipping={checkoutCost.shippingAmount}
                  shippingTax={checkoutCost.shippingTax}
                  taxes={checkoutCost.salesTax}
                  total={checkoutCost.grandTotal}
                  topBorder
                  taxStrategy={store.taxStrategy}
                />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CheckoutConfirmation;
