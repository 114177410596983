import useSWR from 'swr';
import * as Sentry from '@sentry/react';
import { useCallback, useMemo } from 'react';
import { apiVersion, baseUrl } from '../apiConfig';
import FeatureFlags, { FeatureFlagEnum } from '../../../types/FeatureFlags';
import { AppContextType } from '../../../context/ApplicationContext';

async function getFeatureFlags(): Promise<FeatureFlags> {
  /*
   * awaiting backend to add the store information to the api call
   */
  const url = `${baseUrl}/${apiVersion}/features`;

  const response = await fetch(url, {
    headers: {
      Accept: 'application/json',
    },
  });

  if (response.ok) {
    if (response.status === 204) {
      Sentry.withScope((scope) => {
        scope.setLevel('warning');
        Sentry.captureMessage(
          `Warning - ${response.status}: getFeatureFlags - ${response.statusText}`
        );
      });

      throw new Error(`Status code: ${response.status}`);
    }

    return response.json() as Promise<FeatureFlags>;
  }

  Sentry.captureMessage(`Error - ${response.status}: getFeatureFlags`);

  throw new Error('Unable to retrieve feature flags.');
}

export function useFeatureFlags(): AppContextType {
  const { data } = useSWR<FeatureFlags>('/featureFlags', getFeatureFlags, {
    dedupingInterval: Infinity,
    revalidateOnFocus: false,
    revalidateIfStale: false,
  });

  // only recomputed when data changes
  const featureFlags = useMemo(() => data ?? ({} as FeatureFlags), [data]);

  const isFeatureFlagSet = useCallback(
    (flag: FeatureFlagEnum) => !!featureFlags[flag],
    [featureFlags]
  );

  return { featureFlags, isFeatureFlagSet };
}

export default useFeatureFlags;
