import { apiVersion, baseUrl } from '../apiConfig';

type StorePreviewRequest = {
  previewCode: string;
};

type StorePreviewResponse = {
  isValid: boolean;
};

/**
 * Post preview store
 * @param tenantId
 * @param storeId
 * @param request
 */
async function postStorePreview(
  tenantId: number,
  storeId: number,
  request: StorePreviewRequest
): Promise<StorePreviewResponse | undefined> {
  const response = await fetch(
    `${baseUrl}/${apiVersion}/tenants/${tenantId}/stores/${storeId}/validate-preview-code`,
    {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request),
    }
  );

  if (!response.ok) {
    throw new Error(
      `Response status: ${response.status} ${response.statusText}`
    );
  }

  return response.json() as Promise<StorePreviewResponse>;
}

export default postStorePreview;
