import React, { useEffect, useState } from 'react';
import LoadingSpinner from '../LoadingSpinner';
import VisaImg from '../../images/Visa-light.png';
import MastercardImg from '../../images/MasterCard-light.png';
import DiscoverImg from '../../images/Discover-light.png';
import AmExImg from '../../images/AmericanExpress-light.png';
import Styles from './PaymentIframe.module.scss';

interface Props {
  onPaymentFailure: (code: string, message: string) => void;
  onTokenReceived: (value: string) => void;
  payFormUrl: string;
  paymentError?: (string | null)[];
  paymentProcessing: boolean;
  storePreview: boolean;
  iframeKey: number;
}

interface SuccessfulPaymentTokenResponse {
  success: true;
  token: string;
}

interface FailedPaymentTokenResponse {
  code: string;
  reason: string;
  success: false;
}

type PaymentTokenResponse =
  | SuccessfulPaymentTokenResponse
  | FailedPaymentTokenResponse;

const PaymentIframe = ({
  payFormUrl,
  paymentError = [],
  paymentProcessing,
  storePreview,
  iframeKey,
  onTokenReceived,
  onPaymentFailure,
}: Props): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const handleIframeTask = (event: MessageEvent): void => {
      if (!event.isTrusted) return;

      const iframeOrigin = new URL(payFormUrl).origin;
      const messageOrigin = event.origin;

      if (messageOrigin !== iframeOrigin) return;

      const response: PaymentTokenResponse | false =
        typeof event.data === 'string' &&
        (JSON.parse(event.data) as PaymentTokenResponse);

      if (response) {
        if (response.success) {
          onTokenReceived(response.token);
        } else if (typeof response.success !== 'undefined') {
          onPaymentFailure(response.code, response.reason);
        }
      }
    };

    window.addEventListener('message', handleIframeTask, false);

    return () => {
      window.removeEventListener('message', handleIframeTask);
    };
  }, [onTokenReceived, onPaymentFailure, payFormUrl, iframeKey]);

  return storePreview ? (
    <div className={Styles.alertRed}>
      <div className="alert m-0 mb-3">
        <h4 className="my-3">Online Store - Coming Soon</h4>
        <p className="my-3">The billing form will be here.</p>
      </div>
    </div>
  ) : (
    <div>
      <div className={Styles.cards_header}>Credit Card Types Accepted</div>
      <div>
        <img src={VisaImg} className={Styles.cards_image} alt="Visa" />
        <img
          src={MastercardImg}
          className={Styles.cards_image}
          alt="Mastercard"
        />
        <img src={DiscoverImg} className={Styles.cards_image} alt="Discover" />
        <img
          src={AmExImg}
          className={Styles.cards_image}
          alt="American Express"
        />
      </div>
      {!paymentProcessing && paymentError && paymentError.length > 0 && (
        <div className="alert alert-danger m-0 mb-3">
          {paymentError.map((message) => (
            <div key={message}>{message}</div>
          ))}
        </div>
      )}
      <div className={Styles.wrapper}>
        {(loading || paymentProcessing) && (
          <div className={Styles.loading}>
            <LoadingSpinner data-testid="loading-spinner" />
          </div>
        )}
        {payFormUrl && !paymentProcessing && (
          <iframe
            key={iframeKey}
            title="Shopping Pay Form"
            data-testid="payment-iframe"
            referrerPolicy="origin"
            src={payFormUrl}
            className={Styles.pay_form_iframe}
            frameBorder="0"
            onLoad={() => setLoading(false)}
          />
        )}
      </div>
    </div>
  );
};

export default PaymentIframe;
