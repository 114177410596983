import React from 'react';
import SvgIcon from './SvgIcon';

type Props = {
  id: string;
  className?: string;
  isActive?: boolean;
};

const IconShoppingCart = ({
  className,
  id,
  isActive,
  ...other
}: Props): JSX.Element => (
  <SvgIcon
    id={id}
    className={className}
    title="Shopping Cart"
    isActive={isActive}
    {...other}
  >
    <path
      d="M23.121,6.769c-0.313-0.555-0.924-0.874-1.676-0.874H6.894c-0.55,0-0.995,0.445-0.995,0.996
        c0,0.55,0.445,0.996,0.995,0.996l14.244,0.008L17.26,14.32c-0.233,0.39-0.907,0.77-1.362,0.77H5.315
        c-0.25,0-0.464-0.212-0.464-0.464V6.454c0-1.354-0.938-2.456-2.09-2.456H1.666c-0.549,0-0.994,0.445-0.994,0.996
        c0,0.549,0.445,0.995,0.994,0.995l1.028-0.014C2.737,6.001,2.86,6.171,2.86,6.454v8.172c0,1.206,0.878,2.207,2.026,2.411
        c-0.035,0.164-0.056,0.335-0.056,0.509c0,1.354,1.103,2.456,2.456,2.456c1.353,0,2.455-1.102,2.455-2.456
        c0-0.159-0.019-0.313-0.048-0.465h2.729c-0.029,0.151-0.048,0.306-0.048,0.465c0,1.354,1.103,2.456,2.457,2.456
        c1.352,0,2.454-1.102,2.454-2.456c0-0.257-0.051-0.499-0.125-0.731c0.743-0.303,1.422-0.832,1.805-1.465l4.037-6.695
        C23.393,8.01,23.434,7.322,23.121,6.769z M7.751,17.546c0,0.257-0.208,0.464-0.464,0.464s-0.464-0.207-0.464-0.464
        s0.208-0.465,0.464-0.465S7.751,17.289,7.751,17.546z M14.832,18.01c-0.258,0-0.466-0.207-0.466-0.464s0.208-0.465,0.466-0.465
        c0.254,0,0.462,0.208,0.462,0.465S15.086,18.01,14.832,18.01z"
    />
  </SvgIcon>
);

export default IconShoppingCart;
