import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Avatar } from '@material-ui/core';
import IconDefaultStore from '../Icons/IconDefaultStore';
import RetailerInformationContext from '../../context/RetailerInformationContext';

export default function StoreProfile(): JSX.Element {
  const retailerInformation = useContext(RetailerInformationContext);
  // This looks for their dba name with a fallback to the storeName
  const storeName = retailerInformation?.store.tenant.name || undefined;
  const storeTitle = `${retailerInformation?.store.dba || storeName}`;

  // This looks for my story image, falls back to a bio image and lastly falls
  // back to a skeleton screen image.
  const myStoryImageUrl = retailerInformation?.store.myStory?.image?.url;
  const bioImageUrl = retailerInformation?.store.bioImage?.url;
  const storeImageUrl = myStoryImageUrl || bioImageUrl || undefined;

  // eslint-disable-next-line no-nested-ternary
  const dataTestId = myStoryImageUrl
    ? 'my-story-image'
    : bioImageUrl
    ? 'my-story-bio-image'
    : 'my-store-image-default';

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr',
        marginTop: '-.5rem',
        justifyItems: 'center',
        textAlign: 'center',
        alignItems: 'center',
        width: 'auto',
      }}
    >
      {storeImageUrl ? (
        <Avatar
          variant="circular"
          src={storeImageUrl}
          alt=""
          style={{ width: 120, height: 120 }}
          className="my-3 my-md-2"
          data-testid={dataTestId}
        />
      ) : (
        <IconDefaultStore />
      )}

      <Typography component="h5" variant="caption">
        <strong>{storeTitle}</strong>
      </Typography>
      <Typography component="h5" variant="caption">
        <Link
          to={'/about'}
          data-testid={'my-story-link'}
          style={{ textDecoration: 'underline' }}
        >
          My Story
        </Link>
      </Typography>
    </Box>
  );
}
