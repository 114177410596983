import React from 'react';
import Layout from '../../components/Layout/Layout';

interface Props {
  returnPolicy: string;
}

const ReturnPolicy = ({ returnPolicy }: Props): JSX.Element => (
  <Layout>
    <h1>Return Policy</h1>
    <p>{returnPolicy}</p>
  </Layout>
);

export default ReturnPolicy;
