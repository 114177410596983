import React from 'react';
import SvgIcon from './SvgIcon';

type Props = {
  id: string;
  className?: string;
  isActive?: boolean;
};

const PinterestLogo = ({
  className,
  id,
  isActive,
  ...other
}: Props): JSX.Element => (
  <SvgIcon
    id={id}
    className={className}
    title="Pinterest Logo"
    isActive={isActive}
    viewBox="0 0 100 100"
    {...other}
  >
    <path
      d="M50.036,5C25.204,5,5.073,25.132,5.073,49.963c0,19.059,11.842,35.342,28.569,41.893
        c-0.407-3.553-0.74-9.03,0.148-12.916c0.813-3.517,5.254-22.353,5.254-22.353s-1.332-2.701-1.332-6.661
        c0-6.254,3.627-10.917,8.142-10.917c3.849,0,5.699,2.886,5.699,6.328c0,3.849-2.442,9.622-3.737,14.988
        c-1.074,4.478,2.257,8.141,6.66,8.141c7.993,0,14.138-8.438,14.138-20.576c0-10.769-7.735-18.281-18.8-18.281
        c-12.805,0-20.317,9.584-20.317,19.502c0,3.849,1.48,7.994,3.331,10.251c0.371,0.444,0.407,0.851,0.296,1.295
        c-0.333,1.406-1.109,4.478-1.258,5.106c-0.186,0.814-0.666,1-1.519,0.592c-5.624-2.627-9.139-10.806-9.139-17.43
        c0-14.174,10.287-27.2,29.716-27.2c15.579,0,27.717,11.102,27.717,25.979c0,15.506-9.769,27.977-23.313,27.977
        c-4.552,0-8.845-2.368-10.288-5.181c0,0-2.257,8.585-2.813,10.694c-0.998,3.924-3.736,8.809-5.587,11.806
        C40.858,94.297,45.299,95,49.962,95c24.832,0,44.964-20.132,44.964-44.963C94.999,25.132,74.868,5,50.036,5z"
    />
  </SvgIcon>
);

export default PinterestLogo;
