import React from 'react';
import SvgIcon from './SvgIcon';

type Props = {
  id: string;
  className?: string;
  isActive?: boolean;
};

const EmailIcon = ({
  className,
  id,
  isActive,
  ...other
}: Props): JSX.Element => (
  <SvgIcon
    id={id}
    className={className}
    title="Email"
    isActive={isActive}
    viewBox="0 0 100 100"
    {...other}
  >
    <path
      d="M86.664,16.156c2.354,0,4.345,0.822,5.993,2.468c1.648,1.646,2.468,3.642,2.468,5.993
        v50.766c0,2.354-0.819,4.345-2.468,5.993s-3.64,2.468-5.993,2.468H13.336c-2.354,0-4.345-0.819-5.993-2.468
        s-2.468-3.64-2.468-5.993V24.617c0-2.351,0.819-4.348,2.468-5.993c1.648-1.646,3.64-2.468,5.993-2.468H86.664z M86.664,24.617
        H13.336v7.227c4.116,3.29,12.048,9.519,23.797,18.685l1.586,1.41c2.354,2,4.23,3.411,5.641,4.23
        c2.229,1.525,4.116,2.292,5.641,2.292s3.411-0.767,5.641-2.292c1.41-0.82,3.287-2.23,5.641-4.23l1.586-1.41
        c11.749-9.049,19.681-15.276,23.797-18.685V24.617z M13.336,75.383h73.328V42.597L66.217,58.813
        c-2.935,2.468-5.35,4.23-7.227,5.288c-3.174,1.877-6.17,2.82-8.99,2.82s-5.879-0.943-9.166-2.82
        c-1.763-1.058-4.169-2.82-7.227-5.288L13.336,42.597V75.383z"
    />
  </SvgIcon>
);

export default EmailIcon;
