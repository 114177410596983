import { useMemo } from 'react';
import TransitionalType from './TransitionalType';
import useSelectorsForProduct from './useSelectorsForProduct';
import PlaceholderImage from '../../images/inventory_image_1024x1024.png';
import InventoryItemSummaryResponse from '../../api/responses/InventoryItemSummaryResponse';

type ProductGroupImage = {
  /** A full image, for display in expanded views */
  original: string;
  /** A sized-down image for showing in gallery lists */
  thumbnail: string;
};

/**
 * Information about a product group.
 */
type ProductGroupDetails = {
  /** The display name of the product group. */
  productGroupName: string;
  /** The minimum price of the filtered products in the group. */
  minPrice: number;
  /** The maximum price of the filtered products in the group. */
  maxPrice: number;
  /** The description of the first matching product in the group. */
  productGroupDescription: string;
  /** The images of the first matching product within the group. */
  images: ProductGroupImage[];
};

const placeholderImage: ProductGroupImage = {
  original: PlaceholderImage,
  thumbnail: PlaceholderImage,
};

export const useProductGroupDetails = (
  productGroupId: number,
  selectedTags: number[]
): TransitionalType<ProductGroupDetails> => {
  const selectors = useSelectorsForProduct(productGroupId);

  const filtered = useMemo<InventoryItemSummaryResponse[]>(() => {
    return (
      selectors.inventoryItems?.filter(
        (item) =>
          !selectedTags.some((tagId) => !item.product.tagIds.includes(tagId))
      ) || []
    );
  }, [selectedTags, selectors.inventoryItems]);

  // Today we are gathering this by just looking at the first product in the
  // group that matches the selected tags. Eventually, we might have a
  // description at the product group level, as well as supplemental
  // descriptions for the tags selected beneath that group.
  const productGroupDescription = useMemo(() => {
    return (
      filtered.find((item) => item.product?.description)?.product
        ?.description || ''
    );
  }, [filtered]);

  const minPrice = useMemo(() => {
    return filtered.length
      ? filtered
          .map((item) => item.salePrice)
          .reduce((prev, curr) => (curr < prev ? curr : prev))
      : 0;
  }, [filtered]);

  const maxPrice = useMemo(() => {
    return filtered.length
      ? filtered
          .map((item) => item.salePrice)
          .reduce((prev, curr) => (curr > prev ? curr : prev))
      : 0;
  }, [filtered]);

  const images = useMemo(() => {
    const firstWithImages = filtered.find((item) => item.product.images.length);

    if (firstWithImages) {
      return firstWithImages.product.images.map((image) => ({
        original: image.imageUrl,
        thumbnail: image.imageUrl,
      }));
    }
    return [placeholderImage];
  }, [filtered]);

  return useMemo(() => {
    if (selectors.isError || selectors.isLoading) {
      return selectors;
    }

    return {
      isLoading: false,
      isError: false,
      productGroupDescription,
      images,
      productGroupName: selectors.productGroup.name,
      minPrice,
      maxPrice,
    };
  }, [images, maxPrice, minPrice, productGroupDescription, selectors]);
};

export default useProductGroupDetails;
