import React, {
  createContext,
  useState,
  Dispatch,
  SetStateAction,
  ReactNode,
} from 'react';

export type PaymentMethodsType = 'Card' | 'PayPal';
type PaymentMethodsContextType = {
  paymentMethod: PaymentMethodsType;
  setPaymentMethod: Dispatch<SetStateAction<PaymentMethodsType>>;
};
type PropType = { value?: PaymentMethodsType; children: ReactNode };

export const PaymentMethodContext = createContext<PaymentMethodsContextType>({
  paymentMethod: 'Card',
  setPaymentMethod: () => undefined,
});

const PaymentMethodProvider = ({
  value = 'Card',
  children,
}: PropType): JSX.Element => {
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodsType>(value);

  return (
    <PaymentMethodContext.Provider value={{ paymentMethod, setPaymentMethod }}>
      {children}
    </PaymentMethodContext.Provider>
  );
};

export default PaymentMethodProvider;
