import * as Sentry from '@sentry/react';
import { apiVersion, baseUrl } from '../apiConfig';
import WebCheckoutResponsePayPal from '../../responses/WebCheckoutResponsePayPal';
import WebCheckoutRequestPayPal from '../../requests/WebCheckoutRequestPayPal';

/**
 * Post payment
 * @param tenantId
 * @param storeId
 * @param request
 */
async function postWebCheckoutCreatePayPal(
  tenantId: number,
  storeId: number,
  request: WebCheckoutRequestPayPal
): Promise<WebCheckoutResponsePayPal> {
  const url = `${baseUrl}/${apiVersion}/tenants/${tenantId}/stores/${storeId}/orders/webcheckout/create-paypal-order`;
  const errorDisplayMessage = 'Unable to process transaction.';

  const temp = {
    ...request,
    shippingAddress: {
      ...request.shippingAddress,
      country: 'US',
    },
  };

  try {
    const response = await fetch(url, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(temp),
    });
    const body = (await response.json()) as WebCheckoutResponsePayPal;

    if (!response.ok) {
      const level = response.status >= 500 ? 'error' : 'warning';

      Sentry.withScope((scope) => {
        scope.setLevel(level);
        Sentry.captureMessage(
          `${level} - ${response.status}: webcheckout - ${response.statusText}`
        );
      });

      throw new Error(
        `Response status: ${response.status} ${response.statusText}`
      );
    }

    return body;
  } catch (e) {
    throw new Error(`Network error: ${errorDisplayMessage}`);
  }
}

export default postWebCheckoutCreatePayPal;
