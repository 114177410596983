import * as Sentry from '@sentry/react';
import { apiVersion, baseUrl } from '../apiConfig';
import WebCheckoutCaptureRequestPayPalTypes from '../../requests/WebCheckoutCaptureRequestPayPalTypes';
import { WebCheckoutCaptureResponseOurPayPalTypes } from '../../responses/WebCheckoutCaptureResponsePayPalTypes';

/**
 * Post payment
 * @param tenantId
 * @param storeId
 * @param request
 */
async function postWebCheckoutCapturePayPal(
  tenantId: number,
  storeId: number,
  request: WebCheckoutCaptureRequestPayPalTypes
): Promise<WebCheckoutCaptureResponseOurPayPalTypes> {
  const url = `${baseUrl}/${apiVersion}/tenants/${tenantId}/stores/${storeId}/orders/webcheckout/capture-paypal-order`;
  const errorDisplayMessage = 'Unable to process transaction.';

  try {
    const response = await fetch(url, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request),
    });
    const body =
      (await response.json()) as WebCheckoutCaptureResponseOurPayPalTypes;

    if (!response.ok) {
      const level = response.status >= 500 ? 'error' : 'warning';

      Sentry.withScope((scope) => {
        scope.setLevel(level);
        Sentry.captureMessage(
          `${level} - ${response.status}: webcheckout - ${response.statusText}`
        );
      });

      throw new Error(
        `Response status: ${response.status} ${response.statusText}`
      );
    }

    return body;
  } catch (e) {
    throw new Error(`Network error: ${errorDisplayMessage}`);
  }
}

export default postWebCheckoutCapturePayPal;
