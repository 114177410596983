import React, { FunctionComponent, useCallback, useState } from 'react';
import AlertsContext from './AlertsContext';
import AlertSeverity from './AlertSeverity';
import AlertMessage from './AlertMessage';

const AlertsProvider: FunctionComponent = ({ children }) => {
  const [alerts, setAlerts] = useState<AlertMessage[]>([]);

  // I'm doing these as callbacks so if child components use them as props
  // within their renders, we don't cause unnecessary rerenders of those
  // things.
  const clearAlerts = useCallback(() => setAlerts([]), []);

  const sendAlert = useCallback(
    (message, severity: AlertSeverity = 'info', autoClose = true) => {
      // Come up with a random ID for the alert
      const newAlert: AlertMessage = {
        key: Math.random(),
        message,
        severity,
        autoClose,
      };
      setAlerts((previous) => [...previous, newAlert]);
    },
    []
  );

  const dismissAlert = useCallback((alertId: number) => {
    setAlerts((previous) => {
      return previous.filter((alert) => alert.key !== alertId);
    });
  }, []);

  return (
    <AlertsContext.Provider
      value={{
        clearAlerts,
        sendAlert,
        dismissAlert,
        currentAlert: alerts[0],
      }}
    >
      {children}
    </AlertsContext.Provider>
  );
};

export default AlertsProvider;
