import ReactGA from 'react-ga4';
import CartType from '../types/CartType';
import CartItemType from '../types/CartItemType';

type CartItemPartialType = Partial<CartItemType> &
  Pick<CartItemType, 'productId' | 'claimCode' | 'streamId'>;
type CartPartialType = Partial<CartType>;

export default function GAEvent(actionName: string, tenantId: number): void {
  ReactGA.event({
    action: `Shopping - ${actionName}`,
    category: 'Shopping Portal',
    label: `tenantId:${tenantId} - Shopping Portal`,
  });
}

export function GATransactionEvent(
  eventType: 'add_to_cart' | 'remove_from_cart' | 'begin_checkout' | 'purchase',
  itemUpdate?: CartItemPartialType,
  cart?: CartPartialType
): void {
  const formattedLineItems = cart?.cartItems?.map((cartItem) => ({
    item_id: cartItem.productId,
    item_name: cartItem.productName,
    quantity: cartItem.quantity,
    price: cartItem.productSalePrice,
    item_category: cartItem.productGroupId,
  }));

  switch (eventType) {
    case 'add_to_cart':
    case 'remove_from_cart':
      if (itemUpdate) {
        const itemTotal = (itemUpdate.amount || 0) * (itemUpdate.quantity || 0);
        ReactGA.gtag('event', eventType, {
          currency: 'USD',
          value: itemTotal,
          items: [
            {
              item_id: itemUpdate.productId,
              item_name: itemUpdate.productName,
              discount: itemUpdate.productSalePrice,
              item_category: `Claim Code: ${itemUpdate.claimCode}`,
              item_category2: `Stream ID: ${itemUpdate.streamId}`,
              price: itemUpdate.amount,
              quantity: itemUpdate.quantity,
            },
          ],
        });
      }
      break;
    case 'begin_checkout':
    case 'purchase':
      if (cart?.cartItems?.length) {
        ReactGA.gtag('event', eventType, {
          currency: 'USD',
          value: cart.subtotal,
          items: formattedLineItems,
        });
      }
      break;
    default:
  }
}
