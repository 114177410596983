import * as Sentry from '@sentry/react';
import { apiVersion, baseUrl } from '../apiConfig';
import ProductGroupAdditionalSelectorsResponse from '../../responses/ProductGroupAdditionalSelectorsResponse';

/**
 * Get Product Group Additional Selectors
 * @param tenantId
 * @param storeId
 * @param productGroupId
 */
async function getProductGroupAdditionalSelectors(
  tenantId: number,
  storeId: number,
  productGroupId: number
): Promise<ProductGroupAdditionalSelectorsResponse> {
  const url = `${baseUrl}/${apiVersion}/tenants/${tenantId}/stores/${storeId}/inventory/productgroups/${productGroupId}/additional-selectors`;
  const emptyProduct = {
    productGroup: {
      id: 0,
      name: '',
      productTagIds: [],
      minPrice: 0,
      maxPrice: 0,
      thumbnailUrl: '',
      availability: [],
    },
    inventoryItems: [],
    tags: [],
    tagCategories: [],
  };

  if (!Number.isInteger(productGroupId)) return emptyProduct;

  try {
    const response = await fetch(url);

    if (response.ok) {
      if (response.status === 204) {
        Sentry.withScope((scope) => {
          scope.setLevel('warning');
          Sentry.captureMessage(
            `Warning - ${response.status}: getProductGroupAdditionalSelectors - ${response.statusText}`
          );
        });

        return emptyProduct;
      }

      return response.json() as Promise<ProductGroupAdditionalSelectorsResponse>;
    }

    const level = response.status >= 500 ? 'error' : 'warning';

    Sentry.withScope((scope) => {
      scope.setLevel(level);
      Sentry.captureMessage(
        `${level} - ${
          response.status
        }: getProductGroupAdditionalSelectors - ${response.text()}`
      );
    });
  } catch (error) {
    Sentry.withScope((scope) => {
      scope.setLevel('error');
      Sentry.captureMessage(
        `Error - Network error: getProductGroupAdditionalSelectors: ${error}`
      );
    });
  }

  return emptyProduct;
}

export default getProductGroupAdditionalSelectors;
