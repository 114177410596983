import { createContext } from 'react';
import CustomerFormInformation from '../types/CustomerInformation';

type CustomerInformationFunction = {
  customerInfo?: CustomerFormInformation;
  updateCustomerInfo: (data: CustomerFormInformation | undefined) => void;
};

const CustomerInformationContext = createContext<
  CustomerInformationFunction | undefined
>(undefined);

export default CustomerInformationContext;
