import React, { useState, MouseEvent } from 'react';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import IconShare from '../Icons/IconShare';
import EmailIcon from '../Icons/EmailIcon';
import FacebookLogo from '../Icons/FacebookLogo';
import PinterestLogo from '../Icons/PinterestLogo';
import IconAddLink from '../Icons/IconAddLink';
import Styles from './ShareMenu.module.scss';

interface ShareMenuProps {
  title: string;
  text: string;
  emailBody: string;
  emailSubject: string;
  image: string;
}

const ShareMenu = ({
  title,
  text,
  emailBody,
  emailSubject,
  image,
}: ShareMenuProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const currentUrl = document.location.href;
  const emailHref = `mailto:?body=${emailBody} ${currentUrl}&subject=${emailSubject}`;

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleOnClick = async (
    event: MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    if (
      navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i) &&
      navigator.share
    ) {
      try {
        await navigator.share({ title, text, url: currentUrl });
      } catch (error) {
        handleClose();
      }
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  async function copyContent(stringToCopy: string): Promise<void> {
    try {
      await navigator.clipboard.writeText(stringToCopy);
    } finally {
      handleClose();
    }
  }

  return (
    <div className={Styles.shareWrapper}>
      <Button
        aria-controls="share-menu"
        aria-haspopup="true"
        onClick={handleOnClick}
        className={Styles.shareButton}
        data-testid="share-button"
      >
        <IconShare id="share_icon" />
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: { marginTop: '16px', overflow: 'visible' },
        }}
      >
        <div className={Styles.shareCarrot}></div>
        <MenuList>
          <MenuItem onClick={handleClose} className={Styles.shareItem}>
            <a
              className={Styles.shareLink}
              href={emailHref}
              target="_blank"
              rel="noopener noreferrer"
              title="Share via e-mail"
            >
              <span className={Styles.shareIcon}>
                <EmailIcon id="share_via_email" />
              </span>
              <span className={Styles.shareIconText}>Email</span>
            </a>
          </MenuItem>
          <MenuItem onClick={handleClose} className={Styles.shareItem}>
            <a
              className={Styles.shareLink}
              href={`https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`}
              target="_blank"
              rel="noopener noreferrer"
              title="Share on Facebook"
            >
              <span className={Styles.shareIcon}>
                <FacebookLogo id="share_via_Facebook" />
              </span>
              <span className={Styles.shareIconText}>Facebook</span>
            </a>
          </MenuItem>
          <MenuItem onClick={handleClose} className={Styles.shareItem}>
            <a
              className={Styles.shareLink}
              href={`https://www.pinterest.com/pin/create/link/?url=${currentUrl}&media=${image}&description=${text}`}
              target="_blank"
              rel="noopener noreferrer"
              title="Pin it on Pinterest"
            >
              <span className={Styles.shareIcon}>
                <PinterestLogo id="share_via_Pinterest" />
              </span>
              <span className={Styles.shareIconText}>Pinterest</span>
            </a>
          </MenuItem>
          <MenuItem
            onClick={() => copyContent(currentUrl)}
            className={Styles.shareItem}
          >
            <span className={Styles.shareIcon}>
              <IconAddLink id="copy_link" />
            </span>
            <span className={Styles.shareIconText}>Copy Link</span>
          </MenuItem>
        </MenuList>
      </Popover>
    </div>
  );
};

export default ShareMenu;
