import { apiVersion, baseUrl } from '../apiConfig';
import UpdateCartRequest from '../../requests/UpdateCartRequst';
import UpdateCartItemResponse from '../../responses/UpdateCartItemResponse';
import RemoveProductFromCartRequest from '../../requests/RemoveProductFromCartRequest';
import ClaimTokenRequestType from '../../../types/ClaimTokenRequestType';
import ClaimTokenResponseType from '../../../types/ClaimTokenResponseType';

/**
 * Update Cart Item
 * @param storeId
 * @param request
 */
export async function updateProductCartItem(
  tenantId: number,
  storeId: number,
  request: UpdateCartRequest
): Promise<UpdateCartItemResponse> {
  const url = `${baseUrl}/${apiVersion}/tenants/${tenantId}/stores/${storeId}/cartItems`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(request),
  });

  if (!response.ok) {
    throw new Error(`Response: ${response.status} ${response.statusText}`);
  }

  return response.json() as Promise<UpdateCartItemResponse>;
}

/**
 * Remove Product From Cart
 * @param storeId
 * @param request
 */
export async function removeProductFromCart(
  tenantId: number,
  storeId: number,
  request: RemoveProductFromCartRequest
): Promise<UpdateCartItemResponse> {
  const url = `${baseUrl}/${apiVersion}/tenants/${tenantId}/stores/${storeId}/cartItems/remove`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(request),
  });

  if (!response.ok) {
    throw new Error(`Response: ${response.status} ${response.statusText}`);
  }

  return response.json() as Promise<UpdateCartItemResponse>;
}

/**
 * Claim Cart Token
 * @param storeId
 * @param request
 */
export async function claimCartToken(
  tenantId: number,
  storeId: number,
  sessionId: string,
  request: ClaimTokenRequestType
): Promise<ClaimTokenResponseType> {
  const url = `${baseUrl}/${apiVersion}/tenants/${tenantId}/stores/${storeId}/cartItems/${sessionId}/redeem-claim-token`;
  const response = await fetch(url, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(request),
  });

  if (!response.ok) {
    throw new Error(`Response: ${response.status} ${response.statusText}`);
  }

  return response.json() as Promise<ClaimTokenResponseType>;
}
