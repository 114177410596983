import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Styles from './NotFound.module.scss';

interface Props {
  heading?: string;
  message?: string;
}

const defaultProps = {
  heading: null,
  message: null,
};

function NotFound({ heading, message }: Props): JSX.Element {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <div className="col-12">
      <div className="row">
        <div className={`col-md-6 ${Styles.relative}`}>
          <svg
            className={Styles.not_found_svg}
            width="60%"
            height="500px"
            viewBox="0 0 837 1045"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g fill="none">
              <path
                className={Styles.polygon}
                stroke={theme.palette.primary.main}
                d="M353,9 L626.664028,170 L626.664028,487 L353,642 L79.3359724,487 L79.3359724,170 L353,9 Z"
              />
              <path
                className={`${Styles.polygon} ${Styles.polygon2}`}
                stroke={theme.palette.secondary.main}
                d="M78.5,529 L147,569.186414 L147,648.311216 L78.5,687 L10,648.311216 L10,569.186414 L78.5,529 Z"
              />
              <path
                className={`${Styles.polygon} ${Styles.polygon3}`}
                stroke={theme.palette.primary.main}
                d="M773,186 L827,217.538705 L827,279.636651 L773,310 L719,279.636651 L719,217.538705 L773,186 Z"
              />
              <path
                className={`${Styles.polygon} ${Styles.polygon4}`}
                stroke={theme.palette.secondary.main}
                d="M639,529 L773,607.846761 L773,763.091627 L639,839 L505,763.091627 L505,607.846761 L639,529 Z"
              />
              <path
                className={`${Styles.polygon} ${Styles.polygon5}`}
                stroke={theme.palette.primary.main}
                d="M281,801 L383,861.025276 L383,979.21169 L281,1037 L179,979.21169 L179,861.025276 L281,801 Z"
              />
            </g>
          </svg>
        </div>
        <div
          className={`col-md-6 d-flex align-items-center ${Styles.relative}`}
        >
          <div className="w-100">
            {message || heading ? (
              <>
                <h1>{heading}</h1>
                <p>{message}</p>
              </>
            ) : (
              <>
                <h1>404</h1>
                <p>Page not found</p>
                <Button
                  className="me-4"
                  color="primary"
                  variant="contained"
                  onClick={() => navigate(-1)}
                >
                  Go Back
                </Button>
                <Link className="me-4" to="/">
                  Go to Home Page
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

NotFound.defaultProps = defaultProps;

export default NotFound;
