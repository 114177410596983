import React, { ReactNode } from 'react';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type from '@material-ui/lab/themeAugmentation';
import ThemeColors from '../../types/ThemeColors';

interface Props {
  children: ReactNode;
  theme?: ThemeColors;
}

const defaultColors = {
  primary: '#C0C0C0',
  secondary: '#C0C0C0',
  error: '#f44336',
  warning: '#ff9800',
  info: '#2196f3',
  success: '#4caf50',
};

const ThemeWrapper = ({ children, theme }: Props): JSX.Element => {
  const primary = theme?.primary || defaultColors.primary;
  const secondary = theme?.secondary || defaultColors.secondary;
  const error = theme?.danger || defaultColors.error;
  const warning = theme?.warning || defaultColors.warning;
  const info = theme?.tertiary || defaultColors.info;
  const success = theme?.success || defaultColors.success;

  const dsoTheme = {
    palette: {
      primary: {
        main: primary,
      },
      secondary: {
        main: secondary,
      },
      error: {
        main: error,
      },
      warning: {
        main: warning,
      },
      info: {
        main: info,
      },
      success: {
        main: success,
      },
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          a: {
            color: primary,
          },
          'a:hover': {
            color: primary,
          },
        },
      },
      MuiButtonBase: {
        root: {
          '&&:focus': {
            outline: 0,
            boxShadow: `0 0 0 0.2rem ${primary}75`,
          },
        },
      },
      MuiButton: {
        startIcon: {
          '&& span': {
            background: primary,
          },
        },
      },
      MuiToggleButtonGroup: {
        root: {
          display: 'block',
        },
        groupedHorizontal: {
          '&&:not(:first-child)': {
            '&&:not(:disabled)': {
              borderLeft: `1px solid ${primary}`,
            },
          },
          '&&:not(:last-child)': {
            borderRadius: '2px',
            marginLeft: '0',
          },
        },
      },
      MuiToggleButton: {
        root: {
          display: 'inline-block',
          border: `1px solid ${primary}`,
          borderRadius: '2px',
          margin: '0 1rem 1rem 0',
          minWidth: '60px',
          color: primary,
          '&:hover': {
            backgroundColor: primary,
            color: '#ffffff',
          },
          '&$selected': {
            backgroundColor: primary,
            color: '#ffffff',
            '&&:hover': {
              backgroundColor: primary,
              color: '#ffffff',
            },
          },
          '&$disabled': {
            border: 0,
          },
        },
      },
      MuiRadio: {
        colorSecondary: {
          '&$checked': {
            color: primary,
          },
        },
      },
    },
  };

  return (
    <ThemeProvider theme={createTheme(dsoTheme)}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

ThemeWrapper.defaultProps = {
  theme: defaultColors,
};

export default ThemeWrapper;
