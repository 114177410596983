import React, {
  MouseEventHandler,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import LoadingSpinner from '../LoadingSpinner';
import APIError from '../APIError';
import CartItem from '../CartItem';
import FreeShippingThreshold from '../FreeShippingThreshold';
import CartItemType from '../../types/CartItemType';
import CartContext from '../../context/CartContext';
import FormatUSD from '../../utilities/Formatter';
import IconClose from '../Icons/IconClose';
import Styles from './ShoppingCart.module.scss';

interface Props {
  handleClick: MouseEventHandler;
}

const ShoppingCart = ({ handleClick }: Props): JSX.Element => {
  const cart = useContext(CartContext);
  const [oldCartValue, setOldCartValue] = useState<CartItemType[]>();

  const itemRef = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    if (oldCartValue !== cart?.items) {
      const intersection = cart?.items?.filter(
        (cartItem) =>
          !oldCartValue?.some(
            (oldCartItem) =>
              cartItem.productId === oldCartItem.productId &&
              cartItem.quantity === oldCartItem.quantity
          )
      );

      if (intersection?.[0]?.productId) {
        const itemToScrollTo = itemRef.current[intersection[0].productId];
        itemToScrollTo?.scrollIntoView();
      }
      setOldCartValue(cart?.items);
    }
  }, [cart?.items, oldCartValue]);

  const CheckoutDisabled = !cart?.items?.length;
  let bodyElement: JSX.Element = <></>;

  if (!cart) {
    bodyElement = <LoadingSpinner />;
  } else if (cart instanceof Error) {
    bodyElement = <APIError />;
  } else if (cart.items && cart.items.length > 0) {
    bodyElement = (
      <>
        <div className={Styles.shopping_cart_list}>
          {cart.items.map((cartItem) => (
            <div
              ref={(el) => {
                itemRef.current[cartItem.productId] = el;
              }}
              key={`${cartItem.productId}${cartItem.claimCode || ''}`}
              id={`productId${cartItem.productId}`}
            >
              <CartItem
                claimCode={cartItem.claimCode}
                streamId={cartItem.streamId}
                item={cartItem}
                productGroupId={cartItem.productGroupId}
                quantity={cartItem.quantity}
                removeItem={cart.removeFromCart}
                smallLayout
                updateCartItem={cart.updateCartItem}
              />
            </div>
          ))}
        </div>
        <div className="mb-3">
          <div className={Styles.summaryComplete}>
            <div className={Styles.totals}>
              Subtotal
              <span className={Styles.totalsCurrency}>
                {FormatUSD.format(cart?.subtotal || 0)}
              </span>
            </div>
          </div>
          <FreeShippingThreshold />
        </div>
      </>
    );
  }

  return (
    <div id="flyout" className={Styles.container}>
      <div className={Styles.shopping_cart}>
        <div className={Styles.shopping_cart_header}>
          <span className={Styles.header}>Your Cart</span>
          <Button
            variant="text"
            onClick={handleClick}
            className={Styles.closeButton}
          >
            <IconClose id="closeCartFlyoutIcon" />
          </Button>
        </div>
        {bodyElement}
        <div className="row mt-3">
          {CheckoutDisabled && (
            <div className={`col-12 ${Styles.emptyCart}`}>
              Your bag is empty
            </div>
          )}
          <div className="col-12">
            <Button
              component={Link}
              to="/cart"
              color="primary"
              variant="contained"
              fullWidth
              onClick={(event) => {
                handleClick(event);
              }}
              disabled={CheckoutDisabled}
              className={Styles.linkHover}
            >
              View Cart / Check out
            </Button>
          </div>
          <div className="col-12 mt-3">
            <Button
              color="primary"
              variant="outlined"
              fullWidth
              onClick={(event) => {
                handleClick(event);
              }}
            >
              Continue Shopping
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShoppingCart;
