import React, { useContext, useState } from 'react';
import {
  PayPalButtons,
  PayPalButtonsComponentProps,
  usePayPalScriptReducer,
} from '@paypal/react-paypal-js';
import Skeleton from 'react-loading-skeleton';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Alert from '@material-ui/lab/Alert';
import RetailerInformationContext from '../../../context/RetailerInformationContext';
import CustomerInformationContext from '../../../context/CustomerInformationContext';
import CartContext from '../../../context/CartContext';
import postWebCheckoutCreatePayPal from '../../../api/ShoppingApi/clients/WebCheckoutCreatePayPalOrder';
import postWebCheckoutCapturePayPal from '../../../api/ShoppingApi/clients/WebCheckoutCapturePayPalOrder';
import WebCheckoutCaptureResponsePayPalTypes from '../../../api/responses/WebCheckoutCaptureResponsePayPalTypes';
import WebCheckoutResponse from '../../../api/responses/WebCheckoutResponse';
import Styles from './PayPal.module.scss';

const PayPal = ({
  onPaymentSubmit,
}: {
  onPaymentSubmit: (
    paymentToken: string,
    paypalresponse?: WebCheckoutResponse & WebCheckoutCaptureResponsePayPalTypes
  ) => Promise<void>;
}): JSX.Element => {
  const retailerInformation = useContext(RetailerInformationContext);
  const customerInformation = useContext(CustomerInformationContext);
  const cart = useContext(CartContext);

  const [{ isPending, isRejected }] = usePayPalScriptReducer();
  const [open, setOpen] = useState({
    isDisplayed: false,
    message: '',
  });

  const tenantId = retailerInformation?.store.tenant.id;
  const storeId = retailerInformation?.store.id;

  const handleClose = (): void => {
    setOpen({
      isDisplayed: false,
      message: open.message,
    });
  };

  const createOrder = async (): Promise<string> => {
    if (
      tenantId &&
      storeId &&
      cart?.sessionId &&
      customerInformation?.customerInfo
    ) {
      const { deliveryMethod, shippingAddress } =
        customerInformation.customerInfo;
      const shippingUSA =
        deliveryMethod === 'Pickup'
          ? {
              ...retailerInformation.store.pickupPolicy.pickupAddress,
              firstName: 'PayPal',
              lastName: 'Pickup',
            }
          : shippingAddress;
      const body = {
        ...customerInformation.customerInfo,
        shippingAddress: shippingUSA,
        billingAddress: undefined,
        sessionId: cart.sessionId,
      };

      const response = await postWebCheckoutCreatePayPal(
        tenantId,
        storeId,
        body
      );

      return response.orderId;
    }

    return '';
  };

  const onApprove: PayPalButtonsComponentProps['onApprove'] = async (data) => {
    // Capture the funds from the transaction.
    if (
      tenantId &&
      storeId &&
      cart?.sessionId &&
      customerInformation?.customerInfo
    ) {
      const body = { payPalOrderId: data.orderID };

      const response = await postWebCheckoutCapturePayPal(
        tenantId,
        storeId,
        body
      );

      return onPaymentSubmit('PayPalToken', response);
    }
    return undefined;
  };

  const onCancel: PayPalButtonsComponentProps['onCancel'] = () => {
    setOpen({
      isDisplayed: true,
      message: 'You have canceled your PayPal trasaction.',
    });
  };

  const onError: PayPalButtonsComponentProps['onError'] = () => {
    setOpen({
      isDisplayed: true,
      message: 'There was an issue processing your request. Please try again.',
    });
  };

  if (isPending) {
    return (
      <div className={Styles.buttonWrapper}>
        <Skeleton height={'55px'} className={Styles.skeleton} />
        <Skeleton height={'55px'} />
      </div>
    );
  }

  if (isRejected) {
    return (
      <div className={Styles.buttonWrapper}>
        <Alert severity="error">
          There was an issue with PayPal. Please try again.
        </Alert>
      </div>
    );
  }

  return (
    <div className={Styles.buttonWrapper}>
      {isPending ? (
        <div className={Styles.test}>
          <Skeleton height={'55px'} className={Styles.skeleton} />
          <Skeleton height={'55px'} />
        </div>
      ) : (
        <>
          <PayPalButtons
            createOrder={createOrder}
            onApprove={onApprove}
            onCancel={onCancel}
            onError={onError}
          />
          <Dialog
            open={open.isDisplayed}
            onClose={handleClose}
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {open.message}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClose}
                autoFocus
                variant="contained"
                color="primary"
              >
                Dismiss
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </div>
  );
};

export default PayPal;
