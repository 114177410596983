import uuid from './uuid';

let localStorageAvailable: boolean | null = null;
const fallbackMemoryMap = new Map<string, string>();
function isLocalStorageAvailable(): boolean {
  if (localStorageAvailable !== null) {
    return localStorageAvailable;
  }
  // If we haven't determined its availability yet, try to set, get, and remove
  // a record. If we can do so, and the output matches the input, we can assume
  // we have it available.
  try {
    localStorage.setItem('_', '_');
    const result = localStorage.getItem('_');
    localStorage.removeItem('_');
    localStorageAvailable = result === '_';
    return localStorageAvailable;
  } catch (e) {
    return false;
  }
}

function getSessionIdFromStorage(webAlias: string): string | null {
  if (isLocalStorageAvailable()) {
    return localStorage.getItem(`${webAlias}-sessionId`);
  }
  return fallbackMemoryMap.get(webAlias) || null;
}

function putSessionIdInStorage(webAlias: string, sessionId: string): void {
  if (isLocalStorageAvailable()) {
    localStorage.setItem(`${webAlias}-sessionId`, sessionId);
  } else {
    fallbackMemoryMap.set(webAlias, sessionId);
  }
}

function getWebAlias(): string {
  return window.location.pathname.split('/')[1];
}

export function setExistingSessionId(sessionId: string): void {
  putSessionIdInStorage(getWebAlias(), sessionId);
}

export function createNewSessionId(): string {
  const urlWebAlias = getWebAlias();
  const newId = uuid();
  putSessionIdInStorage(urlWebAlias, newId);
  return newId;
}

export function getOrCreateSessionId(): string {
  const urlWebAlias = getWebAlias();

  return getSessionIdFromStorage(urlWebAlias) ?? createNewSessionId();
}
